import React from 'react';
import { useSelector } from 'react-redux';

import ShareProvider from 'components/Modals/ProfileModals/ShareProvider';
import ShareFeedback from 'components/Modals/ProfileModals/ShareFeedback';
import CallModal from 'components/Modals/ProfileModals/CallModal';
import { select, constants } from 'store/toolkit';
import { logDevMessage } from 'utils/utils';
import { useFocusTo } from 'utils/FocusRefContext';
import CostModal from './CostModal';
import InfoModal from './InfoModal';
import ServiceCostModal from '../ServiceCostModal';
import FocusAreasModal from './FocusAreasModal';
import AffiliationsModal from './AffiliationsModal';

const { PROFILE_MODAL_TYPES } = constants.ui;

function ProfileModals() {
  const profileModal = useSelector(select.ui.profileModal);
  const focusTo = useFocusTo();

  const showServiceCost = useSelector(select.featureFlags.showServiceCost);

  if (!profileModal?.isOpen) {
    // the timeout is necessary because of the order in which redux state is updated, and components are unmounted.
    setTimeout(focusTo.profileModalReturn, 10);
    return null;
  }

  switch (profileModal.type) {
    case PROFILE_MODAL_TYPES.CALL:
      return <CallModal profileData={profileModal.data} />;
    case PROFILE_MODAL_TYPES.SHARE:
      return (
        <ShareProvider
          profileData={profileModal.data.results}
          location={profileModal.data.location}
        />
      );
    case PROFILE_MODAL_TYPES.FEEDBACK:
      return <ShareFeedback />;
    case PROFILE_MODAL_TYPES.COST:
      return <CostModal />;
    case PROFILE_MODAL_TYPES.INFO:
      return <InfoModal />;
    case PROFILE_MODAL_TYPES.SERVICE_COST:
      if (!showServiceCost) return null;
      return <ServiceCostModal open />;
    case PROFILE_MODAL_TYPES.FOCUS_AREAS:
      return <FocusAreasModal open />;
    case PROFILE_MODAL_TYPES.AFFILIATIONS:
      return <AffiliationsModal open />;
    default:
      logDevMessage('Unhandled profileModal.type in <ProfileModals />');
      return null;
  }
}

export default ProfileModals;
