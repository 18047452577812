import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ChatAppNavigator from './ChatAppNavigator';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
    padding: ({ disablePadding }) => (disablePadding ? 0 : theme.spacing(1)),
    [theme.breakpoints.down(400)]: {
      padding: ({ disablePadding }) => (disablePadding ? 0 : theme.spacing(0.25)),
    },
  },
}));

export default function ChatLayout({ disablePadding, showHeader, children }) {
  const classes = useStyles({ disablePadding });

  return (
    <>
      {showHeader && <ChatAppNavigator />}
      <Box component="main" className={classes.root}>
        {children}
      </Box>
    </>
  );
}

ChatLayout.propTypes = {
  disablePadding: PropTypes.bool,
  showHeader: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ChatLayout.defaultProps = {
  showHeader: false,
  disablePadding: false,
};
