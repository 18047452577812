import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tabs, Tab, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ProfileTabLabel from 'components/ModernExperience/Profile/ProfileTabLabel';
import useTabState from 'hooks/useTabState';
import { AriaLabelRule } from 'propTypes/index';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  tabsRoot: {
    marginBottom: theme.spacing(1),
  },
  tabIndicator: {
    display: 'none', // hide the built in MUI sliding indicator
  },
  tabsFlexContainer: {
    '& > *': {
      // select all children of the flex container (all children should be tabs)
      flex: 1, // apply equal flex so each tab is the same width and cover the entire container
      maxWidth: 'none', // remove the max width that's inherited so the full container space is taken up
      minWidth: 150, // setting a min width allows tabs to wrap when they get too small
    },
    flexDirection: ({ vertical }) => (vertical ? 'column' : 'row'),
  },
  tabRoot: {
    // this is the tab root (a button)
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius * 3,
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
  },
  tabWrapper: {
    alignSelf: 'start',
  },
  tabLabel: {
    width: '100%',
  },
  tabSelected: {
    borderColor: theme.palette.text.secondary,
  },
}));

function ResultTab({ resultId, ...props }) {
  const classes = useStyles();

  return (
    <Tab
      label={<ProfileTabLabel id={resultId} additionalClass={classes.tabLabel} />}
      classes={{
        root: classes.tabRoot,
        selected: classes.tabSelected,
        wrapper: classes.tabWrapper,
      }}
      {...props}
    />
  );
}

ResultTab.propTypes = {
  resultId: PropTypes.string.isRequired,
};

export default function ResultProfileTabs({
  ids = [],
  renderPanel,
  className,
  fullWidth,
  vertical,
  onChange,
  ...props
}) {
  const classes = useStyles({ vertical });
  const [currentId, handleTabChange, getTabProps, getPanelProps] = useTabState(ids[0]);

  const handleChange = useCallback(
    (evt, newVal) => {
      handleTabChange(evt, newVal);

      if (typeof onChange === 'function') {
        onChange(newVal);
      }
    },
    [handleTabChange, onChange]
  );

  if (!Array.isArray(ids) || ids.length < 1) return null;

  return (
    <div className={clsx(className, { [classes.fullWidth]: fullWidth })}>
      <Tabs
        value={currentId}
        onChange={handleChange}
        classes={{
          root: classes.tabsRoot,
          flexContainer: classes.tabsFlexContainer,
          indicator: classes.tabIndicator,
        }}
        {...props}
      >
        {ids.map((id) => (
          <ResultTab resultId={id} key={id} value={id} {...getTabProps(id)} />
        ))}
      </Tabs>

      {ids.map((resultId) => (
        <Box key={`panel-${resultId}`} {...getPanelProps(resultId)} hidden={resultId !== currentId}>
          {renderPanel(resultId)}
        </Box>
      ))}
    </div>
  );
}

ResultProfileTabs.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  renderPanel: PropTypes.func.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  vertical: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  AriaLabelRule,
  onChange: PropTypes.func,
};

ResultProfileTabs.defaultProps = {
  fullWidth: false,
  className: undefined,
  vertical: false,
  onChange: undefined,
};
