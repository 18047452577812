import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box } from '@material-ui/core';

import AuthProtectedRoute from 'components/Navigation/AuthProtectedRoute';
import PrivacyPage from 'components/Pages/PrivacyPage';
import TermsPage from 'components/Pages/TermsPage';
import Redirect from 'components/Redirect';
import usePromote from 'hooks/usePromote';
import TooltipInfo from 'components/TooltipInfo';
import useFetchSpecialties from 'hooks/useFetchSpecialties';
import BreakpointWatcher from 'components/BreakpointWatcher';
import LoginForm from 'components/Forms/Login/LoginForm';
import ProfilePage from 'components/ModernExperience/Profile/ProfilePage';
import ClientConfigDevTools from 'components/DevTools/ClientConfigDevTools';
import ClientConfigDevToolsModal from 'components/Modals/ClientConfigDevToolsModal';
import ProfileModals from 'components/Modals/ProfileModals/index';
import ChatApp from './ChatApp';
import ChatLayout from './ChatLayout';
import '../App.css';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  login: {
    marginTop: theme.spacing(3),
  },
}));

export default function StandaloneChatApp() {
  const classes = useStyles();
  usePromote();
  useFetchSpecialties();

  return (
    <>
      <BreakpointWatcher />
      <Switch>
        <Route
          path="/login"
          exact
          render={() => (
            <ChatLayout>
              <Typography variant="h1" gutterBottom>
                Welcome to Eva
              </Typography>
              <Typography variant="h3" component="p" color="textSecondary" gutterBottom>
                Your digital health assistant
              </Typography>

              <Box className={classes.login}>
                <LoginForm />
              </Box>
            </ChatLayout>
          )}
        />
        <Route path="/privacy" exact component={PrivacyPage} />
        <Route path="/terms" exact component={TermsPage} />

        <AuthProtectedRoute
          path="/profile/:profileType/:id"
          exact
          render={() => (
            <ChatLayout disablePadding showHeader>
              <ProfilePage />
            </ChatLayout>
          )}
        />
        <AuthProtectedRoute
          path="/"
          exact
          render={() => (
            <ChatLayout>
              <ChatApp />
            </ChatLayout>
          )}
        />
        <Redirect to="/" />
      </Switch>
      <TooltipInfo />
      <ProfileModals />
      {process.env.NODE_ENV === 'development' && (
        <>
          <ClientConfigDevTools />
          <ClientConfigDevToolsModal />
        </>
      )}
    </>
  );
}
