import * as Sentry from '@sentry/react';

import { getClient, getEnv, detectWebGLSupport } from 'utils/utils';
import beforeBreadcrumb from './breadcrumbs';

const env = getEnv();
const client = getClient();
const BROWSER_SETTINGS = 'browserSettings';

const errorCodeIgnoreList = ['null_origin'];

function addTranslationDataToEventContext(event) {
  const documentLanguage = document.documentElement.lang;

  event.contexts[BROWSER_SETTINGS].documentLanguage = documentLanguage;
  event.contexts[BROWSER_SETTINGS].preferredLanguage = navigator.language;
  event.contexts[BROWSER_SETTINGS].isTranslated = documentLanguage !== 'en-us'; // en-us is the value we set in index.html as the lang tag, changes to it would come from a third party translator
}

export function beforeSend(event, hint) {
  try {
    const { originalException } = hint;

    // filter out specific error codes
    if (errorCodeIgnoreList.includes(originalException.response?.data?.code)) return null;

    if (originalException.sentryMessage) {
      // when errors have a dedicated sentryMessage property, send that rather than the default "message" property
      event.exception.values[0].value = originalException.sentryMessage;
    }
  } catch (e) {
    // do nothing
  }

  // enrich with more context
  addTranslationDataToEventContext(event);

  if (env === 'local') {
    console.log('Sentry Event:', event);
    return null;
  }
  return event;
}

export default function initializeSentry() {
  Sentry.init({
    dsn: 'https://4acc2d0cb9ba4af586739550b525ea43@sentry.io/1875299',
    // eslint-disable-next-line new-cap
    integrations: [new Sentry.browserTracingIntegration()],
    release: process.env.REACT_APP_GIT_HASH,
    environment: `${env}`,
    tracesSampleRate: env !== 'production' ? 0.1 : 1.0,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'Non-Error promise rejection captured',
      'Load failed', // Azure maps failing to fetch
      'Analytics: Failed to fetch',
    ],
    beforeBreadcrumb,
    beforeSend,
  });
  Sentry.setTag('client', `${client}`);
  Sentry.setContext(BROWSER_SETTINGS, {
    isWebGlDisabled: !detectWebGLSupport(),
  });
}
