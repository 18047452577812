import { DEFAULT_LOCATIONS, CHECKMARK, REFERRAL_GUIDE_DEMO } from 'utils/constants';

import acmeHeaderLogo from 'assets/pg-acme-header-logo.svg';
import acmeLoginLogo from 'assets/pg-acme-login-logo.png';
import { selectToken } from 'utils/utils';

export const apiTokens = {
  dev: 'Token f6283dad8d25ad493e14031e256941d00864461a',
  staging: 'Token c644972ffa2b628ce368a2087af1c3944d46abe6',
  prod: 'Token 45586194700dcf527f00172d0fedbcb3884cb04c',
};

const token = selectToken(apiTokens);

const referralGuideDemoConfig = {
  CONFIG_NAME: 'referralguide-demo',

  API_TOKEN: token,

  EMBOLD_CLIENT: REFERRAL_GUIDE_DEMO,
  EMBOLD_CLIENT_SLUG: REFERRAL_GUIDE_DEMO,

  // login
  LOGIN_NETWORK_NAME: 'referralGuideDemoUserId',
  SHOW_REGION_SELECTOR: true,

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Orlando },

  // content
  DISPLAY_NAME: 'ACME',
  HEADER_LOGO: acmeHeaderLogo,
  FORM_VARIANT: CHECKMARK,
  EMBOLD_LOGO_PRESENT: false,
  LOCAL_LOGO_ASSET: acmeLoginLogo,
  NO_WELCOME_LINK: false,
};

export default referralGuideDemoConfig;
