import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { AI_SEARCH_SLICE_NAME } from '../slicesNames';
import { aiSearch } from './aiSearchThunks';
import { formatAiSearchResponse } from './aiSearchUtils';

const getInitialState = () => ({
  aiSearchKey: uuidv4(),

  searchInputValue: '',
  searchPrompt: '',
  loading: false,
  error: null,
  response: {
    chatResponse: '',
    recommendations: {
      specialties: [],
    },
  },

  quickFeedback: {
    shouldPrompt: false, // trigger timeout to display form
    shouldDisplay: false, // show or hide form
    progress: 0,
  },
});

const aiSearchSlice = createSlice({
  name: AI_SEARCH_SLICE_NAME,
  initialState: getInitialState(),
  reducers: {
    reset: () => getInitialState(),
    setInput: (state, action) => {
      state.searchInputValue = action.payload;
    },
    setShouldPromptFeedback: (state, action) => {
      state.quickFeedback.shouldPrompt = action.payload;
    },
    setShouldDisplayFeedback: (state, action) => {
      state.quickFeedback.shouldDisplay = action.payload;
    },
    setQuickFeedbackProgress: (state, action) => {
      state.quickFeedback.progress = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(aiSearch.pending, (state) => ({
      // reset all values except search inputs and loading
      ...getInitialState(),
      loading: true,
      searchInputValue: state.searchInputValue,
      searchPrompt: state.searchInputValue,
    }));
    builder.addCase(aiSearch.fulfilled, (state, action) => {
      const response = formatAiSearchResponse(action.payload);
      state.loading = false;
      state.response = response;
      state.quickFeedback.shouldPrompt = true;
    });
    builder.addCase(aiSearch.rejected, (state) => {
      state.loading = false;
      state.error = true;
      state.quickFeedback.shouldPrompt = true;
    });
  },
});

export default aiSearchSlice;
export const {
  reset,
  setInput,
  setShouldPromptFeedback,
  setShouldDisplayFeedback,
  setQuickFeedbackProgress,
} = aiSearchSlice.actions;
