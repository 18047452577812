/* eslint-disable import/prefer-default-export */

/**
 * This object contains the keys that can by found on the network config object.
 * Use these keys for accessing a network config value in a selector.
 *
 * @example
 * const selectSchedulePhoneNumber = (state) => {
 *  const networks = state[CONFIG_SLICE_NAME].values.REGION_SELECTOR_CODES;
 *
 *  const myNetwork = networks['mynetwork'];
 *  const myNetworkConfig = myNetwork.config;
 *  return myNetworkConfig[constants.config.networkConfigKeys.SCHEDULE_PHONE_NUMBER];
 * }
 */
export const networkConfigKeys = {
  SCHEDULE_PHONE_NUMBER: 'SCHEDULE_PHONE_NUMBER',
  SCHEDULE_COPY: 'SCHEDULE_COPY',
  SHOW_TELEHEALTH: 'SHOW_TELEHEALTH',
  SHOW_BOARD_CERTIFIED: 'SHOW_BOARD_CERTIFIED',
  SHOW_WHEELCHAIR_ACCESSIBLE: 'SHOW_WHEELCHAIR_ACCESSIBLE',
  SHOW_SERVICE_COST: 'SHOW_SERVICE_COST',
  MEMBER_ID_LINK: 'MEMBER_ID_LINK',
  SHOW_BCBS_LINK: 'SHOW_BCBS_LINK',
  SHOW_PHONE_NUMBER: 'SHOW_PHONE_NUMBER',
  IS_SURGERY_PLUS: 'IS_SURGERY_PLUS',
  SURGERY_PLUS_PHONE_NUMBER: 'SURGERY_PLUS_PHONE_NUMBER',
  SHOW_CLIENT_FEATURED: 'SHOW_CLIENT_FEATURED',
  LOGIN_NETWORK_NAME_VERSION: 1,
  ZOHO_MAILBOX_ADDRESS: 'ZOHO_MAILBOX_ADDRESS',
  ZOHO_DEPARTMENT_ID: 'ZOHO_DEPARTMENT_ID',
  SUPPORT_NAME: 'SUPPORT_NAME',
  SUPPORT_PHONE_NUMBER: 'SUPPORT_PHONE_NUMBER',
  SUPPORT_EMAIL: 'SUPPORT_EMAIL',
  SHOW_CHAT: 'SHOW_CHAT',
  SHOW_CHAT_SCENARIO: 'SHOW_CHAT_SCENARIO',
  SHOW_CHAT_FEEDBACK: 'SHOW_CHAT_FEEDBACK',
  SHOW_AI_SEARCH: 'SHOW_AI_SEARCH',
  LOGIN_IMAGE: 'LOGIN_IMAGE',
  HEADER_IMAGE: 'HEADER_IMAGE',
  FEATURED_ACTIONS: 'FEATURED_ACTIONS',
};
