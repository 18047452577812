import React, { useCallback, useState, useMemo } from 'react';
import PropTypes, { RefType } from 'propTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import PrintIcon from '@material-ui/icons/Print';

import formatPrintProfile from 'utils/formatPrintProfile';
import useReturnFocus from 'hooks/useReturnFocus';
import useFocusAnchors from 'utils/FocusRefContext';
import { actions, select } from 'store/toolkit';
import { _analyticsActions } from 'analytics/index';

const SPINNER_SIZE = 16;
const useStyles = makeStyles((theme) => ({
  container: {
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      '& > *': {
        flex: 1,
      },
    },
  },
  buttonRoot: {
    width: 160,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 90,
    },
    padding: theme.spacing(1),
  },
}));

function PlaceProfileHeaderButtons({ placeId, printRef }) {
  const classes = useStyles();
  const focusAnchors = useFocusAnchors();
  const placeData = useSelector(select.place(placeId).data);
  const showShareButton = useSelector(select.featureFlags.showShare);
  const showScheduleButton = useSelector(select.featureFlags.showScheduleButton);

  const locationInput = useSelector(select.results.locationInput);
  const coords = useSelector(select.results.coordinates);

  const dispatch = useDispatch();
  const { ref: callButtonRef, returnFocus: focusCallButton } = useReturnFocus();
  const { ref: shareButtonRef, returnFocus: focusShareButton } = useReturnFocus();
  const { ref: printButtonRef, returnFocus: focusPrintButton } = useReturnFocus();

  const [isPrinting, setIsPrinting] = useState(false);

  const { pathname } = useLocation();
  const isSharePage = pathname.startsWith('/share/');

  const handleCall = useCallback(() => {
    focusAnchors.profileModalReturnFocus = focusCallButton;
    dispatch(actions.ui.openProfileModal({ type: 'call', data: placeData }));
  }, [dispatch, focusAnchors, focusCallButton, placeData]);

  const handleShare = useCallback(() => {
    focusAnchors.profileModalReturnFocus = focusShareButton;
    dispatch(
      actions.ui.openShareModal({ results: [placeData], location: { coords, locationInput } })
    );
  }, [coords, dispatch, focusAnchors, focusShareButton, locationInput, placeData]);

  const handleAfterPrint = useCallback(() => {
    setIsPrinting(false);
    focusPrintButton();
  }, [focusPrintButton]);

  const printProfile = useReactToPrint({
    content: () => formatPrintProfile(printRef),
    documentTitle: placeData.name || 'Embold Health Facility',
    onBeforeGetContent: () => setIsPrinting(true),
    onAfterPrint: handleAfterPrint,
  });

  const handlePrint = useCallback(() => {
    setIsPrinting(true);
    dispatch(_analyticsActions.printProfile(placeId));
    // need to wait for profile accordion animations to finish before ingesting content
    setTimeout(printProfile, 470);
  }, [dispatch, placeId, printProfile]);

  const buttonProps = useMemo(
    () => ({
      variant: 'outlined',
      color: 'primary',
      classes: { root: classes.buttonRoot },
    }),
    [classes]
  );

  return (
    <Grid container classes={{ root: classes.container }}>
      {/* @TODO print button functionality */}
      <Grid item>
        <Button
          {...buttonProps}
          onClick={handlePrint}
          ref={printButtonRef}
          startIcon={
            isPrinting ? (
              <CircularProgress thickness={3} size={SPINNER_SIZE} aria-label="" />
            ) : (
              <PrintIcon />
            )
          }
        >
          {isPrinting ? 'Printing' : 'Print'}
        </Button>
      </Grid>
      {!isSharePage && (
        <>
          {showShareButton && (
            <Grid item>
              <Button
                {...buttonProps}
                onClick={handleShare}
                ref={shareButtonRef}
                startIcon={<ShareIcon />}
              >
                Share
              </Button>
            </Grid>
          )}

          {showScheduleButton && (
            <Grid item>
              <Button {...buttonProps} onClick={handleCall} ref={callButtonRef}>
                Need Help?
              </Button>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default PlaceProfileHeaderButtons;

PlaceProfileHeaderButtons.propTypes = {
  placeId: PropTypes.string.isRequired,
  printRef: RefType.isRequired,
};
