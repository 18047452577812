/* eslint-disable import/prefer-default-export */
import { DEFAULT_LOCATIONS, ENSEMBLE, DROPDOWN } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import quantumLogo from 'assets/quantum-health-care-finder.svg';

export const apiTokens = {
  dev: 'Token 850ea3a3dd2f3d7eddcfc7d5e57dc5c3d539fcd1',
  staging: 'Token 3fdb3f99bf04a82ba9dd0e90814144bec98b5ad4',
  prod: 'Token bfc5c31204c281a50a2517a40f1e3a9f0e0d4730',
};

const token = selectToken(apiTokens);

const ensembleConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'ensemblehp',

  API_TOKEN: token,
  EMBOLD_CLIENT: ENSEMBLE,
  EMBOLD_CLIENT_SLUG: ENSEMBLE,

  // toggles

  // login
  SHOW_REGION_SELECTOR: true,
  REGION_SELECTOR_CODES: {},

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Cincinnati },
  LOGIN_NETWORK_NAME: 'ensembleUserId',

  // content
  DISPLAY_NAME: 'Ensemble',
  HEADER_LOGO: quantumLogo,
  FORM_VARIANT: DROPDOWN,
  LOCAL_LOGO_ASSET: quantumLogo,
  EMBOLD_LOGO_PRESENT: false,
  NO_WELCOME_LINK: false,
};

export default ensembleConfig;
