import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { actions, select } from 'store/toolkit';

export default function useStartOver() {
  const locationHistory = useSelector(select.location.locationHistory);

  const lastLocation = useMemo(() => locationHistory[0], [locationHistory]);

  const dispatch = useDispatch();
  const { push } = useHistory(); // destructure push so that the callback is not rewritten anytime the whole history object has a change

  const startOver = useCallback(
    (route) => {
      // reset redux state values
      dispatch(actions.app.startOver({ lastLocation }));

      // this allows us to pass null to this function, in case we want to avoid changing the route
      if (typeof route === 'string') {
        push(route);
      } else if (route !== null) {
        push('/');
      }
    },
    [dispatch, push, lastLocation]
  );

  return startOver;
}
