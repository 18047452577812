/* eslint-disable import/prefer-default-export */
import { DEFAULT_LOCATIONS, JBHUNT, DROPDOWN } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import jbhuntLogo from 'assets/jb-hunt-logo.svg';

export const apiTokens = {
  dev: 'Token ec239e80a893267e51e2a741ac9e25bf7223767f',
  staging: 'Token 50b31f5268c0c6f7d4fc5833d218d81bbbc556c4',
  prod: 'Token 2e5a92c96f2b85545f456d90504bec7c6097c9e3',
};

const token = selectToken(apiTokens);

const jbhuntConfig = {
  CONFIG_NAME: 'jbhunt',

  API_TOKEN: token,
  EMBOLD_CLIENT: JBHUNT,
  EMBOLD_CLIENT_SLUG: JBHUNT,

  // login
  SHOW_REGION_SELECTOR: true,
  REGION_SELECTOR_CODES: {},

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Lowell },
  LOGIN_NETWORK_NAME: 'jbhuntUserId',

  // content
  DISPLAY_NAME: 'J. B. Hunt',
  HEADER_LOGO: jbhuntLogo,
  FORM_VARIANT: DROPDOWN,
  LOCAL_LOGO_ASSET: jbhuntLogo,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};

export default jbhuntConfig;
