import React, { useMemo } from 'react';
import PropTypes, { ProviderSubspecialtyDataShape } from 'propTypes';

import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import FocusAreaTypeIcon from 'components/ModernExperience/FocusAreaTypeIcon';
import AccordionList from 'components/ModernExperience/Profile/AccordionList';
import { SUBSPECIALTY_SECTION } from 'utils/constants';
import useSubspecialtyExplanationText from 'hooks/Provider/useSubspecialtyExplanation';
import SectionSubheader from '../SectionSubheader';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    '& > :nth-child(2)': { marginTop: theme.spacing(1) },
  },
  centersOfExcellenceBox: {
    marginTop: `${theme.spacing(1)}px`,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '10px',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.common.white,
  },
  centerOfExcellenceTitle: {
    color: theme.palette.secondary.main,
  },
  title: {
    display: 'flex',
    alignContent: 'center',
    color: theme.palette.info.main,
    flexWrap: 'wrap',
  },
}));

function FocusAreaContent({ subspecialty, providerId, matchingSpecialty }) {
  const classes = useStyles();
  const allSubspecialties = useSelector(select.specialties.providerSubspecialtiesMap);
  const centersOfExcellence = useSelector(select.provider(providerId).centersOfExcellence);

  const description = allSubspecialties[subspecialty.subspecialtyId]?.description;
  const explanationText = useSubspecialtyExplanationText(subspecialty, {
    asComponent: true,
  });

  return (
    <Box className={classes.contentContainer}>
      {explanationText}
      {Boolean(description) && <Typography>{description}</Typography>}
      {matchingSpecialty && (
        <Box className={classes.centersOfExcellenceBox}>
          <SectionSubheader className={classes.title} component="span" variant="subtitle2">
            <centersOfExcellence.Icon />
            &nbsp;{centersOfExcellence.title}
          </SectionSubheader>
          <Typography>{centersOfExcellence.description}</Typography>
        </Box>
      )}
    </Box>
  );
}

function SectionSpecializations({ providerId, isPrintMode }) {
  const classes = useStyles();
  const subspecialties = useSelector(select.provider(providerId).sortedSubspecialties);
  const fullNameAndTitle = useSelector(select.provider(providerId).fullNameAndTitle);
  const centersOfExcellence = useSelector(select.provider(providerId).centersOfExcellence);

  const accordions = useMemo(
    () =>
      subspecialties.map((subspecialty) => {
        let matchingSpecialty = false;

        if (centersOfExcellence) {
          matchingSpecialty =
            centersOfExcellence?.specialtyIds[0]?.subspecialtyId === subspecialty.subspecialtyId;
        }

        return {
          title: matchingSpecialty ? (
            <>
              <span>{subspecialty.subspecialtyName} </span>
              <span>-</span>{' '}
              <span className={classes.centerOfExcellenceTitle}>{centersOfExcellence.title}</span>
            </>
          ) : (
            subspecialty.subspecialtyName
          ),
          icon: <FocusAreaTypeIcon subspecialty={subspecialty} size={22} />,
          children: (
            <FocusAreaContent
              subspecialty={subspecialty}
              providerId={providerId}
              matchingSpecialty={matchingSpecialty}
            />
          ),
        };
      }),
    [subspecialties, centersOfExcellence, providerId, classes.centerOfExcellenceTitle]
  );

  if (subspecialties.length === 0) return null;

  return (
    <AccordionList
      TitleProps={{
        title: SUBSPECIALTY_SECTION.title,
        icon: <SUBSPECIALTY_SECTION.IconComponent />,
        TooltipProps: {
          message: SUBSPECIALTY_SECTION.description,
          title: SUBSPECIALTY_SECTION.title,
          ariaLabel: `${fullNameAndTitle} ${SUBSPECIALTY_SECTION.title}`,
        },
      }}
      ariaId="provider-specializations"
      isPrintMode={isPrintMode}
      accordions={accordions}
    />
  );
}

export default SectionSpecializations;

FocusAreaContent.propTypes = {
  subspecialty: ProviderSubspecialtyDataShape.isRequired,
  providerId: PropTypes.string.isRequired,
  matchingSpecialty: PropTypes.bool.isRequired,
};

SectionSpecializations.propTypes = {
  providerId: PropTypes.string.isRequired,
  isPrintMode: PropTypes.bool,
};

SectionSpecializations.defaultProps = {
  isPrintMode: false,
};
