import { createSelector } from '@reduxjs/toolkit';
import { DROPDOWN, DROPDOWN_AUTOCOMPLETE } from 'utils/constants';
import { getNetworkDefaultLocation, isCurrentNetworkValid } from './configUtils';
import { CONFIG_SLICE_NAME } from '../slicesNames';

export const all = (state) => state[CONFIG_SLICE_NAME].values.REGION_SELECTOR_CODES;
export const networkGroup = (state) => state[CONFIG_SLICE_NAME].networkGroup;

export const availableSlugs = createSelector([all, networkGroup], (networks, groupSlug) => {
  const allSlugs = Object.keys(networks);
  if (!groupSlug) return allSlugs;

  return allSlugs.filter((networkSlug) => networks[networkSlug].networkGroupSlug === groupSlug);
});

export const currentSlug = createSelector(
  [(state) => state[CONFIG_SLICE_NAME].currentNetworkSlug, availableSlugs],
  // eslint-disable-next-line no-shadow
  (currentNetworkSlug, availableSlugs) =>
    isCurrentNetworkValid(currentNetworkSlug, availableSlugs) ? currentNetworkSlug : null
);

export function bySlug(networkSlug) {
  return createSelector([all], (networks) => networks[networkSlug]);
}

export function configBySlug(networkSlug) {
  return createSelector([all], (networks) => networks[networkSlug].config);
}

// eslint-disable-next-line arrow-body-style
export const networkInputVariant = createSelector([all], (networks) => {
  // determines if network selector should be autocomplete or dropdown
  if (!networks) return null;

  let hasNetworkGroupName = false;
  for (const network in networks) {
    if (networks[network].networkGroupName) {
      hasNetworkGroupName = true;
      break;
    }
  }
  return hasNetworkGroupName ? DROPDOWN_AUTOCOMPLETE : DROPDOWN;
});

export const currentNetwork = createSelector(
  [currentSlug, all],
  (slug, networks) => networks[slug]
);
export const currentConfig = createSelector(
  [currentSlug, all],
  (slug, networks) => networks[slug]?.config
);

/* get 'dataSource' property from network root */
export const currentNetworkDataSource = createSelector(
  [currentNetwork],
  (network) => network?.dataSource || null
);

/**
 * @typedef {Object} DefaultLocation
 * @property {String} city
 * @property {String} state
 * @property {Number} latitude
 * @property {Number} longitude
 */

/**
 * Returns the default location for a given network slug
 * @param {String} networkSlug
 * @returns {DefaultLocation}
 */
export function defaultLocationBySlug(networkSlug) {
  return createSelector(
    [all, (state) => state[CONFIG_SLICE_NAME].values.DEFAULT_LOCATION],
    (networks, clientDefault) =>
      getNetworkDefaultLocation({
        currentNetwork: networks[networkSlug],
        fallbackLocation: clientDefault,
      })
  );
}

export const defaultLocation = createSelector(
  [currentNetwork, (state) => state[CONFIG_SLICE_NAME].values.DEFAULT_LOCATION],
  (network, clientDefault) =>
    getNetworkDefaultLocation({
      currentNetwork: network,
      fallbackLocation: clientDefault,
    })
);

/**
 * Looks for a network-level config value, defaults to client-level.
 * Use this selector for config values that exist on both the network and the client.
 * Check for the property in the API in models/client_config.py and models/network_config.py.
 * @param {string} key
 * @returns rtkSelector
 */
export function getNetworkOrClientConfigValue(key) {
  return createSelector(
    [currentConfig, (state) => state[CONFIG_SLICE_NAME].values],
    (networkConfig, clientConfig) => networkConfig?.[key] ?? clientConfig?.[key]
  );
}

/**
 * Looks for a network-level config value.
 * Use this selector for config values that exist only on the network.
 * Check for the property in the API in models/network_config.py and *not* models/client_config.py.
 *
 * Only resort to fallback value for nullish config value to allow value of false (hence usage of ?? instead of ||)
 *
 * @param {string} key
 * @returns rtkSelector
 */
export function getNetworkConfigValue(key, fallback = null) {
  return createSelector([currentConfig], (networkConfig) => networkConfig?.[key] ?? fallback);
}

/**
 * @returns An object with all available group slugs as keys, and group objects as values
 */
export const availableGroups = createSelector([all], (allNetworks) => {
  const result = {};
  for (const network of Object.values(allNetworks)) {
    const { networkGroupSlug: slug, networkGroupName: name } = network;
    result[slug] = { slug, name };
  }
  return result;
});

export const availableGroupSlugs = createSelector([availableGroups], (groupsObj) =>
  Object.keys(groupsObj)
);
