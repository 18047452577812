import { DEMO, DEFAULT_LOCATIONS, PASSWORD } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import acmeHeaderLogo from 'assets/pg-acme-header-logo.svg';
import acmeLoginLogo from 'assets/pg-acme-login-logo.png';

export const apiTokens = {
  dev: 'Token 74fec37dc920f23166de2c27c1e6f132dec99e1e',
  staging: 'Token 4f1a091af010806e664f27ffcbd0a9b19cc9a060',
  prod: 'Token 8b54554bda38e770fcdcfe3690c85b8f78a875d9',
};

const token = selectToken(apiTokens);

const demoConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'demo',

  API_TOKEN: token,
  EMBOLD_CLIENT: DEMO,
  EMBOLD_CLIENT_SLUG: 'acme',

  // login
  LOGIN_NETWORK_NAME: 'demoUserId',
  SHOW_REGION_SELECTOR: true,
  PASSWORD_REGEX: ['demo', 'i'],

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Nashville },

  // content
  DISPLAY_NAME: 'ACME',
  HEADER_LOGO: acmeHeaderLogo,
  FORM_VARIANT: PASSWORD,
  EMBOLD_LOGO_PRESENT: true,
  LOCAL_LOGO_ASSET: acmeLoginLogo,
  NO_WELCOME_LINK: false,
  DEFAULT_SEARCH_RADIUS: 10,
};

export default demoConfig;
