import { ERROR, INFO, SUCCESS } from '../notifications/notificationsConstants';

export const TEXT_BANNER_VARIANT = 'text';
export const CHAT_BANNER_VARIANT = 'chat';
export const MARKDOWN_BANNER_VARIANT = 'markdown';

export const BANNER_COLORS = ['primary', 'secondary', 'tertiary', INFO, SUCCESS, ERROR];
export const VARIANT_GRADIENT = 'gradient';
export const VARIANT_SOLID = 'solid';

export const VALID_BANNER_VARIANTS = [
  TEXT_BANNER_VARIANT,
  CHAT_BANNER_VARIANT,
  MARKDOWN_BANNER_VARIANT,
];
