import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import useFocusAnchors from 'utils/FocusRefContext';
import { select } from 'store/toolkit';
import SubmitButton from '../SubmitButton';
import CheckboxInput from '../CheckboxInput';
import AgreeToTermsLabel from './AgreeToTermsLabel';
import { CHECKBOX_ERROR_TEXT, PASSWORD_ERROR_TEXT, UNABLE_TO_GET_NETWORK } from './errorText';
import TextInput from '../TextInput';
import FieldErrorText from '../FieldErrorText';
import LoginFormErrors from './LoginFormErrors';

export default function PasswordLogin({ classes, onSubmit }) {
  const focusAnchors = useFocusAnchors();
  const passwordRegex = useSelector(select.client.loginPasswordRegex);
  const slugs = useSelector(select.networks.availableSlugs);

  const initialValues = {
    password: '',
    networkSlug: slugs[0],
    termsChecked: false,
  };

  const validationSchema = Yup.object({
    password: Yup.string().test('Correct Password', PASSWORD_ERROR_TEXT, (value) =>
      passwordRegex.test(value)
    ),
    networkSlug: Yup.string().oneOf(slugs, UNABLE_TO_GET_NETWORK).required(UNABLE_TO_GET_NETWORK),
    termsChecked: Yup.bool().oneOf([true], CHECKBOX_ERROR_TEXT),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false} // don't validate on change for password forms
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      <Form className={classes.root}>
        <TextInput
          ref={focusAnchors.passwordField}
          name="password"
          label="Login ID"
          type="password"
        />
        <CheckboxInput
          ref={focusAnchors.termsChecked}
          name="termsChecked"
          label={<AgreeToTermsLabel />}
          className={classes.termsAgreement}
        />
        <FieldErrorText name="networkSlug" />
        <SubmitButton className={classes.submitButton}>Continue</SubmitButton>
        <LoginFormErrors />
      </Form>
    </Formik>
  );
}

PasswordLogin.propTypes = {
  classes: PropTypes.shape({
    submitButton: PropTypes.string,
    root: PropTypes.string,
    termsAgreement: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
