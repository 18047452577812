import React from 'react';
import { Toolbar, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {},
  dense: { minHeight: 32 },
}));

export default function ChatAppNavigator(props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Toolbar classes={classes} component="header" variant="dense" disabledGutters {...props}>
      <nav aria-label="chat navigation">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link component="button" onClick={history.goBack}>
          &lt; Go Back
        </Link>
      </nav>
    </Toolbar>
  );
}

ChatAppNavigator.propTypes = {};

ChatAppNavigator.defaultProps = {};
