export const FILTER_KEYS = {
  ACCEPTING_NEW_PATIENTS: 'acceptingNewPatients',
  GENDER: 'gender',
  CREDENTIALS: 'credentials',
  LANGUAGES: 'languages',
  SPECIALTIES: 'specialties',
  OUTCARE_COMPETENT: 'outcareCompetent',
  TELEHEALTH_AVAILABLE: 'telehealthAvailable',
  BOARD_CERTIFIED: 'boardCertified',
  HIGH_PERFORMING: 'highPerforming',
  IN_NETWORK_PREFERRED: 'inNetworkPreferred',
  HAS_BENEFIT_DIFF: 'hasBenefitDiff',
  HEALTH_CONNECT_PLAN: 'healthConnectPlan',
  IS_WHEELCHAIR_ACCESSIBLE: 'isWheelchairAccessible',
  FEATURED: 'featured',
  EXCLUDE_CLIENT_FEATURED: 'excludeClientFeatured',
  FEATURED_FACILITY: 'featuredFacility',
  GROUP_AFFILIATIONS: 'groupAffiliations',
  HOSPITAL_AFFILIATIONS: 'hospitalAffiliations',
  SUBSPECIALTIES: 'subspecialties',
  PREFERRED_GROUP: 'preferredGroup',
  MATCHED_ON: 'matchedOn',
};

export const ARRAY_FILTER_TYPE = 'array';
export const BOOL_FILTER_TYPE = 'boolean';

// filter slider marks need a 'false' value in order to display logarithmically rather than linearly
// ie we want a user to have an easy time selecting between 1 mile and 3 miles as well as 250 miles
// these values get used by the mui slider component for display purposes and then converted to their true value with SLIDER_VALUE_TO_RADIUS
export const SLIDER_MARKS = [
  { value: 0, label: '1mi' },
  { value: 10, label: '' },
  { value: 20, label: '' },
  { value: 30, label: '' },
  { value: 40, label: '' },
  { value: 50, label: '' },
  { value: 60, label: '' },
  { value: 70, label: '' },
  { value: 80, label: '250mi' },
];

export const SLIDER_VALUE_TO_RADIUS = {
  0: 1,
  10: 2,
  20: 3,
  30: 5,
  40: 10,
  50: 25,
  60: 50,
  70: 100,
  80: 250,
};

export const RADIUS_TO_SLIDER_VALUE = {
  1: 0,
  2: 10,
  3: 20,
  5: 30,
  10: 40,
  25: 50,
  50: 60,
  100: 70,
  250: 80,
};

export const RADIUS_VALUES = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 5 },
  { value: 10 },
  { value: 25 },
  { value: 50 },
  { value: 100 },
  { value: 250 },
];

export const VALID_RADIUS_VALUES = RADIUS_VALUES.map((obj) => obj.value);
export const MAX_RADIUS_VALUE = RADIUS_VALUES[RADIUS_VALUES.length - 1].value;

// slider values are used as the intermediate state for the Mui Slider component
export const VALID_SLIDER_VALUES = SLIDER_MARKS.map((obj) => obj.value).sort();
export const MIN_SLIDER_VALUES = VALID_SLIDER_VALUES[0];
export const MAX_SLIDER_VALUE = VALID_SLIDER_VALUES[VALID_SLIDER_VALUES.length - 1];
export const SLIDER_VALUE_STEP =
  (MAX_SLIDER_VALUE - MIN_SLIDER_VALUES) / (VALID_SLIDER_VALUES.length - 1);

/**
 * The values accepted by Fusion for the match_on param
 */
export const MATCH_ON_PARAM_VALUES = {
  NAME: 'name',
  AFFILIATIONS: 'affiliations',
  SPECIALTY: 'specialty',
};

/**
 * Maximum number of quick filters to display
 * Note: this does not include "radius", so to total chips should never exceed 5
 */
export const MAX_QUICK_FILTERS = 4;

export const PROVIDER_QUICK_FILTERS_BY_PRIORITY = [
  FILTER_KEYS.ACCEPTING_NEW_PATIENTS,
  FILTER_KEYS.FEATURED,
  FILTER_KEYS.HAS_BENEFIT_DIFF,
  FILTER_KEYS.GENDER,
  FILTER_KEYS.SPECIALTIES,
  FILTER_KEYS.SUBSPECIALTIES,
];
export const FACILITY_QUICK_FILTERS_BY_PRIORITY = [FILTER_KEYS.FEATURED_FACILITY];
