/* eslint-disable no-await-in-loop */
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as selectAxios from '../config/selectAxios';

const fetchInit = createAsyncThunk('fetchInit', async (args, thunkApi) => {
  const { getState } = thunkApi;
  const state = getState();

  if (args.bypassInit) return true;

  const axios = selectAxios.axiosInstance(state);
  await axios('/init/');
  return true;
});

export default {
  fetchInit,
};
