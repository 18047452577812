/* eslint-disable no-shadow */
import { createSelector } from '@reduxjs/toolkit';

import { DEFAULT_SUPPORT_MAILBOX, JPMC } from 'utils/constants';

import { isTodayInDateRange } from 'utils/utils';
import * as selectFeatureFlags from './selectFeatureFlags';
import * as selectClient from './selectClient';
import * as selectNetworks from './selectNetworks';
import { CONFIG_SLICE_NAME } from '../slicesNames';
import { networkConfigKeys } from './configConstants';

/**
 * ************************* *
 * Client level config values
 * ************************* *
 */

/** @returns {string} The name used for the app */
export const appName = (state) => state[CONFIG_SLICE_NAME].values.APP_NAME || 'Provider Guide';

export const brandAndAppName = createSelector(
  [appName, selectFeatureFlags.isWhitelabeled, selectClient.displayName],
  (appName, isWhitelabeled, displayName) => {
    if (!isWhitelabeled) return `Embold Health ${appName}`;
    return `${displayName} ${appName}`;
  }
);

/**
 * The text shown in the network selector menu.
 * This is a config item set in the backend. If no value is present, it will return an empty string
 *
 * @returns {string} The UPDATE_REGION_TEXT value */
export const regionSelectorText = (state) =>
  state[CONFIG_SLICE_NAME].values.UPDATE_REGION_TEXT || '';

/** The text shown on the login page. Written in markdown format to be used inside of an EmboldMarkdown component.
 *
 * This markdown is retrieved from the client config JSON welcome_message property.
 *
 * If the property doesn't exist, a default message is constructed using the client's displayName and appName.
 * @type {Function} A selector function that accepts the Redux state as an arg
 * @returns {string} A welcome message string formatted as markdown.
 *
 * @example
 * function MyComponent() {
 *   const welcomeMessage = useSelector(select.content.welcomeMessage);
 *
 *   return <EmboldMarkdown>{welcomeMessage}</EmboldMarkdown>
 * }
 * */
export const welcomeMessage = createSelector(
  [(state) => state[CONFIG_SLICE_NAME].values.WELCOME_MESSAGE, selectClient.displayName, appName],
  (welcomeMessage, clientName, appName) => {
    if (typeof welcomeMessage === 'string') return welcomeMessage;

    const defaultMessage = `${clientName} and Embold Health have partnered to bring you Embold’s ${appName}, your guide to quickly and easily finding the doctors in your network and area that have proven to provide the best care.`;
    return defaultMessage;
  }
);

/**
 * This text content is shown in the footer above the copyright.
 *
 * @returns {string|undefined} The DISCLAIMER_TEXT value */
export const disclaimerText = (state) => state[CONFIG_SLICE_NAME].values.DISCLAIMER_TEXT;

/** Phone number used to override any SurgyPlus provider's number at all locations.
 * @returns {string} Value of SURGERY_PLUS_PHONE_NUMBER */
export const surgeryPlusPhoneNumber = (state) =>
  state[CONFIG_SLICE_NAME].values.SURGERY_PLUS_PHONE_NUMBER;

// @TODO: TECH-3491 review benefits changing properties. It may be worth updating the names to be more accurate
// This selects all content related to the benefits change and constructs an object with nested values
/**
 * The benefitsChange selector returns an object. See object shape below
 * @returns {object} benefitsChange An object with the title, text, and tooltip content
 * 
 * Deprecated: guageText properties - the gauge design is no longer in use
 * 
 * {
    dateRange: DATE_RANGE,
    title: COVERAGE_CHANGE_TITLE,
    cardAttribute: {
      text: COVERAGE_DECREASING_TEXT,
      tooltip: COVERAGE_DECREASING_TOOLTIP,
    },
    previousBenefit: {
      text: HIGHER_BENEFIT_MODAL_TITLE,
      modalText: HIGHER_BENEFIT_MODAL_TEXT,
    },
    newBenefit: {
      text: LOWER_BENEFIT_MODAL_TITLE,
      modalText: LOWER_BENEFIT_MODAL_TEXT,
    },
 * }
 */
export const benefitsChange = createSelector(
  [
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_COVERAGE_CHANGE_TITLE,
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_COVERAGE_DECREASING_TEXT,
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_COVERAGE_DECREASING_TOOLTIP,
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_HIGHER_BENEFIT_MODAL_TITLE,
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_HIGHER_BENEFIT_MODAL_TEXT,
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_LOWER_BENEFIT_MODAL_TITLE,
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_LOWER_BENEFIT_MODAL_TEXT,
    (state) => state[CONFIG_SLICE_NAME].values.BENEFITS_CHANGE_DATE_RANGE,
  ],
  (
    COVERAGE_CHANGE_TITLE,
    COVERAGE_DECREASING_TEXT,
    COVERAGE_DECREASING_TOOLTIP,
    HIGHER_BENEFIT_MODAL_TITLE,
    HIGHER_BENEFIT_MODAL_TEXT,
    LOWER_BENEFIT_MODAL_TITLE,
    LOWER_BENEFIT_MODAL_TEXT,
    DATE_RANGE
  ) => ({
    dateRange: DATE_RANGE,
    title: COVERAGE_CHANGE_TITLE,
    cardAttribute: {
      text: COVERAGE_DECREASING_TEXT,
      tooltip: COVERAGE_DECREASING_TOOLTIP,
    },
    previousBenefit: {
      text: HIGHER_BENEFIT_MODAL_TITLE,
      modalText: HIGHER_BENEFIT_MODAL_TEXT,
    },
    newBenefit: {
      text: LOWER_BENEFIT_MODAL_TITLE,
      modalText: LOWER_BENEFIT_MODAL_TEXT,
    },
  })
);

export const isTodayInBenefitChangeDateRange = createSelector(
  [benefitsChange],
  (benefitsConfigObj) => {
    if (!benefitsConfigObj?.dateRange) return null;
    return isTodayInDateRange(
      benefitsConfigObj?.dateRange?.start,
      benefitsConfigObj?.dateRange?.end
    );
  }
);

export const featuredProviderText = createSelector(
  [
    (state) => state[CONFIG_SLICE_NAME].values.FEATURED_PROVIDER_BANNER_TEXT,
    (state) => state[CONFIG_SLICE_NAME].values.FEATURED_PROVIDER_TOOLTIP_TEXT,
    (state) => state[CONFIG_SLICE_NAME].values.FEATURED_PROVIDER_COLOR,
    (state) => state[CONFIG_SLICE_NAME].values.FEATURED_PROVIDER_ICON,
    (state) => state[CONFIG_SLICE_NAME].values.FEATURED_PROVIDER_LINK,
  ],
  (bannerText, tooltipText, color, icon, link) => {
    if (!bannerText) return null; // should never have tooltip text without banner text

    return {
      banner: bannerText,
      tooltip: tooltipText,
      link,
      color, // may be undefined
      icon, // may be undefined
    };
  }
);

export const featuredPlaceText = createSelector(
  [
    (state) => state[CONFIG_SLICE_NAME].values.FEATURED_FACILITY_BANNER_TEXT,
    (state) => state[CONFIG_SLICE_NAME].values.FEATURED_FACILITY_TOOLTIP_TEXT,
    (state) => state[CONFIG_SLICE_NAME].values.FEATURED_PROVIDER_COLOR, // this is intentional, we reuse the same icon for featured providers and places currently
    (state) => state[CONFIG_SLICE_NAME].values.FEATURED_PROVIDER_ICON, // this is intentional, we reuse the same icon for featured providers and places currently
    (state) => state[CONFIG_SLICE_NAME].values.FEATURED_FACILITY_LINK,
  ],
  featuredProviderText.resultFunc // reuse the same logic from featuredProviders just with different inputs
);

export const benefitDiffCopy = (state) => state[CONFIG_SLICE_NAME].values.BENEFIT_DIFF_COPY;
export const benefitDiffTooltipTitle = (state) =>
  state[CONFIG_SLICE_NAME].values.BENEFIT_DIFF_TITLE;
export const benefitDiffTooltipDescription = (state) =>
  state[CONFIG_SLICE_NAME].values.BENEFIT_DIFF_TOOLTIP;
export const falseBenefitDiffCopy = (state) =>
  state[CONFIG_SLICE_NAME].values.FALSE_BENEFIT_DIFF_COPY;
export const falseBenefitDiffTooltipTitle = (state) =>
  state[CONFIG_SLICE_NAME].values.FALSE_BENEFIT_DIFF_TITLE;
export const falseBenefitDiffTooltipDescription = (state) =>
  state[CONFIG_SLICE_NAME].values.FALSE_BENEFIT_DIFF_TOOLTIP;
export const benefitDiffDetailMarkdown = (state) =>
  state[CONFIG_SLICE_NAME].values.BENEFIT_DIFF_DETAIL_MARKDOWN;

export const searchErrorText = (state) => state[CONFIG_SLICE_NAME].values.SEARCH_ERROR_TEXT;

export const howToVideoUrl = (state) => state[CONFIG_SLICE_NAME].values.HOW_TO_VIDEO_URL;

export const howToVideoProps = createSelector([howToVideoUrl, appName], (url, appName) => {
  if (!url) return null;

  if (!url.endsWith('.mp4')) throw new Error('how_to_video_url does not have mp4 extension');

  const urlWithNoExtension = url.slice(0, -4);

  const props = {
    title: `How to use ${appName}`,
    sources: [
      { src: `${urlWithNoExtension}.webm`, type: 'video/webm' },
      { src: `${urlWithNoExtension}.mp4`, type: 'video/mp4' },
    ],
    tracks: [{ src: `${urlWithNoExtension}.vtt`, label: 'English', srcLang: 'en' }],
    poster: `${urlWithNoExtension}.jpg`,
  };

  return props;
});

/**
 * ************************* *
 * Network level config values
 * ************************* *
 */

/** Network level config value
 * @returns {string} The SCHEDULE_PHONE_NUMBER value */
export const schedulePhoneNumber = selectNetworks.getNetworkConfigValue(
  networkConfigKeys.SCHEDULE_PHONE_NUMBER
);

/** Text shown as a description in the call modal. Network level config value
 * @returns {string} The SCHEDULE_COPY value */
export const scheduleCopy = selectNetworks.getNetworkConfigValue(networkConfigKeys.SCHEDULE_COPY);

/** Member id link shown in header. Network level config value
 * @returns {string} The MEMBER_ID_LINK value */
export const memberIdLink = selectNetworks.getNetworkConfigValue(networkConfigKeys.MEMBER_ID_LINK);

/** Network level config value, fallback value DEFAULT_SUPPORT_MAILBOX
 * @returns {string} The ZOHO_MAILBOX_ADDRESS value */
export const zohoMailboxAddress = selectNetworks.getNetworkConfigValue(
  networkConfigKeys.ZOHO_MAILBOX_ADDRESS,
  DEFAULT_SUPPORT_MAILBOX
);

/** Network level config value
 * @returns {string} The ZOHO_DEPARTMENT_ID value */
export const zohoDepartmentId = selectNetworks.getNetworkConfigValue(
  networkConfigKeys.ZOHO_DEPARTMENT_ID
);

/** Network level config value
 * @returns {string} The SUPPORT_NAME value */
export const supportName = selectNetworks.getNetworkConfigValue(networkConfigKeys.SUPPORT_NAME);

/** Network level config value
 * @returns {string} The SUPPORT_PHONE_NUMBER value */
export const supportPhoneNumber = selectNetworks.getNetworkConfigValue(
  networkConfigKeys.SUPPORT_PHONE_NUMBER
);

/** Network level config value
 * @returns {string} The SUPPORT_EMAIL value */
export const supportEmail = selectNetworks.getNetworkConfigValue(networkConfigKeys.SUPPORT_EMAIL);

export const featuredActions = createSelector(
  [selectNetworks.getNetworkConfigValue(networkConfigKeys.FEATURED_ACTIONS)],
  (featuredActions) => {
    if (!featuredActions || !Array.isArray(featuredActions)) return [];

    return featuredActions;
  }
);

/**
 * ******************** *
 * Client specific values
 * Note: This should be done sparingly, and only if a feature is limited to a
 * single client. Otherwise create a new client or network config value in fusion.
 * ******************** *
 */

// @TODO: test
/**
 * Markdown text displayed in banner. Same message shown on "No Results"
 * JPMC is the only client requesting this
 * @returns {string}
 */
export const searchNoticeBannerText = createSelector(
  [selectClient.name, searchErrorText],
  (name, searchErrorText) => (name === JPMC ? searchErrorText : null)
);
