import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import { benefitDiffPill } from 'store/slices/results/selectProvider';
import { Grid, Typography, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useThemeColor from 'hooks/useThemeColor';
import RoundedWrapper from 'components/ModernExperience/ResultCards/StyledWrappers/RoundedWrapper';
import EmboldMarkdown from 'components/EmboldMarkdown';
import ShowMoreButton from 'components/ShowMoreButton';
import PropTypes from 'prop-types';

const useStyles = (iconThemeColor) =>
  makeStyles((theme) => ({
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    icon: {
      maxHeight: 24,
      marginRight: 8,
      color: iconThemeColor,
    },
    title: {
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
    markdown: {
      padding: theme.spacing(1),
    },
  }));

export default function BenefitDiffDetailAccordion({ providerId, isPrintMode }) {
  // Only show if hasBenefitDiff is true
  const { hasBenefitDiff } = useSelector(select.provider(providerId).data) || {};
  const { label, color, icon } = useSelector(benefitDiffPill(providerId)) || {};
  const markdown = useSelector(select.content.benefitDiffDetailMarkdown);
  const iconThemeColor = useThemeColor(color || 'primary.main');
  const classes = useStyles(iconThemeColor)();
  const [isExpanded, setIsExpanded] = useState(isPrintMode);
  const titleId = 'benefit-differential-profile-title';
  const contentId = 'benefit-differential-profile-content';

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  if (!hasBenefitDiff || !markdown || !label) return null;

  return (
    <RoundedWrapper grey padBottom>
      <Grid className={classes.header}>
        <Grid item className={classes.title}>
          {Boolean(icon) && <Grid className={classes.icon}>{icon}</Grid>}
          <Typography component="span" variant="subtitle1" id={titleId}>
            {label} Information
          </Typography>
        </Grid>
        {!isPrintMode && (
          <ShowMoreButton
            showMoreText="See details"
            showLessText="Close details"
            onClick={toggleIsExpanded}
            isOpen={isExpanded}
            ariaControls={contentId}
            ariaLabel={label}
            IconProps={{
              color: 'secondary',
            }}
          />
        )}
      </Grid>
      <Collapse in={isExpanded}>
        <Grid id={contentId} className={classes.markdown} role="region" aria-labelledby={titleId}>
          <EmboldMarkdown>{markdown}</EmboldMarkdown>
        </Grid>
      </Collapse>
    </RoundedWrapper>
  );
}

BenefitDiffDetailAccordion.propTypes = {
  providerId: PropTypes.string.isRequired,
  isPrintMode: PropTypes.bool,
};

BenefitDiffDetailAccordion.defaultProps = {
  isPrintMode: false,
};
