import { createAsyncThunk } from '@reduxjs/toolkit';

import { FILTERS_SLICE_NAME } from '../slicesNames';
import * as selectAxios from '../config/selectAxios';
import { transformFilterOptions } from './filterUtils';

export const fetchPredictedResultCount = createAsyncThunk(
  `${FILTERS_SLICE_NAME}/fetchPredictedResultCount`,
  async (args, thunkApi) => {
    const { queryString, wasPlaceSearch } = args;
    const { getState } = thunkApi;
    const state = getState();

    if (!queryString) throw new Error('No queryString provided');

    const axios = selectAxios.axiosInstance(state);

    const resultCountQuery = wasPlaceSearch ? '/places/count/?' : '/providers/count/?';
    const response = await axios.get(resultCountQuery.concat(queryString));

    return response.data;
  }
);

export const fetchFilterOptions = createAsyncThunk(
  `${FILTERS_SLICE_NAME}/fetchFilterOptions`,
  async (queryString, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState();

    if (!queryString) throw new Error('No query string provided');

    const axios = selectAxios.axiosInstance(state);

    // fetch filter options
    const result = await axios.get(`/filter-options/?${queryString}`);

    // transform response into format that the front-end uses
    const transformedResults = transformFilterOptions(result.data);

    // returned the transformed response to the filtersSlice
    return transformedResults;
  }
);
