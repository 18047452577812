import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import EmboldMarkdown from 'components/EmboldMarkdown';
import PropTypes from 'propTypes/index';

function WelcomeMessage({ SkeletonProps, isLoading }) {
  const welcomeMessage = useSelector(select.content.welcomeMessage);

  return isLoading ? (
    <Skeleton
      variant="rect"
      height={100}
      aria-label="welcome message is loading"
      {...SkeletonProps}
    />
  ) : (
    <EmboldMarkdown>{welcomeMessage}</EmboldMarkdown>
  );
}

WelcomeMessage.propTypes = {
  SkeletonProps: PropTypes.shape({}),
  isLoading: PropTypes.bool.isRequired,
};

WelcomeMessage.defaultProps = {
  SkeletonProps: {},
};

export default WelcomeMessage;
