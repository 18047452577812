import { createSlice } from '@reduxjs/toolkit';

import { fetchPromotions } from './promotionsThunks';

const initialState = {
  promotions: [],
  isLoading: false,
  isLoaded: false,
  error: null,
};

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    updatePromotions(state, action) {
      state.promotions = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPromotions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPromotions.rejected, (state) => {
        state.error = true;
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(fetchPromotions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
        const promotions = action.payload;
        if (Array.isArray(promotions)) {
          state.promotions = promotions;
        }
      });
  },
});

export default promotionsSlice;
export const promotionsActions = promotionsSlice.actions;
