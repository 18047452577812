import { useState, useCallback } from 'react';
import useUuid from './useUuid';

/**
 * A reusable hook for controlling tab state for the MUI Tabs component.
 * https://v4.mui.com/api/tabs/#tabs-api
 *
 * @param {*} [initialState=false]
 * @returns {[*, Function, Function, Function]} [state, handleChange]
 *
 * @example
 * function MyTabs() {
 *   const [tab, handleTabChange, getTabProps, getPanelProps] = useTabState("first-tab");
 *
 *   return (
 *     <Tabs value={tab} onChange={handleTabChange}>
 *        <Tab value="first-tab" {...getTabProps("first-tab")}>First Tab</Tab>
 *        <Tab value="second-tab" {...getTabProps("first-tab")}>Second Tab</Tab>
 *     </Tabs>
 *
 *     <div {...getPanelProps("first-tab")}>First Panel</div>
 *     <div {...getPanelProps("second-tab")}>Second Panel</div>
 *   )
 * }
 */
export default function useTabState(initialState = false) {
  const [tabState, setTabState] = useState(initialState);
  const tabGroupId = useUuid();

  const handleTabChange = useCallback(
    (evt, newValue) => {
      setTabState(newValue);
    },
    [setTabState]
  );

  // see example from https://v4.mui.com/components/tabs/#simple-tabs
  const getTabA11yProps = useCallback(
    (id) => ({
      id: `tab-${tabGroupId}-${id}`,
      'aria-controls': `panel-${tabGroupId}-${id}`,
    }),
    [tabGroupId]
  );

  const getPanelA11yProps = useCallback(
    (id) => ({
      role: 'tabpanel',
      id: `panel-${tabGroupId}-${id}`,
      'aria-labelledby': `tab-${tabGroupId}-${id}`,
    }),
    [tabGroupId]
  );

  return [tabState, handleTabChange, getTabA11yProps, getPanelA11yProps];
}
