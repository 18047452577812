import { createSelector } from '@reduxjs/toolkit';
import { REFERRING_CLINIC_SLICE_NAME } from 'store/slices/slicesNames';
import { BCBS_NORTH_CAROLINA } from 'utils/constants';
import * as selectClient from '../config/selectClient';
import { getReferringClinicConfig } from './referringClinicConfig';

export const referringClinic = (state) => state[REFERRING_CLINIC_SLICE_NAME].referringClinic;

export const referringClinicOptions = createSelector(
  [selectClient.name],
  (client) => getReferringClinicConfig(client).options
);

export const referringClinicOptionsGroupBy = createSelector(
  [selectClient.name],
  (client) => getReferringClinicConfig(client).groupBy
);

export const requireReferringClinic = createSelector(
  [(state) => state[REFERRING_CLINIC_SLICE_NAME].requireReferringClinic, selectClient.name],
  (referringClinicIsRequired, client) => referringClinicIsRequired && client === BCBS_NORTH_CAROLINA
);

export const showReferringClinicSelect = createSelector(
  [requireReferringClinic, referringClinicOptions],
  (referringClinicIsRequired, clinicOptions) =>
    referringClinicIsRequired && clinicOptions?.length > 0
);
