/* eslint-disable import/prefer-default-export */
import { BCBS_NORTH_CAROLINA } from 'utils/constants';

const referringClinicConfig = {
  [BCBS_NORTH_CAROLINA]: {
    options: [
      { name: 'Olive Chapel Road', city: 'Apex' },
      { name: 'Crescent Commons Drive | Kildaire Farm', city: 'Cary' },
      { name: 'Amberly | McCrimmon Parkway', city: 'Cary' },
      { name: 'Morrisville', city: 'Morrisville' },
      { name: 'Midtown | Kings Drive', city: 'Charlotte' },
      { name: 'Franklin Street', city: 'Chapel Hill' },
      { name: 'Briar Chapel | Veranda', city: 'Chapel Hill' },
      { name: 'Southpark | Fairview Road', city: 'Charlotte' },
      { name: 'NC Highway 42 West', city: 'Clayton' },
      { name: 'Martin Luther King Jr. Parkway', city: 'Durham' },
      { name: 'Hillsborough Road', city: 'Durham' },
      { name: 'Attain Street', city: 'Fuquay-Varina' },
      { name: 'Main Street Square', city: 'Holly Springs' },
      { name: 'Hinton Oaks Boulevard', city: 'Knightdale' },
      { name: 'Matthews | Sam Newell Road', city: 'Matthews' },
      { name: 'Timber Drive', city: 'Garner' },
      { name: 'Brier Creek Parkway', city: 'Raleigh' },
      { name: 'Avance Psychiatry (now Centers of Excellence)', city: 'Raleigh' },
      { name: 'Oberlin Road', city: 'Raleigh' },
      { name: 'Strickland Road', city: 'Raleigh' },
      { name: 'Perry Creek Road', city: 'Raleigh' },
      { name: 'South Main Street ', city: 'Wake Forest' },
      { name: 'Avance Psychiatry Charlotte', city: 'Charlotte' },
      { name: 'Avance Behavioral Health Centers of Excellence', city: 'Raleigh' },
      { name: 'Avance Neurology', city: 'Raleigh' },
      { name: 'Preston | NW Cary Parkway', city: 'Cary' },
      { name: 'Capital Blvd | Stadium Drive', city: 'Wake Forest' },
      { name: 'Midtown Raleigh | Wake Forest Road', city: 'Raleigh' },
      { name: 'W. Mallard Creek | E. Arbors Drive', city: 'Charlotte' },
      { name: 'Rea Farms | Providence Road', city: 'Charlotte' },
      { name: 'Avance Psychiatry and Pain Management', city: 'Chapel Hill' },
      { name: 'Davidson Family Medicine', city: 'Davidson' },
      { name: 'Broad Street', city: 'Durham' },
      { name: 'RTP', city: 'Durham' },
      { name: 'Waxhaw', city: 'Waxhaw' },
      { name: 'Avance Care Wilmington', city: 'Wilmington' },
      { name: 'Avance Care Pediatrics | Southern Village', city: 'Chapel Hill' },
      { name: 'Avance Care Southeast Cary', city: 'Chapel Hill' },
    ],
    groupBy: 'city',
  },
};

export function getReferringClinicConfig(client) {
  return referringClinicConfig[client] || {};
}
