import { DEMO, DEFAULT_LOCATIONS, DROPDOWN } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import acmeHeaderLogo from 'assets/pg-acme-header-logo.svg';
import acmeLoginLogo from 'assets/pg-acme-login-logo.png';

export const apiTokens = {
  dev: 'Token 72544c7d66f71d808ce57698ee3b1d3e3da7cc06',
  staging: 'Token 626a1d0c06bb79e7962a90a507f0c173c5f55291',
  prod: 'Token 736e081846985b1729bbfbf533e24fe320cf474b',
};

const token = selectToken(apiTokens);

const customerDemoConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'customerdemo',

  API_TOKEN: token,
  EMBOLD_CLIENT: DEMO,
  EMBOLD_CLIENT_SLUG: 'customerdemo',

  // login
  LOGIN_NETWORK_NAME: 'customerDemoUserId',
  SHOW_REGION_SELECTOR: true,

  // other
  BENEFITS_CHANGE: false,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Nashville },

  // content
  DISPLAY_NAME: 'ACME',
  HEADER_LOGO: acmeHeaderLogo,
  FORM_VARIANT: DROPDOWN,
  EMBOLD_LOGO_PRESENT: true,
  LOCAL_LOGO_ASSET: acmeLoginLogo,
  NO_WELCOME_LINK: false,
};

export default customerDemoConfig;
