import { createSlice } from '@reduxjs/toolkit';
import { AUTH_SLICE_NAME } from '../slicesNames';
import * as appThunks from '../../appThunks';
import authThunks from './authThunks';
import configThunks from '../config/configThunks';
import { cookieValue } from '../config/configSlice';

const initialState = {
  authPending: false,
  authSuccess: false,
  authFailed: false,

  isLoggedIn: false,
};

const authSlice = createSlice({
  name: AUTH_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(appThunks.userLoggedIn.fulfilled, (state) => {
        state.isLoggedIn = true;
      })
      .addCase(appThunks.userLoggedIn.rejected, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(appThunks.userLoggedOut.fulfilled, (state) => {
        state.isLoggedIn = false;
      });

    builder
      .addCase(authThunks.fetchInit.pending, (state) => {
        state.authPending = true;
      })
      .addCase(authThunks.fetchInit.fulfilled, (state) => {
        state.authPending = false;
        state.authSuccess = true;
      })
      .addCase(authThunks.fetchInit.rejected, (state) => {
        state.authPending = false;
        state.authFailed = true;
      })
      .addCase(configThunks.fetchClientConfig.fulfilled, (state, action) => {
        // TODO: handle this in a different way, repeated logic in configSlice
        const availableNetworks = Object.keys(action.payload.REGION_SELECTOR_CODES);
        state.isLoggedIn =
          Array.isArray(availableNetworks) && availableNetworks.includes(cookieValue);
      });
  },
});

export default authSlice;
