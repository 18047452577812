import { DEFAULT_LOCATIONS, SURGERY_PLUS, CHECKMARK } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import lanternHeader from 'assets/lantern_header_logo.png';
import lanternLoginLogo from 'assets/lantern_login_logo.png';

export const apiTokens = {
  dev: 'Token d7b1fd5b743e814c1ced5308af3e9816d81fa43a',
  staging: 'Token 8aabcb9b67e4a4bc9082b9dcc47ca5b762ffd2aa',
  prod: 'Token 3ebb19b307fe12abd5d4b25914782b63729e9ea6',
};

const token = selectToken(apiTokens);

const surgeryPlusConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'lantern',

  API_TOKEN: token,
  EMBOLD_CLIENT: SURGERY_PLUS,
  EMBOLD_CLIENT_SLUG: 'surgeryplus',

  // login
  SHOW_REGION_SELECTOR: false,

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Dallas },
  DEFAULT_SEARCH_RADIUS: 50,
  LOGIN_NETWORK_NAME: 'surgeryPlusUserId',

  // content
  DISPLAY_NAME: 'Lantern',
  HEADER_LOGO: lanternHeader,
  FORM_VARIANT: CHECKMARK,
  LOCAL_LOGO_ASSET: lanternLoginLogo,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};

export default surgeryPlusConfig;
