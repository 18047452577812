import React from 'react';
import { useSelector } from 'react-redux';
import { List, Typography } from '@material-ui/core';

import { select } from 'store/toolkit';
import ChatMessage from 'components/Modals/ChatModal/ChatMessage';

function MessageChain(props) {
  const initialMessage = useSelector(select.chat.starterMessage);
  const errorMessage = useSelector(select.chat.errorMessage);
  const conversation = useSelector(select.chat.conversation);
  const a11yMessage = useSelector(select.chat.a11yMessage);

  return (
    <>
      <List {...props}>
        <ChatMessage message={initialMessage} />
        {conversation.map((message, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <ChatMessage key={i} message={message} />
        ))}
        {errorMessage && <ChatMessage message={errorMessage} error />}
      </List>
      <Typography variant="srOnly" aria-live="polite">
        {a11yMessage}
      </Typography>
    </>
  );
}

export default React.memo(MessageChain);
