import React from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import { DEFAULT_SUPPORT_MAILBOX } from 'utils/constants';

function ClientConfigError() {
  return (
    <Grid container justifyContent="center">
      <Grid container justifyContent="center" style={{ maxWidth: 500 }}>
        <Typography variant="h2" color="error">
          Something went wrong
        </Typography>
        <Typography paragraph>
          Sorry for the inconvenience, this doesn&apos;t normally happen. Refresh the page below to
          try again, or if the issue persists contact our support team.
        </Typography>

        <div style={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.reload()}
            style={{ marginBottom: 20 }}
          >
            Try Again
          </Button>
        </div>

        <div style={{ textAlign: 'center' }}>
          <Button
            color="primary"
            href={`mailto:${DEFAULT_SUPPORT_MAILBOX}`}
            style={{ marginBottom: 20, marginLeft: 20 }}
          >
            Contact Support
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export default ClientConfigError;
