import { FILTER_KEYS } from './filterConstants';

/**
 * This map connects the Provider Guide filterKeys used in the filtersSlice (in camelCase)
 * to the Fusion API corresponding filter keys(in snake_case)
 * Exclusion filters are appended with "__exclude"
 */
const filterKeyMap = {
  [FILTER_KEYS.ACCEPTING_NEW_PATIENTS]: 'accepting_new_patients',
  [FILTER_KEYS.GENDER]: 'gender',
  [FILTER_KEYS.CREDENTIALS]: 'title',
  [FILTER_KEYS.LANGUAGES]: 'languages_array__in',
  [FILTER_KEYS.SPECIALTIES]: 'specialty_id__in',
  [FILTER_KEYS.OUTCARE_COMPETENT]: 'outcare_competent',
  [FILTER_KEYS.TELEHEALTH_AVAILABLE]: 'telehealth_available',
  [FILTER_KEYS.BOARD_CERTIFIED]: 'board_certified',
  [FILTER_KEYS.HIGH_PERFORMING]: 'high_performing',
  [FILTER_KEYS.IN_NETWORK_PREFERRED]: 'curated_out',
  [FILTER_KEYS.HAS_BENEFIT_DIFF]: 'has_benefit_diff',
  [FILTER_KEYS.HEALTH_CONNECT_PLAN]: 'health_plan',
  [FILTER_KEYS.IS_WHEELCHAIR_ACCESSIBLE]: 'is_wheelchair_accessible',
  [FILTER_KEYS.FEATURED]: 'client_featured',
  [FILTER_KEYS.EXCLUDE_CLIENT_FEATURED]: 'client_featured__exclude',
  [FILTER_KEYS.FEATURED_FACILITY]: 'client_featured',
  [FILTER_KEYS.GROUP_AFFILIATIONS]: 'group_affiliations__in',
  [FILTER_KEYS.HOSPITAL_AFFILIATIONS]: 'hospital_affiliations__in',
  [FILTER_KEYS.SUBSPECIALTIES]: 'subspecialty_id__in',
  [FILTER_KEYS.PREFERRED_GROUP]: 'group_score_tier',
  [FILTER_KEYS.MATCHED_ON]: 'match_on',
};

export default filterKeyMap;
