/* eslint-disable import/prefer-default-export */
/**
 * Shapes a user's message to the expected payload of the `/specialty-ai-search` fusion endpoint
 * @param {string} content
 * @returns {Object} payload
 * @returns {Object} payload.messages
 * @returns {string} payload.messages.role
 * @returns {string} payload.messages.content
 */
export const formatAiSearchPayload = ({ content, aiSearchKey }) => {
  if (typeof content !== 'string') return null;
  return {
    chatKey: aiSearchKey,
    messages: {
      role: 'user',
      content,
    },
  };
};

/**
 * Reformats the recommendations.specialties array
 * { id, name, description } => { specialtyId, specialtyName, description }
 */
export const formatAiSearchResponse = (response) => {
  const { specialties } = response.recommendations;
  response.recommendations.specialties = specialties.map(
    ({ id: specialtyId, name: specialtyName, description }) => ({
      specialtyId,
      specialtyName,
      description,
    })
  );
  return response;
};
