import { createTheme, darken, lighten } from '@material-ui/core/styles'; // view colorManipulator functions from MUI here: https://github.com/mui/material-ui/blob/v4.x/packages/material-ui/src/styles/colorManipulator.js
import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import blue from '@material-ui/core/colors/blue';
import teal from '@material-ui/core/colors/teal';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import orange from '@material-ui/core/colors/orange';
import grey from '@material-ui/core/colors/grey';

const iconSizes = {
  small: '.875rem',
  medium: '1.125rem',
  large: '2rem',
};

const {
  palette: defaultPalette,
  spacing,
  shadows,
} = createTheme({
  palette: {
    contrastThreshold: 4.5, // AA a11y standards for text contrast ratio

    /* **** Main MUI Colors **** */
    primary: {
      main: '#00205B',
    },
    secondary: {
      main: '#166FC8',
    },
    info: {
      main: '#166FC8',
    },
    success: {
      light: '#00B407',
      main: '#01801D',
      dark: '#236731',
    },
    warning: {
      main: '#C34515',
    },
    error: {
      main: '#D73737',
    },

    grey,

    /* **** Custom Colors **** */
    tertiary: {
      light: '#f6685e',
      main: '#F25645',
      dark: '#9c4336',
      contrastText: '#FFFFFF',
    },

    text: {
      secondary: grey[700],
    },

    focusIndicator: '#166FC8',

    scoreTier: {
      1: '#236731',
      2: '#00205B',
      3: '#C34515',
    },

    pink,
    red,
    orange,
    yellow,
    green,
    teal,
    blue,
    purple,
  },
});

export function getDefaultClassOverrides(palette = defaultPalette) {
  // generate MUI Class overrides based on a given set of palette colors
  return {
    MuiCssBaseline: {
      '@global': {
        '*:focus-visible': {
          outlineColor: palette.focusIndicator, // catch-all for non MUI components ex: buttons used inside azure maps
          outlineWidth: 2,
          outlineStyle: 'solid',
        },
      },
    },
    MuiDrawer: {
      root: {
        zIndex: '1600!important',
      },
    },
    MuiToolbar: {
      regular: {
        '@media (min-width: 0px) and (orientation: landscape) and (max-width: 600px)': {
          minHeight: 56,
        },
      },
    },
    MuiLink: {
      root: {
        '&$focusVisible': {
          outlineColor: palette.focusIndicator,
        },
      },
      button: {
        '&$focusVisible': {
          outlineColor: palette.focusIndicator,
          outlineOffset: 2,
        },
      },
    },

    /* ******************** */
    /* ***** Buttons ****** */
    /* ******************** */
    MuiButtonBase: {
      root: {
        fontFamily: 'Lato, Arial, sans-serif',
        overflowY: 'hidden',
        overflowX: 'visible',
        '&$focusVisible': {
          outlineWidth: 2,
          outlineStyle: 'solid',
          outlineColor: palette.focusIndicator,
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: 'bold',
        borderRadius: 50,
        lineHeight: 'inherit',
      },
      iconSizeSmall: {
        '& > *:first-child': { fontSize: iconSizes.small },
      },
      iconSizeMedium: {
        '& > *:first-child': { fontSize: iconSizes.medium },
      },
      iconSizeLarge: {
        '& > *:first-child': { fontSize: iconSizes.large },
      },
      contained: {
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
        '&$focusVisible': {
          outlineWidth: 3,
          outlineOffset: 0,
          outlineColor: palette.grey[700],
          boxShadow: `0 0 0 5px ${palette.common.white} !important`, // !important is necessary because by default, disableElevation will remove all box shadow and has higher specificity
        },
        '&.tertiary': {
          backgroundColor: palette.tertiary.main,
          '&.Mui-disabled': {
            backgroundColor: palette.action.disabledBackground,
          },
          '&:hover': {
            backgroundColor: palette.tertiary.dark,
          },
          '&.Mui-focusVisible': {
            outlineColor: lighten(palette.tertiary.main, 0.1),
            backgroundColor: darken(palette.tertiary.main, 0.6),
          },
        },
      },
      containedPrimary: {
        '&$focusVisible': {
          outlineColor: lighten(palette.primary.main, 0.5),
          backgroundColor: darken(palette.primary.main, 0.3),
        },
      },
      containedSecondary: {
        '&$focusVisible': {
          outlineColor: darken(palette.secondary.main, 0.5),
        },
      },
      outlined: {
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
        '&$focusVisible': {
          outlineColor: palette.grey[700],
        },
        '&.white-background': {
          backgroundColor: palette.common.white,
        },
      },
      outlinedPrimary: {
        borderColor: palette.primary.main, // by default, MUI uses a .light color for the border unless hovered, override that
        '&$focusVisible': {
          outlineColor: palette.primary.main,
        },
        '&.white-background:hover': {
          backgroundColor: darken(palette.common.white, 0.1),
        },
      },
      outlinedSecondary: {
        borderColor: palette.secondary.main, // by default, MUI uses a .light color for the border unless hovered, override that
        '&$focusVisible': {
          outlineColor: palette.secondary.main,
        },
        '&.white-background:hover': {
          backgroundColor: darken(palette.common.white, 0.1),
          borderColor: palette.primary.dark,
          color: palette.primary.dark,
        },
      },
    },
    MuiFab: {
      root: {
        '&$focusVisible': {
          outlineOffset: 1,
          outlineColor: palette.grey[700],
        },
      },
      primary: {
        '&$focusVisible': {
          outlineColor: lighten(palette.primary.main, 0.5),
          backgroundColor: darken(palette.primary.main, 0.3),
        },
      },
      secondary: {
        '&$focusVisible': {
          outlineColor: lighten(palette.secondary.main, 0.5),
          backgroundColor: darken(palette.secondary.main, 0.3),
        },
      },
    },
    MuiIconButton: {
      root: {
        '&.Mui-focusVisible': {
          outlineOffset: -2,
        },
      },
    },
    MuiDivider: {
      root: {
        margin: '1rem 0',
      },
    },
    MuiPopover: {
      paper: {
        padding: '0.5rem',
      },
    },
    /* ******************** */
    /* ***** Inputs ****** */
    /* ******************** */
    MuiTextField: {
      root: {
        // custom rounded class for outlined variant only
        '&.rounded': {
          '& .MuiOutlinedInput-notchedOutline': { borderRadius: 32 },
          '& .MuiOutlinedInput-input': {
            marginLeft: spacing(0.5),
            paddingLeft: spacing(2),
            paddingRight: spacing(2),
          },
          '& .MuiOutlinedInput-input.MuiOutlinedInput-inputAdornedStart': {
            marginLeft: 0,
            paddingLeft: 0,
          },
        },

        // custom square class
        '&.square': {
          '& .MuiOutlinedInput-notchedOutline': { borderRadius: 0 },
        },
      },
    },

    MuiFormLabel: {
      root: {
        margin: '0.5rem 0',
        color: palette.text.secondary,
        fontWeight: 'bold',
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '1rem',
        '&::placeholder': {
          fontSize: '1rem',
          opacity: 0.8,
        },
      },
    },
    MuiInputLabel: {
      root: {
        margin: 0,
        textTransform: 'none',
        fontWeight: 'normal',
      },
      shrink: {
        background: palette.background.paper,
        paddingLeft: 2,
      },
      outlined: {
        // the reason these transform properties need to be modified, is because we have globally changed the MuiInputBase font size
        transform: 'translate(14px, 21px) scale(1)',
        '&$marginDense': {
          transform: 'translate(14px, 13px) scale(1)',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'inherit',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&.Mui-focused $notchedOutline': {
          borderColor: palette.focusIndicator,
        },
      },
      notchedOutline: {
        borderRadius: 10,
        borderColor: palette.grey[600],
      },
    },
    MuiFormGroup: {
      root: {
        padding: '0.5rem 1rem',
      },
    },
    MuiPaper: {
      root: {
        '&.grey': {
          backgroundColor: palette.grey[100],
        },
      },
      rounded: {
        borderRadius: 12,
      },
    },
    MuiDialogContent: {
      root: {
        '&:first-child': {
          paddingTop: 8,
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '16px 24px 8px',
        textAlign: 'center',
      },
    },
    MuiSnackbar: {
      root: {
        zIndex: 1600,
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: palette.tertiary.main,
      },
    },
    MuiMenuItem: {
      root: {
        '&.Mui-focusVisible': {
          outline: `2px solid ${palette.focusIndicator}`,
          background: 'none',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
        '&.Mui-focusVisible.Mui-selected': {
          background: '#e0e0e0',
        },
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          // providing an underline allows for a non-color visual indicator.
          // without it, we would be required to have a 3:1 contrast ratio with the background
          textDecoration: 'underline',
        },
      },
    },
    MuiAutocomplete: {
      option: {
        '&[aria-selected="true"]': {
          // providing an underline allows for a non-color visual indicator.
          // without it, we would be required to have a 3:1 contrast ratio with the background
          textDecoration: 'underline',
        },
        '&[data-focus="true"]': {
          outlineOffset: -3,
          outline: `2px solid ${palette.focusIndicator}`,
        },
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: { fontSize: iconSizes.small },
      fontSizeLarge: { fontSize: iconSizes.large },
    },
    MuiTab: {
      root: {
        '&.Mui-focusVisible': {
          outlineOffset: -1 * spacing(0.75),
        },
      },
    },
    MuiRadio: {
      root: {
        '&.MuiButtonBase-root.Mui-focusVisible': {
          outline: 'none',
        },
        '&.Mui-focusVisible > .MuiIconButton-label': {
          outline: `2px solid ${palette.focusIndicator}`,
        },
      },
    },
    MuiCheckbox: {
      root: {
        '&.MuiButtonBase-root.Mui-focusVisible': {
          outline: 'none',
        },
        '&.Mui-focusVisible > .MuiIconButton-label': {
          outline: `2px solid ${palette.focusIndicator}`,
        },
      },
    },
    MuiSlider: {
      root: {
        '&.Mui-disabled $markLabel': {
          color: palette.action.disabled,
        },
      },
      // slider customized to follow this design: https://www.figma.com/design/GhY79G8MF8Qfmjmus4UYly/Filter-and-Search-UX-Exploration?node-id=1650-3289&p=f&t=yOl2BBe6yADXWtle-0
      track: { height: 12, borderRadius: 6 },
      rail: {
        height: 8,
        backgroundColor: grey[500],
        marginTop: 2,
        borderRadius: 6,
      },
      trackInverted: {
        '& $track': {
          // match rail styles
          height: 8,
          backgroundColor: grey[500],
          marginTop: 2,
          borderRadius: 6,
        },
        '& $rail': {
          backgroundColor: palette.primary.main,
        },
      },

      colorSecondary: {
        '&$trackInverted $rail': {
          backgroundColor: palette.secondary.main,
        },
      },

      mark: {
        // the "ticks" shown as stopping points for the thumb
        marginTop: 2, // match rail position
        height: 8, // match rail height
        '&[data-index="0"]': { display: 'none' }, // hides the first tick
        '&:has(+ $thumb), &:has(+ $markLabel + $thumb)': { display: 'none' }, // hides the last tick - selecting the last tick by assuming it will be followed by the thumb, or followed by a label that is followed by the thumb

        '&.hidden': { display: 'none' },
      },
      markActive: {
        marginTop: 0, // match track position
        height: 12, // match track height
      },
      thumb: {
        marginTop: -7,
        height: 24,
        width: 24,
        color: palette.background.paper,
        border: `2px solid ${palette.grey[700]}`,

        '&.Mui-disabled': {
          marginTop: -7,
          height: 24,
          width: 24,
          borderColor: palette.action.disabled,

          '& $valueLabel > span > span': {
            color: palette.action.disabled,
          },
        },
      },

      thumbColorPrimary: {
        borderColor: palette.primary.main,

        '& $valueLabel > span > span': {
          color: palette.primary.main,
        },
      },

      thumbColorSecondary: {
        borderColor: palette.secondary.main,
        '& $valueLabel > span > span': {
          color: palette.secondary.main,
        },
      },

      valueLabel: {
        // the "popup" that appears on focus or hover of the thumb
        left: `calc(-50% + 4px)`,
        top: -28,
        color: 'transparent',
        '& > span': {
          // the PrivateValueLabel-circle
          color: 'inherit',
        },
      },
      marked: {
        marginBottom: spacing(4), // space for mark labels
      },
      markLabel: {
        marginTop: spacing(2),
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: palette.common.white,
        color: palette.text.primary,
        fontSize: '.75rem',
        boxShadow: shadows[8],
        padding: spacing(1),
        borderRadius: spacing(1),
      },
      arrow: {
        color: palette.common.white,
      },
    },
  };
}

const defaultTheme = {
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiButtonGroup: {
      disableRipple: true,
      disableElevation: true,
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MuiRadio: {
      color: 'primary',
    },
    MuiLink: {
      color: 'secondary',
      underline: 'always',
    },
    MuiBadge: {
      overlap: 'rectangular', // resolves deprecation warning
    },
    MuiSwitch: {
      color: 'primary',
    },
    MuiSkeleton: {
      animation: 'wave',
    },
    MuiTextField: {
      variant: 'outlined',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: 'Lato, Arial, sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 'bold',
      margin: '0.5rem 0',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: 1.15,
      margin: '0.5rem 0',
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 'bold',
      margin: '0.25rem 0',
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '1rem',
    },
    h6: {
      fontSize: '.875rem',
    },
    body1: {
      fontSize: '.875rem',
    },
    body2: {
      fontSize: '.875rem',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    subtitle2: {
      fontSize: '.875rem',
      fontWeight: 'bold',
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: 1.25,
      display: 'inline-block',
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: defaultPalette,
  overrides: getDefaultClassOverrides(),
  iconSizes,
};

export default defaultTheme;
