import React from 'react';
import PropTypes from 'propTypes/index';
import { makeStyles } from '@material-ui/styles';
import { getLineIntroAnimation, getSpinAnimation, getSpinIntroAnimation } from './utils';

const useStyles = ({ size, thickness, fullWidth, duration, animations }) =>
  makeStyles((theme) => ({
    '@keyframes lineIntro': animations.lineIntro,
    '@keyframes spinIntro': animations.spinIntro,
    '@keyframes spin': animations.spin,
    spinner: {
      animationName: '$spinIntro, $spin',
      animationDuration: `${duration.intro}s, ${duration.loop}s`,
      animationTimingFunction: 'ease-in, linear',
      animationIterationCount: '1, infinite',
      animationDelay: `0s, ${duration.intro}s`,
      transform: animations.spin['0%'].transform, // ensure "transform" equals first frame of spin animation (should also equal last frame of intro animation)
      height: size,
      width: size,
      '& rect': {
        animationName: '$lineIntro',
        animationIterationCount: 1,
        animationDuration: `${duration.intro}s`,
        animationTimingFunction: 'linear',
        height: animations.lineIntro['100%'].height, // ensure "height" equals last frame of intro animation
        transform: `translate(0, ${(size - thickness) / 2}px)`,
        width: fullWidth ? size : size / 2,
        fill: theme.palette.common.white,
      },
    },
  }));

function Spinner({ size, thickness, sequence, fullWidth, duration }) {
  const animations = {
    lineIntro: getLineIntroAnimation(thickness),
    spinIntro: getSpinIntroAnimation(sequence),
    spin: getSpinAnimation(sequence),
  };
  const classes = useStyles({ sequence, size, thickness, fullWidth, duration, animations })();

  return (
    <svg className={classes.spinner} xmlns="http://www.w3.org/2000/svg">
      <rect />
    </svg>
  );
}

export default React.memo(Spinner);

Spinner.propTypes = {
  duration: PropTypes.shape({
    intro: PropTypes.number.isRequired,
    loop: PropTypes.number.isRequired,
  }).isRequired,
  size: PropTypes.number.isRequired,
  thickness: PropTypes.number.isRequired,
  sequence: PropTypes.arrayOf(PropTypes.number).isRequired,
  fullWidth: PropTypes.bool,
};

Spinner.defaultProps = {
  fullWidth: false,
};
