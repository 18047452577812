import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import CollapsingProfile from 'components/ModernExperience/Profile/CollapsingProfile';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > li': {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
      '&:first-child': { paddingTop: theme.spacing(1) },
      '&:last-child': { paddingBottom: theme.spacing(1) },
    },
  },
}));
export default function CollapsingProfileList({ resultIds = [], renderProfile, onOpen, ...props }) {
  const [openIds, setOpenIds] = useState({});
  const classes = useStyles();

  const handleClick = useCallback(
    (id) => {
      // before { 123: true } >>> handleClick(123) >>> after { 123: false }
      // before { 123: true } >>> handleClick(456) >>> after { 123: true, 456: true }
      setOpenIds((prevIdObj) => {
        if (!prevIdObj[id] && typeof onOpen === 'function') {
          onOpen(id);
        }
        return { ...prevIdObj, [id]: !prevIdObj[id] };
      });
    },
    [setOpenIds, onOpen]
  );

  if (!resultIds?.length) return null;

  return (
    <List classes={classes} {...props}>
      {resultIds.map((id) => (
        <CollapsingProfile
          key={`profile-list-item-${id}`}
          id={id}
          isOpen={!!openIds[id]}
          onClick={handleClick}
          wrapper={ListItem}
          renderProfile={renderProfile}
        />
      ))}
    </List>
  );
}

CollapsingProfileList.propTypes = {
  resultIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  renderProfile: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
};

CollapsingProfileList.defaultProps = {
  onOpen: undefined,
};
