import React from 'react';
import PropTypes from 'propTypes';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import { openInNewTab } from 'utils/utils';
import DetailsButton from '../DetailsButton';

function ProviderDetailsButton({ providerId, profileUrl: overrideUrl, buttonClasses }) {
  const accessibleLabel = useSelector(select.provider(providerId).fullNameAndTitle);
  const profileDetailsLink = useSelector(
    // if there is a profileUrl prop, we want to prefer that and not call select.results.profileDetailsLink at all
    overrideUrl ? () => overrideUrl : select.results.profileDetailsLink(providerId)
  );
  const history = useHistory();

  const handleProviderDetailsClick = () => {
    if (process.env.REACT_APP_STANDALONE_CHAT) {
      history.push(profileDetailsLink);
    } else {
      openInNewTab(profileDetailsLink);
    }
  };

  return (
    <DetailsButton
      onClick={handleProviderDetailsClick}
      accessibleLabel={accessibleLabel}
      buttonClasses={buttonClasses}
    />
  );
}

export default React.memo(ProviderDetailsButton);

ProviderDetailsButton.propTypes = {
  providerId: PropTypes.string.isRequired,
  buttonClasses: PropTypes.string,
  profileUrl: PropTypes.string, // optional override for the url
};

ProviderDetailsButton.defaultProps = {
  buttonClasses: '',
  profileUrl: undefined,
};
