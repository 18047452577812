/* eslint-disable import/prefer-default-export */
import { DEFAULT_LOCATIONS, SOCT_PROVIDER_LOOKUP, DROPDOWN } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import soctLogo from 'assets/soct_logo.svg';

export const apiTokens = {
  dev: 'Token 70397bf834f307e965d40b8ebb9a0eaf9807f4e6',
  staging: 'Token 5d594fe981620ea7b15615885c795ec5859ba461',
  prod: 'Token 7e18e7532ed2f16ce9d39318c2cd31f02b2b0570',
};

const token = selectToken(apiTokens);

const soctProviderLookupConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'carecompass-providerlookup',

  API_TOKEN: token,
  EMBOLD_CLIENT: SOCT_PROVIDER_LOOKUP,
  EMBOLD_CLIENT_SLUG: SOCT_PROVIDER_LOOKUP,

  // toggles
  HIGH_QUALITY_INTRO_MODAL: true,

  // login
  SHOW_REGION_SELECTOR: true,
  REGION_SELECTOR_CODES: {},

  // other
  BENEFITS_CHANGE: false,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Hartford },
  LOGIN_NETWORK_NAME: 'soctProviderLookupUserId',

  // content
  DISPLAY_NAME: 'State of Connecticut',
  HEADER_LOGO: soctLogo,
  FORM_VARIANT: DROPDOWN,
  LOCAL_LOGO_ASSET: soctLogo,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};

export default soctProviderLookupConfig;
