import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { FileCopy } from '@material-ui/icons';
import { ERROR, SUCCESS } from 'store/slices/notifications/notificationsConstants';

function CopyTextButton({ textToCopy, buttonText, anchorOrigin, onAfterCopy, ...props }) {
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [isCopyError, setIsCopyError] = useState(false);

  const handleClick = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setSnackbarMessage('Copied to Clipboard');
      setIsCopyError(false);
      onAfterCopy();
    } catch (e) {
      setIsCopyError(true);
      setSnackbarMessage('Failed to copy to clipboard');
    } finally {
      // this clears the message after 2s to hide the snackbar
      setTimeout(() => {
        setSnackbarMessage(null);
      }, 2000);
    }
  }, [onAfterCopy, textToCopy]);

  const buttonProps = {
    'aria-label': 'Copy text to clipboard',
    variant: 'outlined',
    color: 'primary',
    onClick: handleClick,
  };

  const CopyButton = buttonText ? (
    <Button startIcon={<FileCopy />} {...buttonProps} {...props}>
      {buttonText}
    </Button>
  ) : (
    <IconButton {...buttonProps} {...props}>
      <FileCopy />
    </IconButton>
  );

  return (
    <>
      {CopyButton}
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={Boolean(snackbarMessage)}
        message="Copied to Clipboard"
      >
        <Alert severity={isCopyError ? ERROR : SUCCESS}>{snackbarMessage}</Alert>
      </Snackbar>
    </>
  );
}

CopyTextButton.propTypes = {
  textToCopy: PropTypes.string,
  buttonText: PropTypes.string,
  onAfterCopy: PropTypes.func,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
};

CopyTextButton.defaultProps = {
  textToCopy: '',
  buttonText: '',
  onAfterCopy: () => {},
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export default React.memo(CopyTextButton);
