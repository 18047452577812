import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import {
  CARE_CATEGORIES,
  CARE_CATEGORY_OPTIONS,
  PLACE_RESULT_TYPE,
  PLACES_BY_IDS,
  PROVIDER_RESULT_TYPE,
  PROVIDERS_BY_IDS,
} from 'utils/constants';
import { clearSearchForm, updateStoreFromUrl, startOver } from 'store/appActions';
import { logDevMessage } from 'utils/utils';
import { SEARCH_SLICE_NAME } from 'store/slices/slicesNames';
import * as chatActions from 'store/slices/chat/chatSlice';
import { alternateSuggestedSearch } from '../results/resultsThunks';
import { getTypeSuggestions } from './searchThunks';
import {
  AUTOCOMPLETE_INITIAL,
  AUTOCOMPLETE_FETCHING_INITIAL,
  AUTOCOMPLETE_SUGGESTION_KEYS,
  FREEFORM_SEARCH_TYPE_MAP,
  VALID_SEARCH_TYPES,
} from './searchConstants';
import { AFFILIATION_TYPES } from '../results/providerConstants';
import { getRelatedFreeformCategory } from './searchUtils';

const initialState = {
  type: CARE_CATEGORIES.PROVIDER_NAME,
  text: '',
  isSuggestionSelected: false,
  autocompleteValue: {}, // this can't be null or else it will break the aria-selected state on the autocompleteType options. See https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/useAutocomplete/useAutocomplete.js#L990
  autocompleteFetchingMap: AUTOCOMPLETE_FETCHING_INITIAL,
  autocompleteSuggestionsMap: AUTOCOMPLETE_INITIAL,

  // search type id's
  serviceId: null,
  specialtyId: null,
  subspecialtyId: null,
  entityId: null,
  entityIds: null,
  affiliationName: null,
  affiliationType: null,
};

const searchSlice = createSlice({
  name: SEARCH_SLICE_NAME,
  initialState,
  reducers: {
    changeType(state, action) {
      const newSearchType = action.payload;

      if (VALID_SEARCH_TYPES.includes(newSearchType)) {
        state.type = newSearchType;
        state.text = '';
      }
    },
    handleTextInput(state, action) {
      const { value, reason } = action.payload;
      if (reason !== 'input') return;

      state.text = value;
      state.isSuggestionSelected = false;
      state.specialtyId = null;
      state.subspecialtyId = null;
      state.entityId = null;
      state.serviceId = null;
      state.type = getRelatedFreeformCategory(state.type);
    },
    changeAutocompleteType(state, action) {
      const { value, reason } = action.payload;
      if (reason === 'select-option' && value.isSearchTypeOption) {
        state.type = FREEFORM_SEARCH_TYPE_MAP[value.value];
        state.text = '';
      }
    },
    autocompleteCleared(state) {
      state.text = '';
      state.autocompleteValue = {};
      state.subspecialtyId = null;
      state.specialtyId = null;
      state.serviceId = null;
      state.entityId = null;
    },
    suggestionSelected(state, action) {
      const { value, reason } = action.payload;
      if (reason !== 'select-option') {
        logDevMessage(
          `${SEARCH_SLICE_NAME}/suggestionSelected was dispatched by an Autocomplete component for a reason other than "option-selected"`
        );
        return;
      }

      if (value.isFreeformOption) {
        // handle freeform search
        state.isSuggestionSelected = false;
        state.type = getRelatedFreeformCategory(state.type);
        return;
      }

      state.autocompleteValue = value;
      state.type = value.group;
      state.text = value.name;
      state.isSuggestionSelected = true;

      state.subspecialtyId = value.subspecialtyId > -1 ? value.subspecialtyId : null; // negative subspecialties suggest invalid subspecialty
      state.specialtyId = value.specialtyId || null;
      state.serviceId = value.serviceId || null;
      state.entityId = value.entityId || null;
      state.affiliationName = value.affiliationName || null;
      state.affiliationType = value.affiliationType || null;
    },
    quickSearchSelected(state, action) {
      const { type, name } = action.payload;

      const commonSearch = CARE_CATEGORY_OPTIONS[type].commonSearches.find(
        (cs) => cs.name === name
      );

      if (!commonSearch) throw new Error(`No common search for type ${type} with name ${name}`);

      state.type = type;
      state.text = commonSearch.name;
      state.serviceId = commonSearch.serviceId || null;
      state.specialtyId = commonSearch?.specialtyId || null;
      state.subspecialtyId = commonSearch?.subspecialtyId || null;

      state.isSuggestionSelected = true; // all quick searches should be related to an autocomplete suggestion
    },
    overwriteSlice(state, action) {
      if (typeof action.payload !== 'object') {
        Sentry.captureMessage(`search/overwriteSlice payload type ${typeof action.payload}`);
        return state;
      }

      return { ...state, ...action.payload };
    },
    showMoreProvidersBySpecialty(state, action) {
      const { specialty, specialtyId } = action.payload;
      state.type = CARE_CATEGORIES.PROVIDER_SPECIALTY;
      state.text = specialty;
      state.entityId = null;
      state.specialtyId = specialtyId;
    },
    expandSubspecialtySearchToParentSpecialty(state, action) {
      // TECH-3302 This can be removed, the modern experience search will use breadcrumbClicked instead.
      const { text } = action.payload;
      state.text = text;
      state.subspecialtyId = null;
    },
    selectSpecialtyFromAppendix(state, action) {
      const {
        specialtyName,
        specialtyId,
        subspecialtyId,
        subspecialtyName,
        parentSpecialtyId,
        searchType,
      } = action.payload;
      if (parentSpecialtyId) {
        // subspecialty search
        if (!subspecialtyName) throw new Error('Missing subspecialty name');
        if (!subspecialtyId) throw new Error('Missing subspecialty id');
        state.text = subspecialtyName;
        state.specialtyId = parentSpecialtyId;
        state.subspecialtyId = subspecialtyId;
      } else {
        if (!specialtyName) throw new Error('Missing specialty name');
        if (!specialtyId) throw new Error('Missing specialty id');
        state.text = specialtyName;
        state.specialtyId = specialtyId;
        state.subspecialtyId = null;
      }

      state.type = searchType;
      state.serviceId = null;
      state.entityId = null;
      state.isSuggestionSelected = true;
    },
    breadcrumbClicked(state, action) {
      const { specialtyName, specialtyId } = action.payload;
      if (!specialtyName) throw new Error('Missing specialty name');
      if (!specialtyId) throw new Error('Missing specialty id');

      state.text = specialtyName;
      state.specialtyId = specialtyId;

      state.subspecialtyId = null;
      state.serviceId = null;
      state.entityId = null;

      state.isSuggestionSelected = true;
    },
    setEntityId(state, action) {
      state.entityId = action.payload;
    },
    setSingleProviderSearch(state, action) {
      state.type = CARE_CATEGORIES.PROVIDER_NAME;
      state.entityId = action.payload;
    },
    setMultiProviderSearch(state, action) {
      state.type = PROVIDERS_BY_IDS;
      state.entityIds = action.payload;
    },
    setPlacesByIdsSearch(state, action) {
      state.type = PLACES_BY_IDS;
      state.entityIds = action.payload;
    },
    setAutocompleteTypesFetching(state, action) {
      const { careCategories, isFetching } = action.payload;
      if (!careCategories || isFetching === undefined) {
        logDevMessage("'careCategories' and 'isFetching' params are required");
        return;
      }
      careCategories.forEach((cat) => {
        state.autocompleteFetchingMap[cat] = isFetching;
      });
    },
    clearAutocompleteSuggestions(state) {
      state.autocompleteSuggestionsMap = AUTOCOMPLETE_INITIAL;
    },
    featuredActionSearch(state, action) {
      const { specialty, subspecialty } = action.payload;

      const typeMap = {
        F: CARE_CATEGORIES.FACILITY_TYPE,
        P: CARE_CATEGORIES.PROVIDER_SPECIALTY,
      };

      state.type = typeMap[specialty.type];
      state.isSuggestionSelected = true;

      if (subspecialty) {
        state.subspecialtyId = subspecialty.subspecialtyId;
        state.specialtyId = subspecialty.parentSpecialtyId;
        state.text = subspecialty.subspecialtyName;
      } else {
        state.specialtyId = specialty.specialtyId;
        state.subspecialtyId = null;
        state.text = specialty.specialtyName;
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(clearSearchForm, () => initialState);
    builder.addCase(startOver, () => initialState);

    // chat
    builder.addCase(chatActions.specialtySearchInPg, (state, action) => {
      // specialty search performed by chat
      const { specialtyId, specialtyName, subspecialtyId, subspecialtyName } = action.payload;

      state.subspecialtyId = subspecialtyId || null;
      state.specialtyId = specialtyId;
      state.text = subspecialtyName || specialtyName;
      state.type = CARE_CATEGORIES.PROVIDER_SPECIALTY;
    });

    builder.addCase(alternateSuggestedSearch.pending, (state, action) => {
      const { specialtyId, subspecialtyId, name, type } = action.meta.arg;
      if (type === PROVIDER_RESULT_TYPE) state.type = CARE_CATEGORIES.PROVIDER_SPECIALTY;
      else if (type === PLACE_RESULT_TYPE) state.type = CARE_CATEGORIES.FACILITY_TYPE;
      else throw new Error(`Invalid type property ${type}`);

      state.specialtyId = specialtyId || null;
      state.subspecialtyId = subspecialtyId || null;
      state.text = name;
      state.isSuggestionSelected = true;
    });

    // url params
    builder.addCase(updateStoreFromUrl, (state, action) => {
      /* eslint-disable camelcase */
      const {
        care_category,
        search_input,
        specialtyId,
        subspecialtyId,
        serviceId,
        serviceType,
        entity_id,
        affiliationName,
        affiliationType,
        isPlaceByExactNameSearch,
      } = action.payload;

      state.text = search_input || '';
      state.specialtyId = specialtyId || null;
      state.subspecialtyId = subspecialtyId || null;
      state.serviceId = serviceId || null;
      state.entityId = entity_id || null;

      if (specialtyId || subspecialtyId || serviceId || isPlaceByExactNameSearch) {
        // if we receive an autocomplete id from the params, we can assume that this was from an autocomplete suggestion
        state.isSuggestionSelected = true;
      }

      if (entity_id) {
        // handle url params for single provider searches - if we receive an entity_id it would be from a single provider search
        state.isSuggestionSelected = true;
      }

      if (affiliationName && Object.values(AFFILIATION_TYPES).includes(affiliationType)) {
        state.affiliationName = affiliationName;
        state.affiliationType = affiliationType;
        state.isSuggestionSelected = true;
      }

      if (care_category === CARE_CATEGORIES.SERVICE) {
        // handle legacy url's using the generic service care category. New url's will have the provider_service or place_service care categories
        // This block can be removed after March 2025. Repo search "delete-service-type"
        switch (serviceType) {
          case PROVIDER_RESULT_TYPE:
            state.type = CARE_CATEGORIES.PROVIDER_SERVICE;
            break;
          case PLACE_RESULT_TYPE:
            state.type = CARE_CATEGORIES.FACILITY_SERVICE;
            break;
          default: // we should never get here unless a URL was modified
            state.type = CARE_CATEGORIES.PROVIDER_NAME;
            break;
        }
      } else {
        state.type = VALID_SEARCH_TYPES.includes(care_category)
          ? care_category
          : CARE_CATEGORIES.PROVIDER_NAME;
      }
    });

    builder.addCase(getTypeSuggestions.fulfilled, (state, action) => {
      const { type, careCategory, data } = action.payload || {};
      if (!type || !data) {
        logDevMessage(`Invalid input { type: ${type}, data: ${data}}`);
        return;
      }

      const suggestionKeys = AUTOCOMPLETE_SUGGESTION_KEYS[type];
      const suggestions = suggestionKeys
        .map((key) => data[key])
        .flat(1)
        .filter(Boolean);

      state.autocompleteFetchingMap[careCategory] = false;
      state.autocompleteSuggestionsMap[careCategory] = suggestions || [];
    });

    builder.addCase(getTypeSuggestions.rejected, (state, action) => {
      const { careCategory } = action.payload || {};
      logDevMessage(`Error getting suggestions for ${careCategory}`);

      state.autocompleteFetchingMap[careCategory] = false;
      state.autocompleteSuggestionsMap[careCategory] = [];
    });
  },
});

export default searchSlice;
export const {
  changeType,
  changeAutocompleteType,
  autocompleteCleared,
  handleTextInput,
  suggestionSelected,
  quickSearchSelected,
  overwriteSlice,
  showMoreProvidersBySpecialty,
  breadcrumbClicked,
  setEntityId,
  setSingleProviderSearch,
  setMultiProviderSearch,
  setPlacesByIdsSearch,
  selectSpecialtyFromAppendix,
  setAutocompleteTypesFetching,
  clearAutocompleteSuggestions,
  featuredActionSearch,
} = searchSlice.actions;
