import React, { useMemo } from 'react';
import PropTypes from 'propTypes/index';
import { makeStyles } from '@material-ui/styles';

import Spinner from './Spinner';
import OuterCircle from './OuterCircle';
import InnerCircle from './InnerCircle';

const useStyles = makeStyles(() => ({
  root: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    position: 'relative',
    transform: 'rotate(90deg)',
    '& svg': {
      position: 'absolute',
    },
  },
}));

function SplashAnimation({ size, duration }) {
  const classes = useStyles({
    size,
  });

  const lines = useMemo(
    () => ({
      line1: [-5, -110, -95, 130],
      line2: [255, 100, 195, 360],
      line3: [140, -20, 45, 220],
    }),
    []
  );

  return (
    <div className={classes.root}>
      <OuterCircle size={size} duration={duration} rad1={(size * 0.9) / 2} rad2={size / 2} />
      <InnerCircle
        size={size}
        duration={duration}
        rad1={(size * 0.35) / 2}
        rad2={(size * 0.25) / 2}
      />
      <Spinner
        duration={duration}
        size={size}
        fullWidth
        thickness={size / 12}
        sequence={lines.line1}
      />
      <Spinner duration={duration} size={size} thickness={size / 12} sequence={lines.line2} />
      <Spinner duration={duration} size={size} thickness={size / 12} sequence={lines.line3} />
    </div>
  );
}

export default React.memo(SplashAnimation);

SplashAnimation.propTypes = {
  size: PropTypes.number.isRequired,
  duration: PropTypes.shape({
    intro: PropTypes.number.isRequired,
    loop: PropTypes.number.isRequired,
  }).isRequired,
};
