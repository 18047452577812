import React from 'react';
import { Typography } from '@material-ui/core';

export default function EmergencyDisclaimer(props) {
  return (
    <Typography {...props}>
      By clicking “Dismiss and continue”, you acknowledge that you have been alerted to a potential
      emergency situation based on your message and are choosing to continue with your conversation.
    </Typography>
  );
}
