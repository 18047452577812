import { CONVIVA, DEFAULT_LOCATIONS, PASSWORD } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import emboldLogo from 'assets/embold-icon.png';
import emboldLoginLogo from 'assets/embold-login-logo.svg';

export const apiTokens = {
  dev: 'Token 7bd0bf5411f2605cfc42be6751c79706b1c8f85f',
  staging: 'Token fb464d1849ea4b0b9dadf84e7b09b9decfb882cc',
  prod: 'Token ',
};

const token = selectToken(apiTokens);

const convivaConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: CONVIVA,

  API_TOKEN: token,
  EMBOLD_CLIENT: CONVIVA,
  EMBOLD_CLIENT_SLUG: CONVIVA,

  // login
  LOGIN_NETWORK_NAME: 'convivaLoggedIn',
  SHOW_REGION_SELECTOR: true,
  PASSWORD_REGEX: ['emboldcv24', 'i'],

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Orlando },

  // content
  DISPLAY_NAME: 'Conviva',
  HEADER_LOGO: emboldLogo,
  FORM_VARIANT: PASSWORD,
  EMBOLD_LOGO_PRESENT: false,
  LOCAL_LOGO_ASSET: emboldLoginLogo,
  NO_WELCOME_LINK: false,
};

export default convivaConfig;
