import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';
import { apiUrl as selectApiUrl } from 'store/slices/config/selectConfig';
import { referringClinic as selectReferringClinic } from 'store/slices/referringClinic/selectReferringClinic';
import analyticsSlice from './analyticsSlice';
import AnalyticsError from './AnalyticsError';

function getInitialRep() {
  // checkout for ?rep=<repname> so we can filter out traffic
  const params = new URLSearchParams(window.location.search);
  const rep = params.get('rep') || localStorage.getItem('rep') || 'NA';

  // sets localStorage with a string of max length of 30
  localStorage.setItem('rep', rep?.substring(0, 30));

  return rep;
}

export function createEventUrl(state, evtObj) {
  const url = new URL('/events/', selectApiUrl(state));
  const referringClinic = selectReferringClinic(state);
  const localToken = localStorage.getItem('integrationToken');
  const integrationToken = localToken || uuidv4();
  if (!localToken) {
    localStorage.setItem('integrationToken', integrationToken);
  }

  url.searchParams.append('rep', getInitialRep());
  url.searchParams.append('timestamp', new Date().getTime());
  url.searchParams.append('integration_token', integrationToken);

  if (referringClinic) {
    url.searchParams.append('referringClinic', referringClinic);
  }

  Object.entries(evtObj).forEach(([key, value]) => url.searchParams.append(key, value));

  return url.toString();
}

export async function sendEvent(state, evtObj) {
  const eventUrl = createEventUrl(state, evtObj);

  let response;
  try {
    response = await fetch(eventUrl, { keepalive: true }); // https://developer.mozilla.org/en-US/docs/Web/API/RequestInit#keepalive
    if (response?.status !== 200) {
      response.data = await response.json();
      throw new AnalyticsError(response, evtObj);
    }
  } catch (err) {
    err.message = `Analytics: ${err.message}`;
    if (AnalyticsError.isAnalyticsError(err)) {
      err.captureInSentry();
    } else {
      // some other error was thrown, ex: TypeError, SyntaxError ...etc
      Sentry.captureException(err);
    }
  }
}

const analyticsMiddleware = (store) => (next) => (action) => {
  if (!action.type?.startsWith(analyticsSlice.name)) {
    next(action);
  }

  const state = store.getState();
  const event = analyticsSlice.reducer(state, action);
  if (event) sendEvent(state, event);
};

export default analyticsMiddleware;
