import React, { useMemo } from 'react';
import { get } from 'lodash';
import PropTypes from 'propTypes';
import { useSelector } from 'react-redux';
import { List } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { select } from 'store/toolkit';
import { getCignaCostLink } from 'store/slices/results/resultUtils';

import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import LanguageIcon from '@material-ui/icons/Language';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import Layers from '@material-ui/icons/Layers';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import CheckOrXIcon from 'icons/dynamic/CheckOrXIcon';
import OutcareColored from 'icons/colored/OutcareColored';
import StarBoxed from 'icons/colored/StarBoxed';
import Female from 'icons/Female';
import Male from 'icons/Male';

import Attribute from '../../Attribute';

export function getProviderAttributeList({
  showAcceptingNewPatients,
  showNearbyLocations,
  showTierDesignation,
  tierDesignation,
  networkData = {},
  providerData = {},
  numberOfLocationsAcceptingNewPatients = 0,
  hasTelehealthAvailable = false,
  showBenefitDecrease = false,
  benefitsChangeData = {},
  isPreferredGroup,
  enableCignaCostLink,
  centersOfExcellence,
  cignaCostLink,
  palette,
  showAboutPage,
}) {
  const list = [];

  // 1. network designation (optional)
  if (networkData && networkData.showNetworkStatus) {
    list.push({
      key: 'attribute-network',
      icon: (
        <LanguageIcon
          color="inherit"
          fontSize="inherit"
          htmlColor={get(palette, networkData.textColor)}
        />
      ),
      text: networkData.networkText,
      TooltipProps: {
        message: networkData.tooltipMessage,
        ariaLabel: `${providerData.entityName} ${networkData.networkText}`,
      },
      TypographyProps: {
        style: {
          fontWeight: 'bold',
          color: get(palette, networkData.textColor),
        },
      },
    });
  }

  // 2. tier designation
  if (tierDesignation && showTierDesignation) {
    list.push({
      key: 'attribute-tier-designation',
      text: tierDesignation.text,
      TooltipProps: {
        message: tierDesignation.tooltipMessage,
        ariaLabel: `${providerData.entityName} ${tierDesignation.text}`,
      },
      icon: <Layers />,
    });
  }

  if (isPreferredGroup) {
    list.push({
      key: 'preferred-group',
      text: 'Preferred Group',
      icon: <StarBoxed />,
      TooltipProps: {
        message:
          'Preferred Groups are practices with the highest percentage of providers in the practice who are rated Exceeds Standards and the lowest percentage of providers who are rated Below Standard. Receiving care from a Preferred Group is easier; even if your provider is not available when you need an appointment, you can see another provider in that group who is likely to also provide the same high quality of patient care.',
        ...(showAboutPage && {
          link: {
            to: '/about#about-preferred-group',
            label: 'Learn More',
          },
        }),
        ariaLabel: `${providerData.entityName} ${'Preferred Group'}`,
      },
    });
  }

  // 3. benefit decrease
  if (showBenefitDecrease) {
    const { cardAttribute } = benefitsChangeData;
    list.push({
      key: 'attribute-benefits',
      text: cardAttribute.text,
      TooltipProps: {
        message: cardAttribute.tooltip,
        ariaLabel: `${providerData.entityName} ${cardAttribute.text}`,
      },
      iconAltText: `${cardAttribute.text} icon`,
      icon: <ErrorIcon htmlColor={palette.warning.main} />,
    });
  }

  // 4. gender
  if (providerData.gender !== undefined) {
    list.push({
      key: 'attribute-gender',
      text: providerData.gender,
      icon:
        providerData.gender === 'Female' ? (
          <Female htmlColor={palette.text.primary} />
        ) : (
          <Male htmlColor={palette.text.primary} />
        ),
      iconAltText: `${providerData.gender} Provider icon`,
    });
  }

  // 5. Provider Extension
  if (centersOfExcellence) {
    list.push({
      key: `attribute-${centersOfExcellence.key}`,
      icon: (
        <centersOfExcellence.Icon
          width="100%"
          color="secondary"
          titleAccess={`${providerData.gender} ${centersOfExcellence.title} icon`}
        />
      ),
      text: centersOfExcellence.title,
      TooltipProps: {
        message: centersOfExcellence.description,
        ariaLabel: `${providerData.entityName} ${centersOfExcellence.title}`,
      },
    });
  }

  // 6. Languages Spoken
  if (providerData.languagesArray?.length > 0) {
    list.push({
      key: 'attribute-language',
      text: providerData.languagesArray.join(', '),
      icon: <RecordVoiceOverOutlinedIcon htmlColor={palette.text.primary} />,
      iconAltText: 'Languages Spoken Icon',
    });
  }

  // 7. LGBTQ+ (optional)
  if (providerData.outcareCompetent) {
    list.push({
      key: 'attribute-outcare',
      text: 'LGBTQ+ Competent',
      icon: <OutcareColored />,
      iconAltText: 'LGBTQ+ Competent Icon',
    });
  }

  // 8. telehealth
  if (hasTelehealthAvailable) {
    list.push({
      key: 'attribute-telehealth',
      text: 'Telehealth',
      icon: <CheckOrXIcon checked />,
    });
  }

  // 9. accepting new
  if (showAcceptingNewPatients) {
    // some data sources have providers with undefined `acceptingNewPatients`. numberOfLocationsAcceptingNewPatients returns -1 in that case
    if (numberOfLocationsAcceptingNewPatients > -1) {
      list.push(
        numberOfLocationsAcceptingNewPatients > 0
          ? {
              key: 'attribute-accepting-patients',
              text: 'Accepting patients',
              icon: <CheckOrXIcon checked />,
            }
          : {
              key: 'attribute-accepting-patients',
              text: 'Not accepting patients',
              icon: <CheckOrXIcon checked={false} />,
            }
      );
    }
  }

  // 10. nearby locations
  if (showNearbyLocations) {
    if (providerData.places?.length > 1) {
      list.push({
        key: 'attribute-locations',
        text: `${providerData.places.length} nearby locations`,
        icon: <RoomOutlinedIcon htmlColor={palette.text.primary} />,
      });
    }
  }

  // 11. Cigna Cost link
  if (enableCignaCostLink) {
    list.push({
      key: 'cigna-cost',
      text: 'See Cost Estimate',
      icon: <OpenInBrowserIcon color="secondary" />,
      LinkProps: { href: cignaCostLink },
    });
  }

  return list;
}

function ProviderAttributeList({
  showAcceptingNewPatients,
  showNearbyLocations,
  showTierDesignation,
  providerId,
  ...props
}) {
  const { palette } = useTheme();
  const providerData = useSelector(select.provider(providerId).data);
  const showBenefitDecrease = useSelector(select.provider(providerId).showBenefitDecrease);
  const isPreferredGroup = useSelector(select.provider(providerId).isPreferredGroup);
  const benefitsChange = useSelector(select.content.benefitsChange);
  const numberOfLocationsAcceptingNewPatients = useSelector(
    select.provider(providerId).numberOfLocationsAcceptingNewPatients
  );
  const centersOfExcellence = useSelector(select.provider(providerId).centersOfExcellence);
  const hasTelehealthAvailable = useSelector(select.provider(providerId).hasTelehealthAvailable);
  const tierDesignation = useSelector(select.provider(providerId).tierDesignationRollup);
  const network = useSelector(select.provider(providerId).networkContent);
  const enableCignaCostLink = useSelector(select.featureFlags.enableCignaCostLink);
  const cignaCostLink = useMemo(() => getCignaCostLink(providerData), [providerData]);
  const showAboutPage = useSelector(select.featureFlags.showAboutPage);

  const attributeList = useMemo(
    () =>
      getProviderAttributeList({
        showAcceptingNewPatients,
        showNearbyLocations,
        showTierDesignation,
        networkData: network,
        providerData,
        numberOfLocationsAcceptingNewPatients,
        hasTelehealthAvailable,
        tierDesignation,
        showBenefitDecrease,
        benefitsChangeData: benefitsChange,
        isPreferredGroup,
        enableCignaCostLink,
        centersOfExcellence,
        cignaCostLink,
        palette,
        showAboutPage,
      }),
    [
      network,
      providerData,
      numberOfLocationsAcceptingNewPatients,
      hasTelehealthAvailable,
      tierDesignation,
      showBenefitDecrease,
      benefitsChange,
      showAcceptingNewPatients,
      showNearbyLocations,
      showTierDesignation,
      isPreferredGroup,
      enableCignaCostLink,
      centersOfExcellence,
      cignaCostLink,
      palette,
      showAboutPage,
    ]
  );

  if (!attributeList.length) return null;

  return (
    <List disablePadding {...props}>
      {attributeList.map(({ key, ...attribute }) => (
        <Attribute key={`${key}-${providerId}`} {...attribute} />
      ))}
    </List>
  );
}

ProviderAttributeList.propTypes = {
  providerId: PropTypes.string.isRequired,
  showAcceptingNewPatients: PropTypes.bool,
  showNearbyLocations: PropTypes.bool,
  showTierDesignation: PropTypes.bool,
};

ProviderAttributeList.defaultProps = {
  showAcceptingNewPatients: true,
  showNearbyLocations: true,
  showTierDesignation: true,
};

export default React.memo(ProviderAttributeList);
