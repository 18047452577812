import { DEFAULT_LOCATIONS, DOLLAR_GENERAL, DROPDOWN } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import DollarGeneralLoginLogo from 'assets/bcbst-login-logo.svg';

export const apiTokens = {
  dev: 'Token 8efcfae735d260e15f87805b150604ccba55b7d3',
  staging: 'Token f235f9d162b9e9f7008c8ae6180235864d3e52a1',
  prod: 'Token 6baf677e3de9c9681e2f592834f7f0b8346d3a15',
};

const token = selectToken(apiTokens);

const dollarGeneralConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'bcbst',

  API_TOKEN: token,
  EMBOLD_CLIENT: DOLLAR_GENERAL,
  EMBOLD_CLIENT_SLUG: 'dgbcbst',
  SHOW_REGION_SELECTOR: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Goodlettsville },
  LOGIN_NETWORK_NAME: 'dollarGeneralUserId',

  // content
  DISPLAY_NAME: 'Dollar General',
  HEADER_LOGO: DollarGeneralLoginLogo,
  EMBOLD_LOGO_PRESENT: true,
  FORM_VARIANT: DROPDOWN,
  LOCAL_LOGO_ASSET: DollarGeneralLoginLogo,
  NO_WELCOME_LINK: false,
};

export default dollarGeneralConfig;
