import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';
import { delayByAttempt } from 'utils/fetchRetryDelay';
import { mapCamel } from './utils';

export function getStatusCodeRule(code) {
  const statusCodeRule = {
    // default options
    shouldRetry: true,
    disableSentry: false,
    shouldRedirect: false,
  };

  switch (code) {
    case 500:
    case 400:
    case 403:
    case 404:
      statusCodeRule.shouldRetry = false;
      break;
    case 502:
    case 503:
      statusCodeRule.shouldRetry = true;
      break;
    case 409:
    case 410:
      statusCodeRule.shouldRetry = false;
      statusCodeRule.disableSentry = true;
      break;
    case 401:
      statusCodeRule.shouldRetry = false;
      statusCodeRule.shouldRedirect = true;
      break;
    default:
      break;
  }

  return statusCodeRule;
}

export const MAX_ATTEMPTS = 3;

export const getAxiosRequestConfig = (axiosConfig, clientConfig) => {
  const localToken = localStorage.getItem('integrationToken');
  const integrationToken = clientConfig?.CLOSED_ACCESS ? localToken : localToken || uuidv4();
  if (!localToken && !clientConfig?.CLOSED_ACCESS && integrationToken) {
    // if there is no local token and it's not closed access, we should set it locally
    localStorage.setItem('integrationToken', integrationToken);
  }

  axiosConfig.headers.Authorization = clientConfig.API_TOKEN;

  if (integrationToken) axiosConfig.headers['X-Integration-Token'] = integrationToken;

  return axiosConfig;
};

export const handleAxiosResponse = (response) => {
  if (response.data) {
    response.data = mapCamel(response.data);
  }
  return response;
};

export const handleAxiosError = async (error, instance) => {
  const { config, response, request } = error;

  const statusCodeRule = getStatusCodeRule(response?.status);
  const attemptsMade = config.attempts + 1;

  // Retry
  if (statusCodeRule.shouldRetry && attemptsMade < MAX_ATTEMPTS) {
    await delayByAttempt(attemptsMade);
    Sentry.addBreadcrumb(`Axios automatic retry`);
    return instance.request({ ...config, attempts: attemptsMade });
  }

  // Redirect
  if (statusCodeRule.shouldRedirect) {
    const redirectUrl = response?.data?.redirectUrl || response?.data?.redirect_url;
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      Sentry.captureMessage('Received no redirectUrl', (scope) => {
        scope.setLevel('fatal');
        scope.setExtra('url', config.url);
        scope.setExtra('response', response?.data);
      });
    }
    return Promise.reject(error);
  }

  // Report
  if (!statusCodeRule.disableSentry) {
    Sentry.withScope((scope) => {
      const url = new URL(request?.responseURL || `${error?.config?.baseURL}${error?.config?.url}`);

      const code = String(error?.response?.status || error?.response?.code || 'No response');
      const method = error?.config?.method;
      const path = url?.pathname?.split('/')[3] || url?.pathname; // avoid including a path param in the finger print such as /share/:id

      // group errors together based on their request and response
      const fingerprint = [method, path, code];

      if (response?.data?.code) {
        // prefer the "code" property if available for grouping
        fingerprint.push(`code:${response.data.code}`);
      } else if (response?.data?.detail) {
        fingerprint.push(`detail:${response.data.detail}`);
      }
      scope.setFingerprint(fingerprint);
      // add the response data to the event for easier debugging
      scope.setExtra('response', error.response?.data || 'No response data');
      scope.setExtra('url', url.toString());
      scope.setExtra('method', method);
      scope.setExtra('status', code);
      // provide a better faceup error message in sentry
      error.sentryMessage = `${code} on ${method?.toUpperCase()} - /${path}`;
      Sentry.captureException(error);
    });
  }

  return Promise.reject(error);
};

export default function getAxiosInstance(clientConfig) {
  const defaultOptions = {
    baseURL: clientConfig?.API_URL,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    attempts: 0,
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use((config) => getAxiosRequestConfig(config, clientConfig));

  instance.interceptors.response.use(
    /* on fulfilled */
    (response) => handleAxiosResponse(response),

    /* on rejected */
    async (error) => handleAxiosError(error, instance)
  );

  return instance;
}
