import { DEFAULT_LOCATIONS, AXA, DROPDOWN } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import axaLogo from 'assets/axa_logo.png';

export const apiTokens = {
  dev: 'Token 39a44ac8bd073ce5e68deeb910713545ec245fe4',
  staging: 'Token 007a4e4c9217c5d2bf5487c9464986ecdb106b15',
  prod: 'Token a33060081d5ae186c3e484ed2df25386a4d99279',
};

const token = selectToken(apiTokens);

const axaConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'axa-partners',

  API_TOKEN: token,
  EMBOLD_CLIENT: AXA,
  EMBOLD_CLIENT_SLUG: AXA,

  // login
  SHOW_REGION_SELECTOR: true,

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Orlando },
  LOGIN_NETWORK_NAME: 'axaUserId',

  // content
  DISPLAY_NAME: 'Axa',
  HEADER_LOGO: axaLogo,
  EMBOLD_LOGO_PRESENT: true,
  LOCAL_LOGO_ASSET: axaLogo,
  FORM_VARIANT: DROPDOWN,
  NO_WELCOME_LINK: true,
};

export default axaConfig;
