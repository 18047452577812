import { DEFAULT_LOCATIONS, QUANTUM, DROPDOWN_AUTOCOMPLETE } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import quantumLogo from 'assets/quantum-health-care-finder.svg';

export const apiTokens = {
  dev: 'Token 101f6deecb3ab1b0bcda7a15295dd3be741e6d4f',
  staging: 'Token 83f155c4100c446c5030c6d13f0935c05b5c38fe',
  prod: 'Token ccc508e7e9bd2e1c0ae764ab7c7d8744c705c637',
};

const token = selectToken(apiTokens);

const quantumConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'quantum',

  API_TOKEN: token,
  EMBOLD_CLIENT: QUANTUM,
  EMBOLD_CLIENT_SLUG: QUANTUM,

  // login
  SHOW_REGION_SELECTOR: true,

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Nashville },
  LOGIN_NETWORK_NAME: 'quantumUserId',

  // content
  DISPLAY_NAME: 'Quantum Health',
  HEADER_LOGO: quantumLogo,
  EMBOLD_LOGO_PRESENT: false,
  LOCAL_LOGO_ASSET: quantumLogo,
  FORM_VARIANT: DROPDOWN_AUTOCOMPLETE,
  NO_WELCOME_LINK: true,
};

export default quantumConfig;
