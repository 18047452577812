import { CARE_CATEGORIES, PLACE_RESULT_TYPE, PROVIDER_RESULT_TYPE } from 'utils/constants';

const AUTOCOMPLETE_TYPE_PARAM_SPECIALTY = 'specialty';
const AUTOCOMPLETE_TYPE_PARAM_PROVIDER = 'provider';
const AUTOCOMPLETE_TYPE_PARAM_PROVIDER_SERVICE = 'provider_service';
const AUTOCOMPLETE_TYPE_PARAM_AFFILIATION = 'affiliation';
const AUTOCOMPLETE_TYPE_PARAM_PLACE_SPECIALTY = 'place_specialty';
const AUTOCOMPLETE_TYPE_PARAM_PLACE = 'place';
const AUTOCOMPLETE_TYPE_PARAM_PLACE_SERVICE = 'place_service';

export const AUTOCOMPLETE_DEBOUNCE_DELAY = 300;
export const MIN_SEARCH_STRING = 2;

/**
 * Object shape: { [type]: [...suggestionKeys] }
 * Autocomplete requests with the given "type" will return suggestion arrays nested under object keys "suggestionKeys"
 * For example:
 * - Request: https://.../api/pg/autocomplete?type=place_specialty&foo=bar...
 * - Response: { data: { subspecialties: [...], specialties: [...], searchTerms: [...] }}
 */
export const AUTOCOMPLETE_SUGGESTION_KEYS = {
  [AUTOCOMPLETE_TYPE_PARAM_SPECIALTY]: ['specialties', 'subspecialties', 'searchTerms'],
  [AUTOCOMPLETE_TYPE_PARAM_PROVIDER]: ['providers'],
  [AUTOCOMPLETE_TYPE_PARAM_PROVIDER_SERVICE]: ['services'],
  [AUTOCOMPLETE_TYPE_PARAM_AFFILIATION]: ['affiliations'],
  [AUTOCOMPLETE_TYPE_PARAM_PLACE_SPECIALTY]: ['specialties', 'subspecialties', 'searchTerms'],
  [AUTOCOMPLETE_TYPE_PARAM_PLACE]: ['places'],
  [AUTOCOMPLETE_TYPE_PARAM_PLACE_SERVICE]: ['services'],
};
/**
 * Map care categories to autocomplete "type" parameters
 */
export const CARE_CATEGORY_AUTOCOMPLETE_TYPE_PARAM_MAP = {
  [CARE_CATEGORIES.PROVIDER_SPECIALTY]: AUTOCOMPLETE_TYPE_PARAM_SPECIALTY,
  [CARE_CATEGORIES.PROVIDER_NAME]: AUTOCOMPLETE_TYPE_PARAM_PROVIDER,
  [CARE_CATEGORIES.PROVIDER_SERVICE]: AUTOCOMPLETE_TYPE_PARAM_PROVIDER_SERVICE,
  [CARE_CATEGORIES.PROVIDER_AFFILIATION]: AUTOCOMPLETE_TYPE_PARAM_AFFILIATION,
  [CARE_CATEGORIES.FACILITY_NAME]: AUTOCOMPLETE_TYPE_PARAM_PLACE,
  [CARE_CATEGORIES.FACILITY_TYPE]: AUTOCOMPLETE_TYPE_PARAM_PLACE_SPECIALTY,
  [CARE_CATEGORIES.FACILITY_SERVICE]: AUTOCOMPLETE_TYPE_PARAM_PLACE_SERVICE,
};

/**
 * Allow easy mapping over autocomplete types when performing a series of /autocomplete requests
 */
export const AUTOCOMPLETE_TYPE_PARAMS = {
  [PROVIDER_RESULT_TYPE]: [
    AUTOCOMPLETE_TYPE_PARAM_SPECIALTY,
    AUTOCOMPLETE_TYPE_PARAM_PROVIDER,
    AUTOCOMPLETE_TYPE_PARAM_PROVIDER_SERVICE,
    AUTOCOMPLETE_TYPE_PARAM_AFFILIATION,
  ],
  [PLACE_RESULT_TYPE]: [
    AUTOCOMPLETE_TYPE_PARAM_PLACE_SPECIALTY,
    AUTOCOMPLETE_TYPE_PARAM_PLACE,
    AUTOCOMPLETE_TYPE_PARAM_PLACE_SERVICE,
  ],
};

/**
 * Create an object with empty values mapped to each careCategory
 * For example:
 * {
 *  provider: [],
 *  place: [],
 *  specialty: [],
 *  place_specialty: [],
 *  service: [],
 *  // any additional care categories
 * }
 */
export const getAutocompleteInitial = (defaultValue) => {
  const initial = {};
  Object.values(CARE_CATEGORIES).forEach((key) => {
    initial[key] = defaultValue;
  });
  return initial;
};

export const AUTOCOMPLETE_INITIAL = getAutocompleteInitial([]);
export const AUTOCOMPLETE_FETCHING_INITIAL = getAutocompleteInitial(false);

export const FREEFORM_SEARCH_TYPE_MAP = {
  [PROVIDER_RESULT_TYPE]: CARE_CATEGORIES.PROVIDER_NAME,
  [PLACE_RESULT_TYPE]: CARE_CATEGORIES.FACILITY_NAME,
};

export const VALID_SEARCH_TYPES = Object.values(CARE_CATEGORIES);
