import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import PillList from 'components/ModernExperience/ResultCards/ProviderCard/PillList';
import ProviderDetailsButton from 'components/ModernExperience/ResultCards/ProviderCard/ProviderDetailsButton';
import ProviderNameAndSpecialty from 'components/ModernExperience/ResultCards/ProviderCard/ProviderNameAndSpecialty';
import ProviderAttributeList from 'components/ModernExperience/ResultCards/ProviderCard/ProviderAttributeList';
import ProviderMetricList from 'components/ModernExperience/ResultCards/ProviderCard/ProviderMetricList';
import ProviderContactCard from 'components/ModernExperience/ResultCards/ProviderCard/ProviderContactCard';
import ProviderProfileHeaderButtons from 'components/ModernExperience/Profile/ProviderProfile/ProviderProfileHeaderButtons';
import { getProfileUrl } from 'store/slices/results/resultUtils';
import { _analyticsActions } from 'analytics/index';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: ({ disablePadding }) => (disablePadding ? 0 : theme.spacing(2)),
    rowGap: theme.spacing(1),
  },
  pillsAndDetailsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '& > :last-child': { marginLeft: theme.spacing(1) },
  },
  pillContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
  },
  buttons: {
    marginTop: theme.spacing(1),
  },
  hidePrint: {
    '@media print': { display: 'none' },
  },
  onlyPrint: {
    display: 'none',
    '@media print': {
      display: 'block',
    },
  },
}));

export default function ChatProviderCard({ providerId, requestKey, disablePadding, ...props }) {
  const classes = useStyles({ disablePadding });
  const dispatch = useDispatch();
  const search = useSelector(select.chat.requestByKey(requestKey));
  const networkSlug = useSelector(select.networks.currentSlug);
  const providerData = useSelector(select.chat.resultById(providerId));

  const ref = useRef();

  const locationInput = `${search.city}, ${search.stateAbbreviation}`;

  const searchLocation = useMemo(
    () => ({
      locationInput,
      coordinates: search.location,
    }),
    [search, locationInput]
  );

  const profileUrl = useMemo(
    () =>
      getProfileUrl('provider', providerId, {
        networkSlug,
        locationInput,
        city: search.city,
        state: search.stateAbbreviation,
        ...search.location, // coordinates
      }),
    [locationInput, search, networkSlug, providerId]
  );

  const metricOptions = useMemo(
    () => ({
      focusAreas: {
        highlightSubspecialties: search.subspecialties.map((ssObj) => ssObj?.subspecialtyId),
        profileUrl,
      },
      primaryAffiliation: { profileUrl },
      serviceCost: {
        profileUrl,
        locations: providerData.places,
        comparisonLocation: searchLocation,
        service: null, // ignore any service that is set as "current" in the service slice
      },
    }),
    [search, profileUrl, searchLocation, providerData]
  );

  const sendAnalytics = useMemo(
    () => ({
      directions: () => dispatch(_analyticsActions.directionsClickFromChatProfile({ providerId })),
      phone: () => dispatch(_analyticsActions.phoneClickFromChatProfile({ providerId })),
    }),
    [dispatch, providerId]
  );

  return (
    <Grid
      container
      component={Paper}
      elevation={0}
      classes={{ root: classes.root }}
      ref={ref}
      {...props}
    >
      <Grid item xs={12} className={classes.pillsAndDetailsContainer}>
        <div className={classes.pillContainer}>
          <PillList providerId={providerId} />
        </div>
        <ProviderDetailsButton providerId={providerId} profileUrl={profileUrl} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ProviderNameAndSpecialty providerId={providerId} profileUrl={profileUrl} />
        <ProviderAttributeList providerId={providerId} />

        <ProviderProfileHeaderButtons
          providerId={providerId}
          buttonSize="small"
          justify="flex-start"
          printRef={ref}
          className={classes.buttons}
          locationInput={locationInput}
          coordinates={search.location}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ProviderMetricList providerId={providerId} options={metricOptions} />
      </Grid>
      <Grid item xs={12}>
        <ProviderContactCard
          className={classes.hidePrint}
          providerId={providerId}
          showMap
          comparisonLocation={searchLocation}
          locations={providerData.places}
          onAddressClick={sendAnalytics.directions}
          onPhoneClick={sendAnalytics.phone}
        />
        <ProviderContactCard
          className={classes.onlyPrint}
          isPrintMode
          providerId={providerId}
          showMap
          comparisonLocation={searchLocation}
          locations={providerData.places}
        />
      </Grid>
    </Grid>
  );
}

ChatProviderCard.propTypes = {
  providerId: PropTypes.string.isRequired,
  requestKey: PropTypes.string.isRequired,
  disablePadding: PropTypes.bool,
};

ChatProviderCard.defaultProps = {
  disablePadding: false,
};
