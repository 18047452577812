import React from 'react';
import PropTypes from 'prop-types';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { buildLocationUrl } from 'utils/utils';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

function useStyles(props) {
  const generateClasses = makeStyles((theme) => ({
    root: {
      fontSize: props.size || theme.typography.caption.fontSize,
      display: 'block',
    },
  }));

  return generateClasses();
}

export default function AddressLink({ address, className, multiline, size, ...props }) {
  const classes = useStyles({ size });
  const mapsUrl = useSelector(select.config.mapsUrl);

  const href = buildLocationUrl(address, mapsUrl);
  const { address1, address2, city, state, zipcode } = address;

  // generate the first part of the string, combining address1 & address2 based on extraLineBreaks prop
  let addressDisplayString = address1;

  // 111 Main St, Ste 123
  if (address2) addressDisplayString += `, ${address2}`;
  addressDisplayString += `, ${city}, ${state} ${zipcode}`;

  const MultiLineComponent = (
    <>
      <Typography>{address1}</Typography>
      {Boolean(address2) && <Typography>{address2}</Typography>}
      <Typography>
        {city}, {state} {zipcode}
      </Typography>
    </>
  );

  return (
    <Link
      className={`${classes.root} ${className}`}
      href={href}
      rel="noreferrer"
      target="_blank"
      {...props}
    >
      {multiline ? MultiLineComponent : addressDisplayString}
    </Link>
  );
}

AddressLink.defaultProps = {
  className: '',
  size: undefined,
  multiline: false,
};

AddressLink.propTypes = {
  className: PropTypes.string,
  address: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
  }).isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiline: PropTypes.bool,
};
