/**
 * Extracts specified SSO parameters from a URL, stores them in localStorage,
 * and returns the modified URL along with the extracted parameter values.
 *
 * @param {string} inputUrl - The input URL containing potential SSO query parameters.
 * @returns {[string, Object]} A tuple where:
 *   - The first element is the modified URL as a string, with SSO parameters removed.
 *   - The second element is an object containing the extracted parameter values
 *     (or `null` if not present), with the following keys:
 *       - `integrationToken` (string|null): The SSO token parameter.
 *       - `mpiNetwork` (string|null): The network parameter.
 *       - `networkGroup` (string|null): The network group parameter.
 *       - `zipcode` (string|null): The zipcode parameter.
 *
 * @example
 * const [modifiedUrl, params] = extractSsoParams('http://example.com?token=abc123&network=mynetwork');
 * // modifiedUrl: "http://example.com/"
 * // params: { integrationToken: "abc123", mpiNetwork: "mynetwork", zipcode: null, networkGroup: null }
 *
 */
export default function extractSsoParams(inputUrl) {
  const url = new URL(inputUrl);
  const params = url.searchParams;
  const token = params.get('token');
  const network = params.get('network');
  const networkGroup = params.get('network_group');
  const zipcode = params.get('zipcode');
  const requireReferringClinic = params.get('show_referring_clinics') || false;

  if (params.has('token')) {
    localStorage.setItem('integrationToken', token);
    params.delete('token');
  }

  if (params.has('network')) {
    localStorage.setItem('mpiNetwork', network);
    params.delete('network');
  }

  if (params.has('zipcode')) {
    localStorage.setItem('zipcode', zipcode);
    params.delete('zipcode');
  }

  if (params.has('network_group')) {
    localStorage.setItem('networkGroup', networkGroup);
    params.delete('network_group');
  }

  if (params.has('show_referring_clinics')) {
    if (requireReferringClinic) {
      localStorage.setItem('requireReferringClinic', true);
    }
    params.delete('show_referring_clinics');
  }
  return [
    url.toString(),
    { token, network, zipcode, network_group: networkGroup, requireReferringClinic },
  ];
}
