import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import { _analyticsActions } from 'analytics/index';
import ChatNoResults from 'components/Modals/ChatModal/ChatNoResults';
import ProfileSkeleton from 'components/ModernExperience/LoadingSkeletons/ProfileSkeleton';
import ChatExpandedSearchAlert from 'components/Modals/ChatModal/ChatExpandedSearchAlert';
import SpecialtyButtons from 'components/Modals/ChatModal/SpecialtyButtons';
import ResultProfileTabs from 'components/ModernExperience/Profile/ResultProfileTabs';
import CollapsingProfileList from 'components/ModernExperience/Profile/CollapsingProfileList';
import PromotionBySpecialty from './Promotion/PromotionBySpecialty';
import ChatProviderCard from './ChatProviderCard';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': { width: '100%' },
  },
}));

function ChatSearchPanelContent({ search = {}, label }) {
  const dispatch = useDispatch();
  const isSmDown = useSelector(select.ui.isXsDown);
  const locationName =
    search.city && search.stateAbbreviation
      ? `${search.city}, ${search.stateAbbreviation}`
      : 'your location';

  const ariaLabel = `${label} providers`;

  const sendViewAnalytics = useCallback(
    (providerId) => {
      dispatch(_analyticsActions.chatProfileView({ providerId }));
    },
    [dispatch]
  );

  const renderCard = useCallback(
    (id) => (
      <ChatProviderCard disablePadding={isSmDown} providerId={id} requestKey={search.requestKey} />
    ),
    [search, isSmDown]
  );

  useEffect(() => {
    if (!isSmDown) {
      // send profile view analytics on first render of tab view to account for the initially selected tab
      sendViewAnalytics(search.resultList[0]);
    }
  }, [isSmDown, search, sendViewAnalytics]);

  if (search.isLoading) return <ProfileSkeleton />;
  if (search.error) return <ChatNoResults locationName={locationName} searchName={label} error />;
  if (!search.results.length)
    return (
      <>
        <ChatNoResults locationName={locationName} searchName={label} />
        <SpecialtyButtons search={search} />
      </>
    );

  return (
    <>
      <PromotionBySpecialty
        specialtyId={search.specialtyId}
        subspecialtyId={search.subspecialtyId}
      />
      {search.isExpanded && (
        <ChatExpandedSearchAlert searchName={label} newRadius={search.radius} />
      )}

      {isSmDown ? (
        <Paper elevation={0}>
          <CollapsingProfileList
            resultIds={search.resultList}
            aria-label={ariaLabel}
            renderProfile={renderCard}
            onOpen={sendViewAnalytics}
          />
        </Paper>
      ) : (
        <ResultProfileTabs
          ids={search.resultList}
          aria-label={ariaLabel}
          fullWidth
          centered
          vertical={isSmDown}
          renderPanel={renderCard}
          onChange={sendViewAnalytics}
        />
      )}

      {!process.env.REACT_APP_STANDALONE_CHAT && <SpecialtyButtons search={search} />}
    </>
  );
}

export default function ChatSearchTabPanel({ search = {}, label, hidden, ...props }) {
  const classes = useStyles();
  if (hidden) return null;

  return (
    <Box role="tabpanel" className={classes.root} {...props}>
      <ChatSearchPanelContent search={search} label={label} />
    </Box>
  );
}
ChatSearchPanelContent.propTypes = {
  search: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  label: PropTypes.string.isRequired,
};

ChatSearchTabPanel.propTypes = {
  id: PropTypes.string.isRequired,
  'aria-labelledby': PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
  ...ChatSearchPanelContent.propTypes,
};
