import { useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  DISMISS_EMERGENCY,
  RESTART,
  RETRY,
  SEARCH_IN_PG,
  START_OVER,
  TRY_AGAIN,
} from 'store/slices/chat/chatConstants';

import { actions, thunks, select } from 'store/toolkit';

export default function useChat() {
  const dispatch = useDispatch();
  const pendingRequest = useRef(null);
  const inputRef = useRef(null);
  const endOfListRef = useRef(null);
  const store = useStore();

  const appName = useSelector(select.content.appName);
  const actionButtonKeys = useSelector(select.chat.actionButtonKeys);

  const scrollToBottom = useCallback(() => {
    if (endOfListRef?.current) endOfListRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [endOfListRef]);

  const submit = useCallback(
    async ({ userMessage }, formik = null) => {
      if (formik) {
        // this function is sometimes called by the Retry action button
        // when it is, it would not receive the formikObject as a second argument
        formik.resetForm();
      }

      pendingRequest.current = dispatch(thunks.chat.askEva({ userMessage }));
      scrollToBottom(); // scrolls new user message into view
      const { payload: response = {} } = await pendingRequest.current;

      if (!response.end || response.isEmergency) {
        pendingRequest.current = null;
        scrollToBottom(); // scrolls eva response into view
        return; // chat is not over or emergency is detected -> continue conversation
      }

      pendingRequest.current = dispatch(thunks.chat.analyzeChat());
      const { payload: analysis = {} } = await pendingRequest.current;

      if (!analysis.end || analysis.isEmergency) {
        pendingRequest.current = null;
        scrollToBottom(); // scrolls analysis response into view
        return; // chat is not over or emergency is detected -> continue conversation
      }

      pendingRequest.current = dispatch(thunks.chat.performAllSearches());
      await pendingRequest.current;

      pendingRequest.current = null;
    },
    [dispatch, pendingRequest, scrollToBottom]
  );

  const reset = useCallback(() => {
    if (typeof pendingRequest.current?.abort === 'function') {
      // cancel any pending requests
      pendingRequest.current.abort('Chat reset by user');
    }
    dispatch(actions.chat.resetChat());
  }, [dispatch, pendingRequest]);

  const actionButtonsMap = useMemo(
    () => ({
      [RESTART]: {
        label: 'Restart Chat',
        callback: reset,
      },
      [DISMISS_EMERGENCY]: {
        label: 'Dismiss and continue',
        callback: () => dispatch(thunks.chat.askEva({ dismissEmergency: true })),
      },
      [START_OVER]: {
        label: 'Start over',
        callback: reset,
      },
      [SEARCH_IN_PG]: {
        label: `Search In ${appName}`,
        callback: () => {
          reset();
          dispatch(actions.ui.closeModal('chat'));
        },
      },
      [TRY_AGAIN]: {
        // removes the last message and allows user to rephrase their prompt
        label: 'Try again',
        callback: () => {
          dispatch(actions.chat.tryAgain());
          if (inputRef.current) inputRef.current?.focus();
        },
      },
      [RETRY]: {
        // attempts to resend the message
        label: 'Retry',
        callback: () => {
          dispatch(actions.chat.resetErrorState());
          submit({}); // empty form values will avoid adding to the message chain
        },
      },
    }),
    [submit, reset, dispatch, appName]
  );

  const actionButtons = useMemo(
    () => actionButtonKeys.map((key) => actionButtonsMap[key]),
    [actionButtonKeys, actionButtonsMap]
  );

  const getConversationString = useCallback(
    // The reason for using store.getState vs useSelector() is because the conversation string will change with every new message
    // Because of that, this hook would change and force it's parent to rerender.
    // By using store.getState we have a stable reference to the store that doesn't change, and we can get the conversation string
    // only when it's needed - at the time this function is invoked.
    () => select.chat.conversationAsString(store.getState()),
    // TODO - in the current chat we include provider details as string.
    [store]
  );

  return {
    submit,
    reset,
    actionButtons,
    inputRef,
    endOfListRef,
    scrollToBottom,
    getConversationString,
  };
}
