import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { IconButton, InputAdornment, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as Yup from 'yup';

import SendIcon from '@material-ui/icons/Send';
import ReplayIcon from '@mui/icons-material/Replay';

import PropTypes, { RefType } from 'propTypes/index';
import TextInput from 'components/Forms/TextInput';
import { actions, select } from 'store/toolkit';

const useStyles = makeStyles((theme) => ({
  form: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    padding: `${theme.spacing(1)}px 0px`,
  },
  inputOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    zIndex: 1,
  },
}));

const initialValues = {
  userMessage: '',
};

const validationSchema = Yup.object({
  userMessage: Yup.string().required('Message is required'),
});

export default function ChatInputForm({ onReset, onSubmit, className, inputRef, ...props }) {
  const classes = useStyles();

  const searchesHaveBeenPerformed = useSelector(select.chat.searchesHaveBeenPerformed);
  const isSending = useSelector(select.chat.sendingChatMessage);
  const isChatEnded = useSelector(select.chat.endChat);
  const termsAccepted = useSelector(select.chat.termsAccepted);

  const dispatch = useDispatch();

  const handleOverlayClick = useCallback(
    () => dispatch(actions.chat.termsDisabledInputClick()),
    [dispatch]
  );

  const buttonProps = {
    color: 'primary',
  };

  const starOverButton = (
    <IconButton {...buttonProps} aria-label="Start new chat" onClick={onReset}>
      <ReplayIcon size="small" />
    </IconButton>
  );

  const sendButton = (
    <IconButton {...buttonProps} aria-label="Send message" disabled={isSending} type="submit">
      <SendIcon size="small" />
    </IconButton>
  );

  const endAdornment = (
    <InputAdornment position="end">
      {searchesHaveBeenPerformed ? starOverButton : sendButton}
    </InputAdornment>
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form className={clsx(classes.form, { [className]: className })} {...props}>
        {!termsAccepted && <Box className={classes.inputOverlay} onClick={handleOverlayClick} />}
        <TextInput
          id="chat-message"
          name="userMessage"
          label="" // no visible label, handled by aria-label
          fullWidth
          size="small"
          placeholder={searchesHaveBeenPerformed ? 'Start new chat' : 'Enter your message...'}
          disabled={isChatEnded || !termsAccepted}
          inputRef={inputRef}
          InputProps={{ endAdornment }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            'aria-label': 'Enter your message',
            maxLength: 150,
            autoComplete: 'off',
          }}
        />
      </Form>
    </Formik>
  );
}

ChatInputForm.propTypes = {
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  inputRef: RefType,
  className: PropTypes.string,
};

ChatInputForm.defaultProps = {
  className: undefined,
  inputRef: undefined,
};
