// config slice
import * as aiSearch from './slices/aiSearch/selectAiSearch';
import * as axios from './slices/config/selectAxios';
import * as auth from './slices/auth/selectAuth';
import * as chat from './slices/chat/selectChat';
import * as client from './slices/config/selectClient';
import * as config from './slices/config/selectConfig';
import * as content from './slices/config/selectContent';
import * as cost from './slices/cost/selectCost';
import * as featureFlags from './slices/config/selectFeatureFlags';
import * as networks from './slices/config/selectNetworks';
import * as tokens from './slices/config/selectTokens';
import * as filters from './slices/filters/selectFilters';
import * as results from './slices/results/selectResults';
import * as promotions from './slices/promotions/selectPromotions';
import * as ui from './slices/ui/selectUI';
import * as specialties from './slices/specialties/selectSpecialties';
import * as tooltip from './slices/tooltip/selectTooltip';
import * as map from './slices/map/selectMap';
import * as notifications from './slices/notifications/selectNotifications';
import * as search from './slices/search/selectSearch';
import * as location from './slices/location/selectLocation';
import * as services from './slices/services/selectServices';
import * as banner from './slices/banner/selectBanner';
import * as rewards from './slices/rewards/selectRewards';
import * as referringClinic from './slices/referringClinic/selectReferringClinic';
import provider, { getProviderString, getProvidersString } from './slices/results/selectProvider';
import place, { getPlaceString, getPlacesString } from './slices/results/selectPlace';

const select = {
  aiSearch,
  axios,
  auth,
  banner,
  chat,
  client,
  config,
  content,
  cost,
  featureFlags,
  networks,
  tokens,
  filters,
  results,
  rewards,
  promotions,
  ui,
  specialties,
  tooltip,
  map,
  notifications,
  search,
  location,
  services,
  provider,
  place,
  referringClinic,
  toString: {
    provider: getProviderString,
    providers: getProvidersString,
    place: getPlaceString,
    places: getPlacesString,
  },
};

export default select;
