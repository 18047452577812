import { useEffect, useMemo } from 'react';
import { ChildrenType } from 'propTypes/index';
import { useSelector, useDispatch } from 'react-redux';
import { select, thunks } from 'store/toolkit';

// @TODO: TECH-4023 revisit authorization / authentication handling
export default function AccessCheck({ children }) {
  const dispatch = useDispatch();
  const isClosedAccess = useSelector(select.config.isClosedAccess);
  const authSuccess = useSelector(select.auth.authSuccess);

  const bypassInit = useMemo(
    () => !isClosedAccess || window.location.pathname.includes('share'),
    [isClosedAccess]
  );

  const showApp = useMemo(() => {
    // open access clients and share routes
    if (bypassInit) return true;
    // closed access clients - await successful /init call
    if (authSuccess) return true;
    return false;
  }, [authSuccess, bypassInit]);

  useEffect(() => {
    dispatch(thunks.auth.fetchInit({ bypassInit }));
  }, [bypassInit, dispatch]);

  if (showApp) {
    return children;
  }
  return null;
}

AccessCheck.propTypes = {
  children: ChildrenType.isRequired,
};
