import { v4 as uuidv4 } from 'uuid';
import { distance as getDistance, sortProviderPlacesByDistance } from 'utils/utils';
import { MAX_RADIUS_VALUE, RADIUS_VALUES } from '../filters/filterConstants';

// @TODO: add tests
export const formatParametersForSearch = (params) => ({
  specialties: params.specialties,
  subspecialties: params.subspecialties,
  radius: params.radius,
  location: params.location,
  lgbtqCompetent: params.lgbtqCompetent,
  lookingForDiagnosis: params.lookingForDiagnosis,
  lookingForTreatment: params.lookingForTreatment,
  gender: params.gender,
  languagesSpoken: params.languagesSpoken?.join(),
  city: params.city,
  stateAbbreviation: params.stateAbbreviation,
});

export const formatChatResponseParameters = (params) => {
  // chat returns lat and lon, reassign to latitude and longitude
  // also reformat radiusInMiles to radius, and delete radiusInMiles property
  const formattedParams = {
    ...params,
    location: {
      latitude: params.location.lat,
      longitude: params.location.lon,
    },
    radius: params.radiusInMiles || 25,
  };
  delete formattedParams.radiusInMiles;
  return formattedParams;
};

export const buildApiUrl = ({ endpoint, params = {}, closestLocationSearch }) => {
  const {
    radius,
    specialtyId,
    subspecialtyId,
    lgbtqCompetent,
    lookingForDiagnosis,
    lookingForTreatment,
    gender,
    languagesSpoken,
    location,
    networkSlug,
  } = params;

  const ordering = [
    'best_match',
    lgbtqCompetent && '-outcare_competent',
    lookingForDiagnosis && '-diagnosing_percentile',
    lookingForTreatment && '-treatment_percentile',
  ]
    .filter(Boolean)
    .join(',');

  return (
    `/${endpoint}/?limit=3&ordering=${ordering}` +
    `&location=${location.latitude},${location.longitude}` +
    `&radius=${closestLocationSearch ? MAX_RADIUS_VALUE : radius}mi` +
    `&network_slug=${networkSlug}` +
    `${gender ? `&gender=${gender}` : ''}` +
    `${languagesSpoken ? `&languages=${languagesSpoken}` : ''}` +
    `${specialtyId ? `&specialty_id=${specialtyId}` : ''}` +
    `${subspecialtyId ? `&subspecialty_id=${subspecialtyId}` : ''}`
  );
};

export const formatProviderPlaces = (provider, coords) => {
  if (!provider || !coords) return null;
  const formattedProvider = { ...provider };
  const places = provider.places || [];
  formattedProvider.places = places.map((place) => ({ ...place, id: uuidv4() }));
  const sortedPlaces = sortProviderPlacesByDistance(places, coords.latitude, coords.longitude);
  // eslint-disable-next-line prefer-destructuring
  formattedProvider.closestVisibleLocation = sortedPlaces[0];

  return formattedProvider;
};

export const grammaticalCsl = (list, separator = 'and') => {
  if (list.length === 0) return null;
  if (list.length === 1) return list[0];
  // create array of labels,
  const copy = [...list];
  const last = copy.pop(); // returns last item, removes it from 'labels'
  return `${copy.join(', ')} ${separator} ${last}`;
};

/**
 * @param {Number} distance Distance specified (in this case by the Chat user).
 * @returns {Number} A preset radius value that the user supplied value is rounded *up* to
 */
export const findSmallestRadius = (distance) => {
  if (!distance) return null;
  if (distance > MAX_RADIUS_VALUE) return null;
  const { value } = RADIUS_VALUES.find((radiusObj) => distance <= radiusObj.value);
  return value;
};

/**
 * @param {{latitude: number, longitude: number}} point1 Latlong pair
 * @param {{latitude: number, longitude: number}} point2 Latlong pair
 * @returns {Number} A preset radius value that the user supplied value is rounded *up* to
 */
export const findSmallestRadiusBetweenPoints = (point1, point2) => {
  if (!point1 || !point2) return null;
  return findSmallestRadius(
    getDistance(point1.latitude, point1.longitude, point2.latitude, point2.longitude)
  );
};

const isPrimaryCareSpecialty = (specialtyObj = {}) =>
  specialtyObj?.specialtyId === 5 && !specialtyObj.subspecialtyId;

export function sortPrimaryCareToFront(specialtyArray = []) {
  return specialtyArray.sort((a, b) => {
    if (isPrimaryCareSpecialty(a)) return -1;
    if (isPrimaryCareSpecialty(b)) return 1;
    return 0;
  });
}
