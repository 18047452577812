/* eslint-disable no-nested-ternary */
import {
  CARE_CATEGORIES,
  PLACE_CARE_CATEGORIES,
  PLACE_RESULT_TYPE,
  PROVIDER_CARE_CATEGORIES,
  PROVIDER_RESULT_TYPE,
} from 'utils/constants';
import { FREEFORM_SEARCH_TYPE_MAP } from './searchConstants';

// TODO Write some unit tests for this function and refactor to make it a bit more readable
export function getLabelText({ suggestion, careCategory }) {
  const {
    entityName,
    specialty,
    name,
    searchTerm,
    specialtyName,
    subspecialtyName,
    serviceName,
    affiliationName,
    highlight,
  } = suggestion || {};
  let labelText = '';
  if (careCategory === CARE_CATEGORIES.PROVIDER_NAME && entityName) {
    labelText = `${entityName}${specialty ? ` (${specialty})` : ''}`;
  } else if (careCategory === CARE_CATEGORIES.FACILITY_NAME) {
    labelText = name;
  } else if (careCategory === CARE_CATEGORIES.PROVIDER_SPECIALTY) {
    labelText =
      highlight ||
      (searchTerm
        ? `${searchTerm} (${subspecialtyName || specialtyName})`
        : subspecialtyName
        ? `${subspecialtyName}`
        : specialtyName);
  } else if (careCategory === CARE_CATEGORIES.FACILITY_TYPE) {
    labelText =
      highlight ||
      (searchTerm
        ? `${searchTerm} (${subspecialtyName || specialtyName})`
        : subspecialtyName
        ? `${subspecialtyName}`
        : specialtyName);
  } else if (
    careCategory === CARE_CATEGORIES.PROVIDER_SERVICE ||
    careCategory === CARE_CATEGORIES.FACILITY_SERVICE
  ) {
    labelText = serviceName;
  } else if (careCategory === CARE_CATEGORIES.PROVIDER_AFFILIATION) {
    labelText = affiliationName;
  }
  return labelText;
}

export function getAutocompleteTypeFromCareCategory(careCategory) {
  const providerCareCategoryValues = Object.values(PROVIDER_CARE_CATEGORIES);
  const placeCareCategoryValues = Object.values(PLACE_CARE_CATEGORIES);

  if (!careCategory) return null;

  if (providerCareCategoryValues.includes(careCategory)) return PROVIDER_RESULT_TYPE;

  if (placeCareCategoryValues.includes(careCategory)) return PLACE_RESULT_TYPE;

  return null;
}

/**
 * This function is intended to accept a care category argument and return either the provider or place freeform category
 * Example: The 'specialty' careCategory is a provider care category and should return 'provider', the matching provider freeform category
 */
export function getRelatedFreeformCategory(careCategory) {
  const autocompleteType = getAutocompleteTypeFromCareCategory(careCategory);

  return FREEFORM_SEARCH_TYPE_MAP[autocompleteType] || FREEFORM_SEARCH_TYPE_MAP.provider;
}
