/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Link } from '@material-ui/core';
import { actions, select, constants } from 'store/toolkit';
import {
  COST_EFFICIENCY_SECTION,
  SUBSPECIALTY_SECTION,
  STRENGTHS_SECTION,
  SERVICE_COST_CONTENT,
  PRIMARY_AFFILIATION_METRIC,
} from 'utils/constants';
import DynamicFocusAreaText from 'components/ModernExperience/DynamicFocusAreaText';
import { AFFILIATION_TYPES } from 'store/slices/results/providerConstants';
import Place from 'icons/Place';
import GroupIcon from '@material-ui/icons/Group';

const useStyles = makeStyles(() => ({
  capitalize: { textTransform: 'capitalize' },
}));

/** Provider metrics are measurable data points of a provider object that are represented by this object
 * @typedef {object} ProviderMetric
 * @property {string} key A unique string identifier for the metric
 * @property {string} icon The URI of the icon displayed for this metric
 * @property {string} title The title or label of this metric
 * @property {string} text A text representation of the metric value.
 * @property {node} action Optional node to render in top right of metric
 * @property {string} className Optional className string
 */

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric|null}
 */
export function useCostEfficiencyMetric(provider) {
  const showCost = useSelector(select.featureFlags.showCost);
  const costText = useSelector(select.provider(provider.entityId).costText);

  return useMemo(() => {
    if (!showCost || !costText) return null;

    return {
      key: 'costEfficiency',
      icon: <COST_EFFICIENCY_SECTION.IconComponent />,
      title: COST_EFFICIENCY_SECTION.title,
      text: costText,
      description: COST_EFFICIENCY_SECTION.description,
    };
  }, [showCost, costText]);
}

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric|null}
 */
export function useFocusAreasMetric(provider, options) {
  const dispatch = useDispatch();

  const subspecialties = useSelector(select.provider(provider.entityId).sortedSubspecialties);
  const fullNameAndTitle = useSelector(select.provider(provider.entityId).fullNameAndTitle);

  const searchedSubspecialtyId = useSelector(select.results.subspecialtyId);
  const subspecialtyFilterArrayValue = useSelector(select.filters.byKey('subspecialties')).value;

  // identify the subspecialtyId's that should be highlighted as active
  const subspecialtiesIdsToHighlight = useMemo(() => {
    if (options?.highlightSubspecialties && Array.isArray(options.highlightSubspecialties)) {
      // when subspecialty id's are provided via options, use them
      return options.highlightSubspecialties;
    }
    // when no subspecialty id's are provided, default to using the ones from the results slice and filters slice
    const result = subspecialtyFilterArrayValue.map((ssIdString) => Number(ssIdString));
    if (searchedSubspecialtyId) result.push(searchedSubspecialtyId);
    return result;
  }, [options, searchedSubspecialtyId, subspecialtyFilterArrayValue]);

  const getIsSubspecialtyActive = useCallback(
    (ssId) => subspecialtiesIdsToHighlight.includes(ssId),
    [subspecialtiesIdsToHighlight]
  );

  return useMemo(() => {
    if (!subspecialties.length) return null;

    const subspecialtyNodes = subspecialties.map((ss, i) => (
      <React.Fragment key={`focus-area-${ss.subspecialtyId}-${provider.entityId}`}>
        <DynamicFocusAreaText
          subspecialty={ss}
          key={ss.subspecialtyName}
          isActive={getIsSubspecialtyActive(ss.subspecialtyId)}
        />
        {i < subspecialties.length - 1 && ', '}
      </React.Fragment>
    ));

    const handleClick = () => {
      dispatch(
        actions.ui.openProfileModal({
          type: constants.ui.PROFILE_MODAL_TYPES.FOCUS_AREAS,
          data: { id: provider.entityId, profileUrl: options?.profileUrl },
        })
      );
    };

    const action = (
      <Link
        component="button"
        onClick={handleClick}
        aria-label={`${fullNameAndTitle} See more ${SUBSPECIALTY_SECTION.title}`}
      >
        See More &gt;
      </Link>
    );

    return {
      key: 'focusAreas',
      action,
      icon: <SUBSPECIALTY_SECTION.IconComponent />,
      title: SUBSPECIALTY_SECTION.title,
      text: subspecialtyNodes,
      description: SUBSPECIALTY_SECTION.description,
    };
  }, [subspecialties, provider, getIsSubspecialtyActive, fullNameAndTitle, dispatch, options]);
}

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric|null}
 */
export function usePrimaryAffiliationMetric({ entityId }, options) {
  const dispatch = useDispatch();
  const { primaryAffiliation, groupAffiliations, hospitalAffiliations } = useSelector(
    select.provider(entityId).sortedAffiliations
  );

  const fullNameAndTitle = useSelector(select.provider(entityId).fullNameAndTitle);

  return useMemo(() => {
    if (!primaryAffiliation || !primaryAffiliation.type) return null;
    const Icon = primaryAffiliation.type === AFFILIATION_TYPES.HOSPITAL ? Place : GroupIcon;

    const handleClick = () => {
      dispatch(
        actions.ui.openProfileModal({
          type: constants.ui.PROFILE_MODAL_TYPES.AFFILIATIONS,
          data: { id: entityId, profileUrl: options?.profileUrl },
        })
      );
    };

    const additionalAffiliationCount = groupAffiliations.length + hospitalAffiliations.length - 1;

    const action = additionalAffiliationCount > 0 && (
      <Link
        component="button"
        onClick={handleClick}
        aria-label={`See ${fullNameAndTitle} ${additionalAffiliationCount} more affiliation
        ${additionalAffiliationCount > 1 && 's'}`}
      >
        See {additionalAffiliationCount} more affiliation
        {additionalAffiliationCount > 1 && 's'} &gt;
      </Link>
    );

    return {
      key: 'primaryAffiliation',
      action,
      icon: <Icon />,
      text: primaryAffiliation.label,
      title: PRIMARY_AFFILIATION_METRIC.title,
      description: PRIMARY_AFFILIATION_METRIC.description,
    };
  }, [
    dispatch,
    entityId,
    groupAffiliations.length,
    hospitalAffiliations.length,
    primaryAffiliation,
    fullNameAndTitle,
    options,
  ]);
}

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric|null}
 */
export function useStrengthsMetric(provider) {
  const strengths = useSelector(select.provider(provider.entityId).providerStrengths);

  return useMemo(() => {
    if (!strengths.length) return null; // return null if there are no strengths

    return {
      key: 'strengths',
      icon: <STRENGTHS_SECTION.IconComponent />,
      title: STRENGTHS_SECTION.title,
      text: strengths.map((strength) => strength.label).join(', '),
      description: STRENGTHS_SECTION.description,
    };
  }, [strengths]);
}

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric|null}
 */
function useServiceCostMetric(provider, options) {
  const dispatch = useDispatch();
  const service = useSelector(
    options?.service !== undefined ? () => options.service : select.services.current
  );
  const showServiceCost = useSelector(select.featureFlags.showServiceCost);

  const classes = useStyles();

  return useMemo(() => {
    if (!showServiceCost) return null;

    const handleClick = () => {
      dispatch(
        actions.ui.openProfileModal({
          type: constants.ui.PROFILE_MODAL_TYPES.SERVICE_COST,
          data: {
            id: provider.entityId,
            profileUrl: options?.profileUrl,
            locations: options?.locations,
            comparisonLocation: options?.comparisonLocation,
          },
        })
      );
    };

    const actionButton = (
      <Link
        component="button"
        onClick={handleClick}
        aria-label={`See estimate for ${service?.serviceName || 'service'} cost`}
      >
        {SERVICE_COST_CONTENT.buttonText}
      </Link>
    );

    const title = service ? `${service.serviceName} Cost` : 'Service Costs';

    return {
      key: 'serviceCost',
      icon: <SERVICE_COST_CONTENT.IconComponent />,
      title: `${title}:`,
      text: SERVICE_COST_CONTENT.description,
      action: actionButton,
      className: classes.capitalize,
    };
  }, [classes.capitalize, dispatch, provider.entityId, service, showServiceCost, options]);
}

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric[]}
 */
export function useMetricsList(provider, options) {
  const costEfficiency = useCostEfficiencyMetric(provider, options?.costEfficiency);
  const focusAreas = useFocusAreasMetric(provider, options?.focusAreas);
  const primaryAffiliation = usePrimaryAffiliationMetric(provider, options?.primaryAffiliation);
  const strengths = useStrengthsMetric(provider, options?.strengths);
  const serviceCost = useServiceCostMetric(provider, options?.serviceCost);

  return useMemo(() => {
    const metricList = [];

    if (focusAreas) metricList.push(focusAreas);
    if (strengths) metricList.push(strengths);
    if (primaryAffiliation) metricList.push(primaryAffiliation);
    if (costEfficiency) metricList.push(costEfficiency);
    if (serviceCost) metricList.push(serviceCost);
    return metricList;
  }, [costEfficiency, focusAreas, primaryAffiliation, serviceCost, strengths]);
}
