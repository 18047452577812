import * as Sentry from '@sentry/react';

export const ANALYTICS_ERROR = 'AnalyticsError';

/**
 * Custom error for handling analytics fetch failures.
 * @param {Object} response - The response object from the fetch call.
 * @param {Object} [event={}] - The original analytics event object.
 */
export default class AnalyticsError extends Error {
  constructor(response, event = {}) {
    const status = response?.status || null;
    const { eventCode = null } = event;
    const message = `${status || 'No response'} on analytics event${
      eventCode ? `: ${eventCode}` : ''
    }`;

    super(message);
    this.name = ANALYTICS_ERROR;
    this.eventCode = eventCode;
    this.response = response;
    this.status = response?.status || null;
    // the fingerprint used for grouping errors into issue in Sentry
    this.fingerprint = ['get', '/events', status, `eventCode:${eventCode || 'none'}`];
  }

  static isAnalyticsError(err) {
    return err instanceof AnalyticsError;
  }

  captureInSentry() {
    Sentry.captureException(this, (scope) => {
      // no user-facing implications so mark as warning
      scope.setLevel('warning');

      // fingerprint for grouping errors into issues
      scope.setFingerprint(this.fingerprint);

      // append extra data for improved debugging
      scope.setExtra('Event Code', this.eventCode);
      scope.setExtra('Response Status Code', this.status);
      scope.setExtra('Response Data', this.response?.data || 'No response data available');
      scope.setExtra('Request URL', this.response?.url || 'Unknown');
    });
  }
}
