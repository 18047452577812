/* eslint-disable no-shadow */
import { createSelector } from '@reduxjs/toolkit';

import { PLACE_RESULT_TYPE, PROVIDER_RESULT_TYPE } from 'utils/constants';
import { SPECIALTIES_SLICE_NAME } from '../slicesNames';

export const specialties = (state) => state[SPECIALTIES_SLICE_NAME].specialties;

export const subspecialties = (state) => state[SPECIALTIES_SLICE_NAME].subspecialties;

export const placeSpecialtiesMap = (state) =>
  state[SPECIALTIES_SLICE_NAME].specialties?.[PLACE_RESULT_TYPE];

export const placeSubspecialtiesMap = (state) =>
  state[SPECIALTIES_SLICE_NAME].subspecialties?.[PLACE_RESULT_TYPE];

export const placeSpecialtiesArray = createSelector([placeSpecialtiesMap], (specialtyMap) =>
  specialtyMap ? Object.values(specialtyMap) : null
);

export const providerSpecialtiesMap = (state) =>
  state[SPECIALTIES_SLICE_NAME].specialties?.[PROVIDER_RESULT_TYPE];

export const providerSpecialtiesArray = createSelector([providerSpecialtiesMap], (specialtyMap) =>
  specialtyMap ? Object.values(specialtyMap) : null
);

export const providerSubspecialtiesMap = (state) =>
  state[SPECIALTIES_SLICE_NAME].subspecialties?.[PROVIDER_RESULT_TYPE];

export const providerSubspecialtiesArray = createSelector(
  [providerSubspecialtiesMap],
  (subspecialtyMap) => (subspecialtyMap ? Object.values(subspecialtyMap) : null)
);
export const loadingSpecialties = (state) => state[SPECIALTIES_SLICE_NAME].loadingSpecialties;

export const failedToLoadSpecialties = (state) =>
  state[SPECIALTIES_SLICE_NAME].failedToLoadSpecialties;

export function bySpecialtyId(id) {
  return createSelector(
    [providerSpecialtiesMap, placeSpecialtiesMap],
    (providerSpecialtiesMap, placeSpecialtiesMap) => {
      if (id === undefined) return undefined;
      if (id >= 300 && id < 400) {
        // must be a place specialty
        return placeSpecialtiesMap[id];
      }

      return providerSpecialtiesMap[id];
    }
  );
}

export function bySubspecialtyId(id) {
  return createSelector(
    [providerSubspecialtiesMap, placeSubspecialtiesMap],
    (providerSubspecialties, placeSubspecialties) => {
      if (id === undefined) return undefined;
      if (id >= 400 && id < 500) {
        // must be a place subspecialty
        return placeSubspecialties[id];
      }

      return providerSubspecialties[id];
    }
  );
}
