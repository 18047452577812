import React from 'react';
import PropTypes from 'propTypes';
import { List } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import { useMetricsList } from 'hooks/Provider/useProviderMetrics';
import Metric from '../../Metric';

function ProviderMetricList({ providerId, options, MetricProps = {}, ...props }) {
  const providerData = useSelector(select.provider(providerId).data);
  const fullNameAndTitle = useSelector(select.provider(providerId).fullNameAndTitle);
  const metricsList = useMetricsList(providerData, options);

  if (!Array.isArray(metricsList) || !metricsList.length) return null;

  return (
    <List disablePadding {...props}>
      {metricsList.map((metric, i) => (
        <Metric
          listItem
          marginBottom={i < metricsList.length - 1}
          key={metric.key}
          icon={metric.icon}
          title={metric.title}
          TooltipProps={{
            message: metric.description,
            ariaLabel: `${fullNameAndTitle} ${metric.title}`,
          }}
          action={metric.action}
          className={metric.className}
          {...MetricProps}
        >
          {metric.text || ''}
        </Metric>
      ))}
    </List>
  );
}

ProviderMetricList.propTypes = {
  className: PropTypes.string,
  providerId: PropTypes.string.isRequired,
  MetricProps: PropTypes.shape({}),
  options: PropTypes.shape({
    focusAreas: PropTypes.shape({
      highlightSubspecialties: PropTypes.arrayOf(PropTypes.number),
      profileUrl: PropTypes.string,
    }),
    serviceCost: PropTypes.shape({
      profileUrl: PropTypes.string,
      locations: PropTypes.arrayOf(PropTypes.shape({})),
      comparisonLocation: PropTypes.shape({
        locationInput: PropTypes.string.isRequired,
        coordinates: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired,
        }),
      }),
      service: PropTypes.shape({ serviceName: PropTypes.string.isRequired }),
    }),
  }),
};

ProviderMetricList.defaultProps = {
  className: '',
  MetricProps: {},
  options: {},
};

export default React.memo(ProviderMetricList);
