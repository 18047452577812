export const CONFIG_SLICE_NAME = 'config';
export const CHAT_SLICE_NAME = 'chat';
export const FILTERS_SLICE_NAME = 'filters';
export const PROMOTIONS_SLICE_NAME = 'promotions';
export const UI_SLICE_NAME = 'ui';
export const MAP_SLICE_NAME = 'map';
export const NOTIFICATIONS_SLICE_NAME = 'notifications';
export const SEARCH_SLICE_NAME = 'search';
export const LOCATION_SLICE_NAME = 'location';
export const RESULTS_SLICE_NAME = 'results';
export const SERVICES_SLICE_NAME = 'services';
export const SPECIALTIES_SLICE_NAME = 'specialties';
export const BANNER_SLICE_NAME = 'banner';
export const REWARDS_SLICE_NAME = 'rewards';
export const AUTH_SLICE_NAME = 'auth';
export const COST_SLICE_NAME = 'cost';
export const AI_SEARCH_SLICE_NAME = 'ai-search';
export const REFERRING_CLINIC_SLICE_NAME = 'referring-clinic';
