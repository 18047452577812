import { select, actions, thunks } from 'store/toolkit';
import { FEATURED_ACTION_VARIANTS } from 'utils/constants';
import { UI_INTERACTION, CHAT_VIEW, CHAT_INTERACTION, CONVERSION } from './analyticsConstants';

/* ************************************* */
/* *** 400-499 UI Interaction Events *** */
/* ************************************* */

export default {
  tooltipOpened: {
    code: 400,
    category: UI_INTERACTION,
    description: 'A user opened a tooltip to get more information',
    getEventData: (state) => ({
      title: select.tooltip.getTitle(state),
      message: select.tooltip.getMessage(state),
    }),
    triggers: [actions.tooltip.open],
  },

  chatModalOpened: {
    code: 401,
    category: CHAT_VIEW,
    description: 'A user has opened the chat modal',
    getEventData: () => ({
      eventAction: 'Chat Modal Opened',
      eventLabel: 'Chat Modal Opened',
    }),
    labelDescription: 'Chat Modal Opened',
    actionDescription: 'Chat Modal Opened',
  },

  /**
   * @param {string} action Url for the cost estimator tool being directed to
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.costEstimatorRedirect(<carrier portal url>));
   */
  costEstimatorRedirect: {
    code: 402,
    category: UI_INTERACTION,
    description: 'A user has been redirected to external cost estimator tool',
    getEventData: (state, { payload = '' }) => ({
      eventAction: `User redirected to ${payload} for cost estimator tool`,
      eventLabel: 'Cost Estimator Button Click',
    }),
    labelDescription: 'Cost Estimator Button Click',
    actionDescription: 'The url that the user was redirected to for cost estimator tool',
  },

  /**
   * @example
   * Action dispatch
   * dispatch(_analyticsActions.howToVideoClick());
   */
  howToVideoClick: {
    code: 403,
    category: UI_INTERACTION,
    description: 'A user has opened the how-to video from the landing page',
    getEventData: () => ({
      eventLabel: 'How To Video Click',
      eventAction: 'How To Video Click',
    }),
    labelDescription: 'How To Video Click',
    actionDescription: 'How To Video Click',
  },

  featuredActionClick: {
    code: 404,
    category: UI_INTERACTION,
    description: 'A featured action from the homepage was clicked',
    getEventData: (state, action) => {
      const featuredActionObj = action.meta?.arg || action.payload; // featured action would be passed as an arg to a thunk, or in the payload of a raw _analyticsAction dispatch

      const {
        title,
        variant,
        search_specialty: specialtyId,
        search_subspecialty: subspecialtyId,
        promotion: promotionId,
      } = featuredActionObj;

      const variantMap = {
        [FEATURED_ACTION_VARIANTS.PROMOTION]: 'Promotion',
        [FEATURED_ACTION_VARIANTS.SEARCH]: 'Search',
        default: 'unknown',
      };

      return {
        title,
        variant: variantMap[variant] || variantMap.default,
        specialtyId,
        subspecialtyId,
        promotionId,
        eventLabel: 'Featured Action Click',
      };
    },
    triggers: [thunks.results.featuredActionSearch.pending],
  },

  chatExternalLinkClick: {
    code: 405,
    category: CHAT_INTERACTION, // this is the default category, however this may be overwritten by the getEventData function
    description:
      "A user clicked on a link that was generated by Eva. This is commonly a link to a benefit's website but not always. Any promotions who's website matches the url will be included in the payload as matchingPromotionIds and matchingPromotionNames.",
    getEventData: (state, action) => {
      const { url, linkText } = action.payload;
      const chatKey = select.chat.chatKey(state);
      const locationInput = select.chat.locationInput(state);

      const eventPayload = {
        url: encodeURIComponent(url), // without encodeURIComponent the WAF will block this event
        linkText,
        chatKey,
        location_input: locationInput, // explicity setting this because chat may have a different location that the app
        eventLabel: 'Chat External Link Click',
      };

      // attempt to find any matching promotion for the given url
      const allPromotions = select.promotions.promotions(state);
      const matchingPromotionIds = [];
      const matchingPromotionNames = [];

      allPromotions.forEach((promotion) => {
        if (promotion.website === url) {
          eventPayload.eventCategory = CONVERSION; // if we can successfully match on a promotion we should mark as a conversion.
          matchingPromotionIds.push(promotion.id);
          matchingPromotionNames.push(promotion.programName);
        }
      });

      if (matchingPromotionIds.length)
        eventPayload.matchingPromotionIds = matchingPromotionIds.join(',');
      if (matchingPromotionNames.length)
        eventPayload.matchingPromotionNames = matchingPromotionNames.join(',');

      return eventPayload;
    },
  },
};
