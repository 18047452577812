import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export default createSvgIcon(
  <>
    <path d="M14.202 10.1368V14.8H12.9971V13.401H11.3906V14.8H10.1857V10.1368C10.1857 9.62387 10.5472 9.20418 10.989 9.20418H13.3988C13.8406 9.20418 14.202 9.62387 14.202 10.1368ZM12.9971 10.6031H11.3906V12.0021H12.9971V10.6031Z" />
    <path d="M5.5 14.8H6.83877V12.7016H8.84694V11.3026H6.83877V10.6031H9.51632V9.20418H5.5V14.8Z" />
    <path d="M14.8898 9.2L16.4346 11.9979L14.8898 14.7958L16.3903 14.7958L17.935 11.9979L16.3903 9.2L14.8898 9.2Z" />
    <path d="M19.5 14.7958L17.9553 11.9979L19.5 9.2L17.9995 9.2L16.4548 11.9979L17.9995 14.7958L19.5 14.7958Z" />
    <path d="M20.2 1.5H4.8C3.26 1.5 2 2.76 2 4.3V19.7C2 21.24 3.26 22.5 4.8 22.5H20.2C21.74 22.5 23 21.24 23 19.7V4.3C23 2.76 21.74 1.5 20.2 1.5ZM20.9 20.4H4.1V3.6H20.9V20.4Z" />
  </>,
  'Fax'
);
