import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Collapse, Paper, Container } from '@material-ui/core';
import { select, thunks, actions } from 'store/toolkit';
import QuickFeedbackForm from './QuickFeedbackForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));
export default function ChatQuickFeedback() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const progress = useSelector(select.chat.quickFeedbackProgress);
  const isPerformingAllSearches = useSelector(select.chat.isPerformingAllSearches);
  const chatKey = useSelector(select.chat.chatKey);

  const setProgress = useCallback(
    (step) => {
      dispatch(actions.chat.setQuickFeedbackProgress(step));
    },
    [dispatch]
  );

  const handleSubmit = useCallback(
    (payload) => {
      dispatch(thunks.chat.submitQuickFeedback({ ...payload, chatKey }));
    },
    [chatKey, dispatch]
  );

  return (
    <Collapse classes={classes} component={Paper} elevation={0} in={!isPerformingAllSearches}>
      <Container maxWidth="md">
        <QuickFeedbackForm
          prompt="How was your overall chat experience with Embold's Virtual Assistant?"
          handleSubmit={handleSubmit}
          progress={progress}
          setProgress={setProgress}
        />
      </Container>
    </Collapse>
  );
}
