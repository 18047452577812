import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { logDevMessage } from 'utils/utils';
import {
  CARE_CATEGORIES,
  PLACE_RESULT_TYPE,
  DEFAULT_PROVIDER_SORT,
  DEFAULT_PLACE_SORT,
  PROVIDER_TYPE,
  PLACE_TYPE,
} from 'utils/constants';
import { ENDPOINTS } from 'store/fusionServices/fusionConstants';
import * as chatActions from 'store/slices/chat/chatSlice';
import { startOver, updateStoreFromUrl } from 'store/appActions';
import {
  executeSearch,
  executeTopSearch,
  searchThisArea,
} from 'store/slices/results/resultsThunks';
import {
  BOOL_FILTER_TYPE,
  ARRAY_FILTER_TYPE,
  VALID_RADIUS_VALUES,
  MAX_RADIUS_VALUE,
  FILTER_KEYS,
} from './filterConstants';
import { FILTERS_SLICE_NAME } from '../slicesNames';
import { fetchPredictedResultCount, fetchFilterOptions } from './filterThunks';
import { findSmallestRadius } from '../chat/chatUtils';
import { getDynamicFilterOptions, isValidOrderingParam } from './filterUtils';
import { combinedLocalConfig } from '../config/configSlice';

function clearFilterByKey(state, key) {
  const { filters } = state;
  const filter = filters[key];

  if (!filter) {
    logDevMessage(`Cannot find filter ${key}`);
  } else if (filter.type === BOOL_FILTER_TYPE) {
    filter.value = false;
  } else if (filter.type === ARRAY_FILTER_TYPE) {
    filter.value = [];
  }
}

const initialState = {
  options: {
    isLoading: false,
    error: null,
    isInvalidated: true,
  },
  filters: {
    [FILTER_KEYS.GENDER]: {
      label: 'Gender',
      value: [],
      type: ARRAY_FILTER_TYPE,
      disabled: false,
      options: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
      ],
      isDynamic: false,
      providers: true,
    },
    [FILTER_KEYS.LANGUAGES]: {
      label: 'Languages Spoken',
      value: [],
      type: ARRAY_FILTER_TYPE,
      disabled: false,
      options: [],
      isDynamic: true,
      providers: true,
    },
    [FILTER_KEYS.GROUP_AFFILIATIONS]: {
      label: 'Group Affiliations',
      value: [],
      type: ARRAY_FILTER_TYPE,
      disabled: false,
      options: [],
      isDynamic: true,
      providers: true,
    },
    [FILTER_KEYS.HOSPITAL_AFFILIATIONS]: {
      label: 'Hospital Affiliations',
      value: [],
      type: ARRAY_FILTER_TYPE,
      disabled: false,
      options: [],
      isDynamic: true,
      providers: true,
    },
    [FILTER_KEYS.SPECIALTIES]: {
      label: 'Specialties',
      value: [],
      type: ARRAY_FILTER_TYPE,
      disabled: false,
      options: [],
      isDynamic: true,
      providers: true,
      isUniversal: true, // every entity has a specialty
    },
    [FILTER_KEYS.SUBSPECIALTIES]: {
      label: 'Focus Areas',
      value: [],
      type: ARRAY_FILTER_TYPE,
      disabled: false,
      options: [],
      isDynamic: true,
      providers: true,
    },
    [FILTER_KEYS.ACCEPTING_NEW_PATIENTS]: {
      label: 'Accepting New Patients',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: false,
      providers: true,
    },
    [FILTER_KEYS.MATCHED_ON]: {
      label: 'Matched On',
      value: [],
      type: ARRAY_FILTER_TYPE,
      disabled: false,
      providers: true,
      options: [],
      isDynamic: true,
      isUniversal: true, // every entity has this property
    },
    // All filters under this line are disabled by default
    // They get enabled through the updateFromClientConfig action that is dispatched in useInitializeFilters
    [FILTER_KEYS.FEATURED]: {
      label: 'Featured', // placeholder label until client config update
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    [FILTER_KEYS.FEATURED_FACILITY]: {
      label: 'Featured', // placeholder label until client config update
      value: false,
      type: BOOL_FILTER_TYPE,
      places: true,
      disabled: true,
    },
    [FILTER_KEYS.CREDENTIALS]: {
      label: 'Available Credentials',
      value: [],
      type: ARRAY_FILTER_TYPE,
      disabled: true,
      options: [],
      isDynamic: true,
      providers: true,
    },
    [FILTER_KEYS.OUTCARE_COMPETENT]: {
      label: 'LGBTQ+ Competent',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    [FILTER_KEYS.HIGH_PERFORMING]: {
      label: 'Highly Rated',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    [FILTER_KEYS.IN_NETWORK_PREFERRED]: {
      label: 'In Network Preferred',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    [FILTER_KEYS.HAS_BENEFIT_DIFF]: {
      label: '$0 Copay',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    [FILTER_KEYS.HEALTH_CONNECT_PLAN]: {
      label: 'Health Connect Plan',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    [FILTER_KEYS.TELEHEALTH_AVAILABLE]: {
      label: 'Telehealth Available',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    [FILTER_KEYS.BOARD_CERTIFIED]: {
      label: 'Board Certified',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    [FILTER_KEYS.IS_WHEELCHAIR_ACCESSIBLE]: {
      label: 'Handicap Accessible',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    [FILTER_KEYS.PREFERRED_GROUP]: {
      label: 'Preferred Group',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    // filter keys prefixed with 'exclude' will affect the 'showExclusions' selector in selectFilters
    [FILTER_KEYS.EXCLUDE_CLIENT_FEATURED]: {
      label: 'Exclude Client Featured',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
  },
  sort: {
    provider: DEFAULT_PROVIDER_SORT,
    // This is the most reliable way to accurately set the default place sort at this time, but this will need to be updated as soon as
    // we can load the client config before the app loads. We can then use the client config FEATURED_FACILITY_BANNER_TEXT field to determine this value.
    place: DEFAULT_PLACE_SORT,
  },
  predictedResults: {
    count: null,
    isLoading: false,
    error: null,
  },
  quickFilterType: null,
  baseParamPredictedResults: 0,
  radius: combinedLocalConfig.DEFAULT_SEARCH_RADIUS,
  isBoundingBoxSearch: false,
};

export function updateArrayFilter(state, action) {
  const { key, value: newValue } = action.payload;
  // ensure that new value is a string
  if (typeof newValue !== 'string') {
    throw new Error(`${ARRAY_FILTER_TYPE} require a string value`);
  }

  // if current array includes the string, remove it. If not, add it
  if (state.filters[key].value.includes(newValue)) {
    state.filters[key].value = state.filters[key].value.filter((str) => str !== newValue);
  } else {
    state.filters[key].value.push(newValue);
  }
}

export function updateBooleanFilter(state, action) {
  const { key, value: newValue } = action.payload;

  // ensure that new value is boolean
  if (typeof newValue !== 'boolean') {
    throw new Error(`${BOOL_FILTER_TYPE} require a boolean value`);
  }

  state.filters[key].value = newValue;
}

const filtersSlice = createSlice({
  name: FILTERS_SLICE_NAME,
  initialState,
  reducers: {
    handleFilterChange(state, action) {
      const { key } = action.payload;

      // find the filter to change
      const filterType = state.filters[key]?.type || undefined;

      try {
        switch (filterType) {
          case ARRAY_FILTER_TYPE:
            updateArrayFilter(state, action);
            break;
          case BOOL_FILTER_TYPE:
            updateBooleanFilter(state, action);
            break;
          case undefined:
            throw new Error(`Cannot find filter ${key}`);
          default:
            throw new Error(`Unhandled filter type ${filterType} for ${key}`);
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    clearAll(state) {
      const { filters } = state;
      // clear is for handling the `Clear Filters` button, NOT for start over
      for (const [key] of Object.entries(filters)) {
        clearFilterByKey(state, key);
      }
    },
    clearByKey(state, action) {
      const key = action.payload;
      clearFilterByKey(state, key);
    },
    updateFromResults(state, action) {
      // Example payload { filters: { acceptingNewPatients: true, languages: ['Spanish', 'French'], ...etc }, radius: 25 } }
      const { filters, radius } = action.payload;

      if (radius) {
        state.radius = radius;
      }
      state.isBoundingBoxSearch = !radius;

      for (const filterKey in state.filters) {
        if (filters[filterKey] !== undefined) {
          state.filters[filterKey].value = filters[filterKey];
        } else {
          // if filterKey does not exist in filters, we should reset it's value
          // this way, the UI will never show a filter as enabled when it has not been applied
          state.filters[filterKey].value = initialState.filters[filterKey].value;
        }
      }
    },
    updateFromClientConfig(state, action) {
      const { filters = {} } = action.payload;

      // iterate over enabled to enable certain filters
      for (const [filterKey, filterOverrides] of Object.entries(filters)) {
        if (state.filters[filterKey]) {
          // if filter key exists, set disabled. Disabled is the opposite of the filtersToEnable value
          state.filters[filterKey] = { ...state.filters[filterKey], ...filterOverrides };
        } else {
          logDevMessage(`Filter ${filterKey} does not exist`);
        }
      }
    },
    setProviderSort(state, action) {
      const newValue = action.payload;
      state.sort.provider = newValue;
    },
    setPlaceSort(state, action) {
      const newValue = action.payload;
      state.sort.place = newValue;
    },
    invalidateOptions(state) {
      state.options.isInvalidated = true;
    },

    setFilterRadius(state, action) {
      const newValue = action.payload;

      if (!VALID_RADIUS_VALUES.includes(newValue)) {
        logDevMessage(`Invalid radius ${newValue}`);
      }

      state.radius = newValue;
      state.options.isInvalidated = true;
      state.isBoundingBoxSearch = false;
    },
    setIsBoundingBoxSearch(state, action) {
      const { payload = false } = action;
      state.isBoundingBoxSearch = Boolean(payload);
    },
  },
  extraReducers(builder) {
    // filter counts
    builder
      .addCase(fetchPredictedResultCount.pending, (state) => {
        state.predictedResults.isLoading = true;
        state.predictedResults.error = null;
      })
      .addCase(fetchPredictedResultCount.fulfilled, (state, action) => {
        state.predictedResults.isLoading = false;
        state.predictedResults.error = null;

        const { resultCount } = action.payload;
        const { stripQueryParams } = action.meta.arg || {};

        if (stripQueryParams) {
          state.baseParamPredictedResults = resultCount;
        } else {
          state.predictedResults.count = resultCount;
        }
      })
      .addCase(fetchPredictedResultCount.rejected, (state, action) => {
        state.predictedResults.isLoading = false;
        state.predictedResults.count = null;
        state.predictedResults.error = action.error.message || 'Failed to get result count';
      });

    // start over
    builder.addCase(startOver, (state) => {
      const { filters } = state;

      for (const [key, filter] of Object.entries(filters)) {
        if (filter.type === BOOL_FILTER_TYPE) {
          filters[key].value = false;
        } else if (filter.type === ARRAY_FILTER_TYPE) {
          filters[key].value = [];
        }
      }
      state.sort = initialState.sort;
      state.radius = initialState.radius;
    });

    // filter options
    builder
      .addCase(fetchFilterOptions.pending, (state) => {
        state.options.isLoading = true;
        state.options.error = null;
      })
      .addCase(fetchFilterOptions.fulfilled, (state, action) => {
        state.options.isLoading = false;
        state.options.error = null;
        state.options.isInvalidated = false;

        const newFilterOptions = action.payload;
        const currentFilters = state.filters;

        // iterate over filter options
        Object.keys(newFilterOptions).forEach((key) => {
          const updatedOptions = getDynamicFilterOptions(
            currentFilters[key],
            newFilterOptions[key]
          );
          if (updatedOptions) state.filters[key].options = updatedOptions;
        });
      })
      .addCase(fetchFilterOptions.rejected, (state, action) => {
        state.options.isLoading = false;
        state.options.isInvalidated = true;
        state.options.error = action.error.message || 'An error occurred';
      });

    // search form search
    builder.addCase(executeSearch.pending, (state, action) => {
      const { arg } = action.meta;

      if (arg?.clearArrayFilters) {
        for (const [key, filter] of Object.entries(state.filters)) {
          if (filter.type === ARRAY_FILTER_TYPE) {
            state.filters[key].value = [];
          }
        }
      }

      state.options.isInvalidated = !arg?.keepFilterOptionsValid;
    });

    builder.addCase(executeSearch.fulfilled, (state, action) => {
      state.quickFilterType =
        action.payload.request.endpoint === ENDPOINTS.PROVIDERS ? PROVIDER_TYPE : PLACE_TYPE;
    });

    builder.addCase(executeTopSearch.pending, (state) => {
      state.isBoundingBoxSearch = false;
    });

    builder.addCase(searchThisArea.pending, (state) => {
      state.isBoundingBoxSearch = true;
    });

    // url direct search
    builder.addCase(updateStoreFromUrl, (state, action) => {
      /* eslint-disable camelcase */
      const { radius, ordering, care_category, serviceType, bounding_box, ...urlParams } =
        action.payload;

      // validate radius value and set
      if (radius && VALID_RADIUS_VALUES.includes(radius)) state.radius = radius;

      if (bounding_box) {
        state.isBoundingBoxSearch = true;
      }

      if (ordering) {
        // when we receive an ordering param we need to determine if this is a place sort or provider sort
        const isPlaceSearch =
          care_category === CARE_CATEGORIES.FACILITY_NAME ||
          care_category === CARE_CATEGORIES.FACILITY_TYPE ||
          serviceType === PLACE_RESULT_TYPE;

        if (isValidOrderingParam(ordering, isPlaceSearch)) {
          if (isPlaceSearch) state.sort.place = ordering;
          else state.sort.provider = ordering;
        } else {
          // eslint-disable-next-line no-lonely-if
          if (isPlaceSearch) state.sort.place = DEFAULT_PLACE_SORT;
          else state.sort.provider = DEFAULT_PROVIDER_SORT;
        }
      }

      // dynamically gather the filter values sent from the url, and update the filter to match
      for (const filterKey in initialState.filters) {
        if (action.payload[filterKey]) {
          state.filters[filterKey].value = urlParams[filterKey];
        } else {
          // if no query param was received for this filter, it should be set to it's initial value
          state.filters[filterKey].value = initialState.filters[filterKey].value;
        }
      }
    });

    // chat
    builder.addCase(chatActions.specialtySearchInPg, (state, action) => {
      const { gender, lgbtqCompetent, radius, languagesSpoken } = action.payload;

      if (gender) {
        state.filters[FILTER_KEYS.GENDER].value = [gender];
      }

      state.filters[FILTER_KEYS.OUTCARE_COMPETENT].value = Boolean(lgbtqCompetent);

      if (Array.isArray(languagesSpoken)) {
        state.filters[FILTER_KEYS.LANGUAGES].value = languagesSpoken;
      }

      if (VALID_RADIUS_VALUES.includes(radius)) {
        state.radius = radius; // if the chat radius passed is valid, use it
      } else {
        // when the chat radius is not a valid value, round it up to the nearest valid value OR use the max valid value
        state.radius = findSmallestRadius(radius) || MAX_RADIUS_VALUE;
      }
      state.isBoundingBoxSearch = false;
    });
  },
});

export default filtersSlice;
export const {
  handleFilterChange,
  clearAll,
  clearByKey,
  updateFromResults,
  updateFromClientConfig,
  setProviderSort,
  setPlaceSort,
  setFilterRadius,
  invalidateOptions,
  setIsBoundingBoxSearch,
} = filtersSlice.actions;
