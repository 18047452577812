/* eslint-disable import/prefer-default-export */
import { DEFAULT_LOCATIONS, CONTINENTAL_RESOURCES, CHECKMARK } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import clrHeaderLogo from 'assets/clr_header_logo.svg';
import clrLoginLogo from 'assets/clr_login_logo.svg';

export const apiTokens = {
  dev: 'Token 0ba4fc3b55ef610541d71d7b1ca10b77eae62564',
  staging: 'Token 62b471a106e75992a1112c96700d538d886a8f4c',
  prod: 'Token e9038e04ff8a978a1fb1c7d53d6519f0785a049e',
};

const token = selectToken(apiTokens);

const clrConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'continental',

  API_TOKEN: token,
  EMBOLD_CLIENT: CONTINENTAL_RESOURCES,
  EMBOLD_CLIENT_SLUG: CONTINENTAL_RESOURCES,

  // login
  SHOW_REGION_SELECTOR: false,
  REGION_SELECTOR_CODES: {},

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.OklahomaCity },
  LOGIN_NETWORK_NAME: 'clrUserId',

  // content
  DISPLAY_NAME: 'Continental Resources',
  HEADER_LOGO: clrHeaderLogo,
  FORM_VARIANT: CHECKMARK,
  LOCAL_LOGO_ASSET: clrLoginLogo,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};

export default clrConfig;
