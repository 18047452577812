/* eslint-disable global-require */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';

import ScrollToTop from 'components/ScrollToTop';
import ErrorBoundary from 'components/ErrorBoundary';
import AccessCheck from 'components/AccessCheck';
import SplashPage from 'components/SplashPage/SplashPage';

import initializeSentry from 'monitoring/initializeSentry';
import ThemeProvider from 'themes/ThemeProvider';
import extractSsoParams from 'utils/extractSsoParams';
import { thunks } from 'store/toolkit';
import { FocusAnchorProvider } from 'utils/FocusRefContext';

import store from './store/store';
import { applyGoogleTranslateFix } from './googleTranslateFix';

let App;
if (process.env.REACT_APP_STANDALONE_CHAT === 'true') {
  App = require('./chat/StandaloneChatApp').default;
} else {
  App = require('App').default;
}

applyGoogleTranslateFix();

const [updatedUrl, { network, network_group: networkGroup, requireReferringClinic }] =
  extractSsoParams(window.location.href);
window.history.replaceState(null, null, updatedUrl);

initializeSentry();

store.dispatch(thunks.config.fetchClientConfig({ network, networkGroup, requireReferringClinic }));

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ErrorBoundary>
            <LastLocationProvider>
              <ScrollToTop>
                <SplashPage>
                  <AccessCheck>
                    <FocusAnchorProvider>
                      <App />
                    </FocusAnchorProvider>
                  </AccessCheck>
                </SplashPage>
              </ScrollToTop>
            </LastLocationProvider>
          </ErrorBoundary>
        </QueryParamProvider>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
