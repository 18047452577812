import { sortedIndex } from 'lodash';

import {
  ARRAY_FILTER_TYPE,
  FILTER_KEYS,
  MATCH_ON_PARAM_VALUES,
  VALID_RADIUS_VALUES,
} from 'store/slices/filters/filterConstants';
import { PLACE_SORT_OPTION_VALUES, PROVIDER_SORT_OPTION_VALUES } from 'utils/constants';
import { logDevMessage } from 'utils/utils';

/**
 * Accepts a radius number. Returns a new number rounded up to the nearest valid radius value, OR -1 if
 * @param {number} radius
 * @param {number} validRadius Returns the input up to the nearest valid radius. Returns -1 if input is over max.
 */
export function getNearestValidRadius(radius) {
  if (VALID_RADIUS_VALUES.includes(radius)) {
    return radius;
  }

  const closestIndex = sortedIndex(VALID_RADIUS_VALUES, radius); // get the index of the new radius rounded up to the nearest valid radius value.
  const newRadius = VALID_RADIUS_VALUES[closestIndex]; // new radius value should be the value rounded up, or simply the max radius value
  return newRadius || -1;
}

export function isValidOrderingParam(ordering, isPlaceSearch) {
  if (!ordering) return false;
  const sortOptions = isPlaceSearch ? PLACE_SORT_OPTION_VALUES : PROVIDER_SORT_OPTION_VALUES;
  return Object.values(sortOptions).indexOf(ordering) > -1;
}

/**
 *
 * @param {object} filterOptionsResponseData keys will map to keys from filtersSlice.filters
 * @returns {object} available filter options
 */
export const transformFilterOptions = (filterOptionsResponseData) => {
  const result = {};

  // **if new dynamic filters are added, they need to be added to the transform map**
  // the transform map is responsible for transforming each filter key into an array of options with
  // shape { label: string, value: any }. The label being the option displayed in the filter menu,
  // the value being the value that used to compose the filter query string on searches

  const transformMap = {
    specialtyCounts: {
      filter: 'specialties',
      transform: (specialtyArray) =>
        specialtyArray.map((specialty) => ({
          label: specialty.specialty,
          value: String(specialty.specialtyId), // this needs to be a string because the <Checkbox /> component converts it's value to a string.
        })),
    },
    availableLanguages: {
      filter: 'languages',
      transform: (languagesArray) =>
        languagesArray
          .filter((lang) => lang !== 'English')
          .map((lang) => ({ label: lang, value: lang })),
    },
    availableCredentials: {
      filter: 'credentials',
      transform: (credentialsArray) =>
        credentialsArray.map((cred) => ({ label: cred, value: cred })),
    },
    hospitalAffiliations: {
      filter: 'hospitalAffiliations',
      transform: (affiliations) => affiliations.map((aff) => ({ label: aff, value: aff })),
    },
    groupAffiliations: {
      filter: 'groupAffiliations',
      transform: (affiliations) => affiliations.map((group) => ({ label: group, value: group })),
    },
    subspecialties: {
      filter: 'subspecialties',
      transform: (subspecialtiesArray) =>
        subspecialtiesArray.map((specialty) => ({
          label: specialty.subspecialty,
          value: String(specialty.subspecialtyId), // this needs to be a string because the <Checkbox /> component converts it's value to a string.
        })),
    },
    /**
     * Expect that highlightCounts will be an object where the key represents the field that was matched, and the value represents the number of results that matched on that field
     */
    highlightCounts: {
      filter: 'matchedOn',
      transform: (highlightObject) => {
        if (!highlightObject || typeof highlightObject !== 'object') return [];

        const options = [];

        // name
        const { entityName = 0 } = highlightObject;
        if (entityName) options.push({ label: 'Name', value: MATCH_ON_PARAM_VALUES.NAME });

        // affiliations
        const { hospitalAffiliations = 0, groupAffiliations = 0 } = highlightObject;
        const affiliationTotal = hospitalAffiliations + groupAffiliations;

        if (affiliationTotal)
          options.push({
            label: 'Affiliations',
            value: MATCH_ON_PARAM_VALUES.AFFILIATIONS,
          });

        // specialty
        const { keyword = 0, specialty = 0, subspecialtyNames = 0 } = highlightObject;
        const specialtyTotal = keyword + specialty + subspecialtyNames;

        if (specialtyTotal)
          options.push({
            label: 'Specialty, Focus Area, or Keyword',
            value: MATCH_ON_PARAM_VALUES.SPECIALTY,
          });

        return options;
      },
    },
  };

  // "filter count" keys with no associated filters
  const ignoreKeys = ['scoreTierCounts'];

  for (const key in filterOptionsResponseData) {
    if (transformMap[key]) {
      result[transformMap[key].filter] = transformMap[key].transform(
        filterOptionsResponseData[key]
      );
    } else if (!ignoreKeys.includes(key)) {
      logDevMessage(
        `Unhandled filter key in transformFilterOptions: ${key}. No corresponding transformMap available. To ingest this filter options data into the filtersSlice a transformMap must be provided.`
      );
    }
  }
  return result;
};

/**
 * for a property that exists on every entity there's no purpose including a filter if there's only one available option, since all results will match
 * example: "specialty" filter (isUniversal: true) - if only "Primary Care" is available, all results will match
 * example: "language" filter (isUniversal: undefined) - even if only "Spanish" is available, not every result will necessarily match
 * @param {object} filter ARRAY_TYPE filter
 * @returns bool
 */
export const arrayFilterOptionsAreValid = (filter) => {
  const { options = [], isUniversal } = filter || {};
  if (!options.length) return false;
  if (isUniversal && options.length < 2) return false;
  return true;
};

/** QUICK FILTERS */

/**
 * Accepts a list of valid filter keys, returns the same list filtered to remove mutually exclusive filters
 * @param {string[]} filterKeys
 * @returns {string[]}
 */
export const filterMutuallyExclusiveChips = (filterKeys) => {
  // in order of preference
  const specialties = [FILTER_KEYS.SPECIALTIES, FILTER_KEYS.SUBSPECIALTIES];
  // if filters[0] , remove filters[1]
  if (filterKeys.includes(specialties[0])) {
    return filterKeys.filter((key) => key !== specialties[1]);
  }
  return filterKeys;
};

/**
 * Determines if a filter should be shown
 * @param {object} filter
 * @returns bool
 */
export const isQuickFilterValid = (filter) => {
  if (!filter) return false;
  if (filter.disabled) return false;
  if (filter.type === ARRAY_FILTER_TYPE) return arrayFilterOptionsAreValid(filter);
  return true;
};

/**
 * @typedef {object} FilterOption
 * @property {string} value
 * @property {string} label
 *
 * @typedef {object} Filter a filter as defined in filterSlice.filters
 * @property {boolean} isDynamic flag for filters whose values are dynamically provided by /filter-options endpoint
 * @property {string[]} value array of selected values in the current filter (dynamic filters are always ARRAY_TYPE_FILTER)
 * @property {FilterOption[]} options all current filter options
 *
 * @typedef {FilterOption[]} IncomingFilter array of incoming filter options.
 */

/**
 * Find active filter options that are missing from the incoming filter options
 * @param {Filter} currentFilter current filter object
 * @param {IncomingFilter} incomingFilter array of incoming filter options
 * @returns {string[]} array of missing values
 */
export const findMissingValues = (currentFilter, incomingFilterOptions) =>
  currentFilter.value.filter((val) => !incomingFilterOptions.some((opt) => opt.value === val));

/**
 * Retrieves filter option objects that match the given filter values
 * @param {string[]} filterValues array of filter values to find options for
 * @param {FilterOption[]} filterOptions array of available filter options
 * @returns {FilterOption[]} array of matching filter option objects
 */
export const getOptionsFromValues = (filterValues, filterOptions) => {
  if (!filterValues?.length) return [];
  return filterValues.map((val) => filterOptions.find((opt) => opt.value === val));
};

/**
 * Generate dynamic filter options by retaining active values when applicable
 * @param {Filter} currentFilter current filter object
 * @param {IncomingFilter} incomingFilter array of incoming filter options
 * @returns {FilterOption[]|undefined} updated filter options for dynamic filters. undefined otherwise
 */
export const getDynamicFilterOptions = (currentFilter, incomingFilter) => {
  if (currentFilter && currentFilter.isDynamic) {
    let filterOptions = incomingFilter;
    // retain any active values
    if (currentFilter.value.length) {
      const missingActiveValues = findMissingValues(currentFilter, incomingFilter);
      const missingOptions = getOptionsFromValues(missingActiveValues, currentFilter.options);
      filterOptions = [...missingOptions, ...incomingFilter];
    }
    return filterOptions;
  }
  return undefined;
};
