import React, { useMemo } from 'react';
import PropTypes, { ProviderDataShape } from 'propTypes';
import { useSelector } from 'react-redux';
import { Paper, Tabs, Tab, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ErrorIcon from '@material-ui/icons/Error';

import { select } from 'store/toolkit';
import EmboldLoadingSpinner from 'components/EmboldLoadingSpinner';
import ChatSearchTabPanel from 'components/Modals/ChatModal/ChatSearchTabPanel';
import useTabState from 'hooks/useTabState';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *:not(:last-child)': { marginBottom: theme.spacing(1) },
  },
  tabContainer: {
    width: '100%',
    marginBottom: 8,
    marginTop: 8,
    boxShadow: 'none',
    borderRadius: 8,
  },
  tabRoot: {
    padding: theme.spacing(1),
  },
  tabLabel: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 'normal',
    columnGap: theme.spacing(1),
  },
  expandedIcon: { color: theme.palette.warning.main },
  noResultsIcon: { color: theme.palette.error.main },
}));

function SearchIcon({ search }) {
  const classes = useStyles();

  if (search.isLoading) return <EmboldLoadingSpinner size={18} />;
  if (search.error) return <ErrorIcon color="error" />;
  if (!search.results.length) return <ErrorIcon className={classes.noResultsIcon} />;
  if (search.isExpanded) return <ErrorIcon className={classes.expandedIcon} />;
  return null;
}

SearchIcon.propTypes = {
  search: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    results: PropTypes.arrayOf(ProviderDataShape),
    isExpanded: PropTypes.bool,
  }).isRequired,
};

/**
 * Each tab represents a particular search that was performed.
 * For example, a chat may have resulted 3 searches for Physical Therapy, Ortho Joint and Sports Medicine.
 * Selecting a tab should render the corresponding ChatSearchTabPanel to display the results.
 */
export default function ChatSearchTabs() {
  const classes = useStyles();
  const requests = useSelector(select.chat.requestsWithResults);
  const [tabId, handleTabChange, getA11yTabProps, getA11yPanelProps] = useTabState(
    requests[0].requestKey || ''
  );
  const isXsDown = useSelector(select.ui.isXsDown);

  const tabsProps = useMemo(() => {
    const props = {
      variant: 'standard',
      scrollButtons: 'off',
      centered: true,
    };

    const isMoreThan1AtXs = isXsDown && requests.length > 1;

    if (isMoreThan1AtXs || requests.length > 2) {
      props.variant = 'scrollable';
      props.scrollButtons = 'on';
      props.centered = false;
    }

    return props;
  }, [requests.length, isXsDown]);

  return (
    <Box className={classes.root}>
      <Tabs
        component={Paper}
        elevation={0}
        value={tabId}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="Searches Performed"
        {...tabsProps}
      >
        {requests.map((search) => (
          <Tab
            key={search.requestKey}
            {...getA11yTabProps(search.requestKey)}
            classes={{
              root: classes.tabRoot,
              wrapper: classes.tabLabel,
            }}
            label={
              <>
                {search.label}
                <SearchIcon search={search} />
              </>
            }
            value={search.requestKey}
            disableFocusRipple
            disableRipple
          />
        ))}
      </Tabs>

      {requests.map((search) => (
        <ChatSearchTabPanel
          key={search.requestKey}
          hidden={search.requestKey !== tabId}
          {...getA11yPanelProps(search.requestKey)}
          label={search.label}
          search={search}
        />
      ))}
    </Box>
  );
}
