import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import ChatDevTools from 'components/Modals/ChatModal/DevTools/ChatDevTools';
import ActionButtons from 'components/Modals/ChatModal/ActionButtons';
import ChatSearchTabs from 'components/Modals/ChatModal/ChatSearchTabs';
import ChatQuickFeedback from 'components/Modals/ChatModal/Feedback/ChatQuickFeedback';
import ChatFeedback from 'components/Modals/ChatModal/Feedback/ChatFeedback';
import ChatHeader from 'components/Modals/ChatModal/ChatHeader';
import ChatInputForm from './ChatInputForm';
import ChatConversation from './ChatConversation';
import EmergencyDisclaimer from './EmergencyDisclaimer';
import useChat from './useChat';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *:not(:last-child)': { marginBottom: theme.spacing(1) },
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  conversation: {
    flex: 1,
  },
  input: {
    position: 'sticky',
    bottom: 0,
  },
}));

export default function ChatApp() {
  const classes = useStyles();
  const enableDevTools = useSelector(select.chat.devTools);
  const searchesHaveBeenPerformed = useSelector(select.chat.searchesHaveBeenPerformed);
  const showEmergencyDisclaimer = useSelector(select.chat.showEmergencyDisclaimer);
  const isChatOver = useSelector(select.chat.endChat);

  // TODO both of these values could be encapsulated by ChatFeedback and taken out of this component
  const chatKey = useSelector(select.chat.chatKey);
  const feedbackSuccess = useSelector(select.chat.feedbackSuccess);

  const {
    submit,
    reset,
    actionButtons,
    inputRef,
    endOfListRef,
    scrollToBottom,
    getConversationString,
  } = useChat();

  return (
    <Box className={classes.root}>
      <ChatHeader getConversationAsString={getConversationString} onReset={reset} />

      <ChatConversation
        className={classes.conversation}
        endOfListRef={endOfListRef}
        scrollToBottom={scrollToBottom}
      >
        {searchesHaveBeenPerformed && (
          <>
            <ChatSearchTabs />
            <ChatQuickFeedback />
          </>
        )}
        {isChatOver && <ChatFeedback chatKey={chatKey} success={feedbackSuccess} chatLog="" />}

        <ActionButtons actionButtons={actionButtons} />
        {showEmergencyDisclaimer && <EmergencyDisclaimer />}
      </ChatConversation>

      <ChatInputForm
        onReset={reset}
        onSubmit={submit}
        inputRef={inputRef}
        className={classes.input}
      />
      {enableDevTools && <ChatDevTools />}
    </Box>
  );
}
