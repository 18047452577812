import React from 'react';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';
import ProviderContactCard from 'components/ModernExperience/ResultCards/ProviderCard/ProviderContactCard';
import ServiceCostWrapper from 'components/ModernExperience/ServiceCost/ServiceCostWrapper';
import ResultMiniMap from 'components/ModernExperience/ResultCards/ResultLocations/ResultMiniMap';
import ProfileSectionModal from './ProfileSectionModal';

export default function ServiceCostModal(props) {
  const service = useSelector(select.services.current);
  const { data: profileModal } = useSelector(select.ui.profileModal);
  const smDown = useSelector(select.ui.isXsDown);

  const { id: npi, locations, comparisonLocation } = profileModal || {};

  if (!npi) return null;

  const title = service ? `${service.serviceName} Cost` : 'Service Costs';

  return (
    <ProfileSectionModal title={title} {...props}>
      <ProviderContactCard
        providerId={npi}
        showMap={!smDown}
        {...(locations ? { locations } : {})} // only pass the locations prop if locations have been provided. passing location=undefined would override the default locations array passed to the ContactCard component
        comparisonLocation={comparisonLocation}
      >
        <ServiceCostWrapper npi={npi} />
        {smDown && <ResultMiniMap />}
      </ProviderContactCard>
    </ProfileSectionModal>
  );
}
