import * as sliceNames from './slices/slicesNames';
import * as filters from './slices/filters/filterConstants';
import * as map from './slices/map/mapConstants';
import * as ui from './slices/ui/uiConstants';
import * as results from './slices/results/resultsConstants';
import * as fusion from './fusionServices/fusionConstants';
import * as config from './slices/config/configConstants';

const constants = {
  sliceNames,
  filters,
  map,
  ui,
  results,
  fusion,
  config,
};

export default constants;
