import React, { useCallback, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  FormControl,
  Button,
  IconButton,
  Collapse,
  TextField,
  Box,
  CircularProgress,
} from '@material-ui/core/';
import { useSelector } from 'react-redux';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import SmileyFace from '@mui/icons-material/TagFaces';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'propTypes/index';
import { checkInvalidTextField } from 'utils/utils';
import { select } from 'store/toolkit';

const THUMBS_UP = 'thumbs-up';
const THUMBS_DOWN = 'thumbs-down';

const BUTTONS = {
  [THUMBS_UP]: {
    value: true,
    buttonLabel: 'Good',
    messageLabel: 'What did you like about this experience?',
    icon: <ThumbUpIcon />,
  },
  [THUMBS_DOWN]: {
    value: false,
    buttonLabel: 'Poor',
    messageLabel: 'How can we improve?',
    icon: <ThumbDownIcon />,
  },
};

const SENDING = 'Sending';
const SENT = 'Sent';

const SENDING_ICONS = {
  [SENDING]: <CircularProgress size={20} />,
  [SENT]: <CheckIcon size={20} color="success" />,
};

/* 
QUICK_FEEDBACK_PROGRESS
0: 'not-started'
1: 'thumb-rating-submitted'
2: 'message-submitted'
*/

function QuickFeedbackForm({
  prompt,
  handleSubmit,
  compressed,
  progress,
  setProgress,
  dismissTextFeedback,
}) {
  const isXsDown = useSelector(select.ui.isXsDown);

  const endOfFeedbackForm = useRef();
  const [thumbValue, setThumbValue] = useState(null);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [sendingIcon, setSendingIcon] = useState(null);

  const scrollMessageIntoView = useCallback(() => {
    if (endOfFeedbackForm?.current)
      endOfFeedbackForm.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [endOfFeedbackForm]);

  const handleThumbClick = useCallback(
    (key) => {
      setThumbValue(key);
      setProgress(1);
      handleSubmit({ thumbsUp: BUTTONS[key].value });
      setTimeout(scrollMessageIntoView, 500);
    },
    [handleSubmit, scrollMessageIntoView, setProgress]
  );

  const handleChange = useCallback((e) => {
    setError('');
    setMessage(e.target.value);
  }, []);

  const sentTimeout = useCallback(() => {
    setTimeout(() => setProgress(2), 2000);
  }, [setProgress]);

  const sendingTimeout = useCallback(() => {
    setSendingIcon(SENDING);
    setTimeout(() => {
      setSendingIcon(SENT);
      sentTimeout();
    }, 1000);
  }, [sentTimeout]);

  const handleMessageSubmit = useCallback(() => {
    const errorMessage = checkInvalidTextField(message);
    if (errorMessage) {
      setError(errorMessage);
    } else {
      setError('');
      handleSubmit({ message });
      sendingTimeout();
    }
  }, [handleSubmit, message, sendingTimeout]);

  return (
    <Grid container justifyContent="center">
      <FormControl component="fieldset">
        {/* step 1 */}
        <Collapse in={progress === 0}>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            direction={compressed ? 'row' : 'column'}
            style={{ columnGap: 10 }}
          >
            <Grid>
              <Typography
                variant={compressed && isXsDown ? 'body1' : 'h3'}
                component="legend"
                color="primary"
              >
                {prompt}
              </Typography>
            </Grid>
            <Grid style={{ display: 'flex', flexWrap: compressed ? 'nowrap' : 'wrap' }}>
              <ThumbButton
                value={THUMBS_UP}
                onClick={() => handleThumbClick(THUMBS_UP)}
                compressed={compressed}
              />
              <ThumbButton
                value={THUMBS_DOWN}
                onClick={() => handleThumbClick(THUMBS_DOWN)}
                compressed={compressed}
              />
            </Grid>
          </Grid>
        </Collapse>

        {/* step 2 */}
        <Collapse in={progress > 0}>
          <Grid style={{ padding: '8px 0' }} container justifyContent="center">
            <SmileyFace style={{ marginRight: 6 }} />
            <Typography align="center" color="primary">
              Thank you for your feedback!
            </Typography>
          </Grid>
        </Collapse>

        <Collapse in={progress === 1}>
          <Box align="center">
            <TextField
              variant="outlined"
              margin="dense"
              label={BUTTONS[thumbValue]?.messageLabel || 'How can we improve?'}
              fullWidth
              multiline
              minRows={4}
              style={{ textAlign: 'left', background: 'white', borderRadius: 20 }}
              value={message}
              onChange={handleChange}
              inputProps={{ maxLength: 1024 }}
              InputLabelProps={{ style: { textAlign: 'left', maxWidth: '95%' } }}
              error={Boolean(error)}
              disabled={Boolean(sendingIcon)}
            />
            <Button
              disabled={!message || Boolean(sendingIcon)}
              variant="contained"
              color="primary"
              onClick={handleMessageSubmit}
              style={{ margin: '8px 0' }}
              endIcon={Boolean(sendingIcon) && SENDING_ICONS[sendingIcon]}
            >
              {sendingIcon || 'Submit'}
            </Button>
            {dismissTextFeedback && (
              <Button variant="text" style={{ marginLeft: 8 }} onClick={dismissTextFeedback}>
                Dismiss
              </Button>
            )}
            {error && <Typography color="error">{error}</Typography>}
          </Box>
        </Collapse>

        <div ref={endOfFeedbackForm} />
      </FormControl>
    </Grid>
  );
}

QuickFeedbackForm.propTypes = {
  prompt: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  setProgress: PropTypes.func.isRequired,
  compressed: PropTypes.bool,
  dismissTextFeedback: PropTypes.func,
};

QuickFeedbackForm.defaultProps = {
  compressed: false,
  dismissTextFeedback: undefined,
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    minWidth: 135,
  },
  compressed: {
    margin: 0,
    minWidth: 0,
    padding: 7,
    border: '1px solid',
    marginRight: theme.spacing(1),
  },
  [THUMBS_UP]: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
  [THUMBS_DOWN]: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

function ThumbButton({ value, compressed, ...props }) {
  const classes = useStyles();
  const { icon, buttonLabel } = useMemo(() => BUTTONS[value], [value]);
  const buttonProps = useMemo(
    () => ({
      variant: 'outlined',
      className: clsx(classes.button, classes[value], { [classes.compressed]: compressed }),
      'aria-label': `I had a ${buttonLabel} overall experience`,
      ...props,
    }),
    [buttonLabel, classes, compressed, props, value]
  );

  return compressed ? (
    <IconButton {...buttonProps}>{icon}</IconButton>
  ) : (
    <Button startIcon={icon} {...buttonProps}>
      {buttonLabel}
    </Button>
  );
}

ThumbButton.propTypes = {
  value: PropTypes.oneOf([THUMBS_UP, THUMBS_DOWN]).isRequired,
  compressed: PropTypes.bool,
};
ThumbButton.defaultProps = {
  compressed: false,
};

export default QuickFeedbackForm;
