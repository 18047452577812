import { DEFAULT_LOCATIONS, DROPDOWN_AUTOCOMPLETE, WALMART_NATIONAL } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import walmartLogo from 'assets/pg-walmart-header-logo.png';
import walmartLoginLogo from 'assets/wmt.png';
import { getEnv } from 'utils/utils';

export const apiTokens = {
  dev: 'Token 2a85debfaa7308c2b1c078e7206eeae31acc46a0',
  staging: 'Token 207ce5d5120dfb074c2f6fb5d5f76ba080412cc6',
  prod: 'Token cc501a49a0e8ad0705b7726061c7cfb1727898d9',
};

const token = selectToken(apiTokens);

const walmartNationalConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: getEnv() === 'production' ? 'walmart' : 'walmart-national',

  API_TOKEN: token,
  EMBOLD_CLIENT: WALMART_NATIONAL,
  EMBOLD_CLIENT_SLUG: WALMART_NATIONAL,
  SHOW_REGION_SELECTOR: true,
  HIGH_QUALITY_INTRO_MODAL: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Bentonville },
  LOGIN_NETWORK_NAME: 'walmartNationalLoggedIn',

  // content
  DISPLAY_NAME: 'Walmart',
  HEADER_LOGO: walmartLogo,
  NO_WELCOME_LINK: true,
  EMBOLD_LOGO_PRESENT: true,
  LOCAL_LOGO_ASSET: walmartLoginLogo,
  FORM_VARIANT: DROPDOWN_AUTOCOMPLETE,
};

export default walmartNationalConfig;
