import { createSlice } from '@reduxjs/toolkit';

import { userLoggedIn } from 'store/appThunks';
import { REFERRING_CLINIC_SLICE_NAME } from 'store/slices/slicesNames';
import configThunks from '../config/configThunks';

const initialState = {
  referringClinic: localStorage.getItem('referringClinic'),
  requireReferringClinic: localStorage.getItem('requireReferringClinic'),
};

function updateReferringClinic(state, clinic) {
  state.referringClinic = clinic;
  localStorage.setItem('referringClinic', clinic);
}

const referringClinicSlice = createSlice({
  name: REFERRING_CLINIC_SLICE_NAME,
  initialState,
  reducers: {
    requireReferringClinic(state) {
      state.requireReferringClinic = true;
    },
    setReferringClinic(state, action) {
      updateReferringClinic(state, action.payload);
    },
  },
  extraReducers(builder) {
    builder.addCase(userLoggedIn.fulfilled, (state, action) => {
      const { referringClinic } = action.payload;
      if (referringClinic) {
        updateReferringClinic(state, referringClinic);
      }
    });

    builder.addCase(configThunks.fetchClientConfig.pending, (state, action) => {
      const { requireReferringClinic } = action.meta.arg || {};

      if (requireReferringClinic) {
        state.requireReferringClinic = true;
      }
    });
  },
});

export default referringClinicSlice;
export const { requireReferringClinic, setReferringClinic } = referringClinicSlice.actions;
