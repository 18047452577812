export const getIntroAnimationInnerCircle = (rad1) => ({
  '0%': { r: 0 },
  '25%': { r: 0 },
  '75%': { r: `${rad1 * 1.1}px` },
  '100%': { r: `${rad1}px` },
});

export const getIntroAnimationOuterCircle = ({ rad1, rad2 }) => ({
  '0%': { r: `${rad2 / 10}px`, opacity: 0 },
  '25%': { r: `${rad2 / 3}px`, opacity: 1 },
  '75%': { r: `${rad2}px` },
  '100%': { r: `${rad1}px` },
});

export const getPulseAnimation = ({ rad1, rad2, frames = 4 }) => {
  const animation = {};
  const totalFrames = frames * 4;
  const step = 100 / totalFrames;

  Array.from({ length: totalFrames + 1 }).forEach((_, i) => {
    const percentage = `${i * step}%`;
    const value = i % 8 === 3 || i % 8 === 7 ? rad2 : rad1;
    animation[percentage] = { r: `${value}px` };
  });

  return animation;
};

export const getLineIntroAnimation = (thickness) => ({
  '0%': { height: 0 },
  '50%': { height: 0 },
  '100%': { height: thickness },
});

export const getSpinIntroAnimation = (frames) => ({
  '0%': { transform: `rotate(${frames[0] + 120}deg)` },
  '60%': { transform: `rotate(${frames[0] + 120}deg)` },
  '100%': { transform: `rotate(${frames[0]}deg)` },
});

export const getSpinAnimation = (frames) => {
  if (frames.length !== 4) {
    console.error('Invalid animation definition. Must have exactly 4 frames');
    return null;
  }

  const animation = {};
  const split = 100 / (frames.length * 2);

  const drift = (deg) => {
    const numbers = [-5, -4, -3, 3, 4, 5];
    return deg + numbers[Math.floor(Math.random() * numbers.length)];
  };

  frames
    .flatMap((i) => [i, drift(i)])
    .forEach((deg, i) => {
      animation[`${split * i}%`] = { transform: `rotate(${deg}deg)` };
    });

  animation['100%'] = { transform: `rotate(${frames[0]}deg)` };

  return animation;
};
