import React, { useMemo, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';
import PropTypes, { ChildrenType } from 'propTypes/index';

const useStyles = makeStyles((theme) => ({
  p: {
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
    fontSize: 16,
  },
}));

function AnchorTag(props) {
  const { href, ...rest } = props;

  const linkProps = useMemo(() => {
    if (href?.startsWith('/')) {
      // internal link
      return {
        component: RouterLink,
        to: href,
      };
    }
    // external link
    return {
      target: '_blank',
      rel: 'noopener noreferrer',
      href,
    };
  }, [href]);

  return <Link {...linkProps} {...rest} />;
}

AnchorTag.propTypes = {
  href: PropTypes.string.isRequired,
};

function Paragraph(props) {
  const classes = useStyles();
  return <Typography classes={{ root: classes.p }} {...props} />;
}

function EmboldMarkdown({ children, onLinkClick, markdownProps }) {
  const componentOverrides = useMemo(() => markdownProps?.components || {}, [markdownProps]);

  // this can be used for sending analytics events or other side effects of opening a markdown link
  const handleLinkClick = useCallback(
    (anchorProps) => {
      if (typeof onLinkClick === 'function') {
        onLinkClick(anchorProps);
      }
    },
    [onLinkClick]
  );

  const components = useMemo(
    () => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      a: (props) => <AnchorTag {...props} onClick={() => handleLinkClick(props)} />,
      p: Paragraph,
      ...componentOverrides,
    }),
    [componentOverrides, handleLinkClick]
  );

  const props = {
    ...markdownProps,
    components,
  };
  return <ReactMarkdown {...props}>{children}</ReactMarkdown>;
}

export default EmboldMarkdown;

EmboldMarkdown.propTypes = {
  children: ChildrenType.isRequired,
  markdownProps: PropTypes.shape({
    components: PropTypes.shape({}),
  }),
  onLinkClick: PropTypes.func,
};

EmboldMarkdown.defaultProps = {
  markdownProps: {},
  onLinkClick: undefined,
};
