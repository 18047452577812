import React from 'react';
import PropTypes from 'propTypes';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import select from 'store/select';

const useStyles = makeStyles((theme) => ({
  linkRoot: {
    display: 'inline-block',
    '&:visited': { color: theme.palette.primary.main },
    '& h3': {
      fontSize: '1.125rem', // 18px,
      margin: 0,
    },
  },
}));

function ResultTitle({ resultId, profileUrl: overrideUrl, children }) {
  const classes = useStyles();
  const profileLink = useSelector(
    // if there is a profileUrl prop, we want to prefer that and not call select.results.profileDetailsLink at all
    overrideUrl ? () => overrideUrl : select.results.profileDetailsLink(resultId)
  );

  const navigationProps = process.env.REACT_APP_STANDALONE_CHAT
    ? { component: RouterLink, to: profileLink }
    : { href: profileLink, target: '_blank', rel: 'noreferrer' };

  return (
    <Box>
      <Link classes={{ root: classes.linkRoot }} underline="hover" {...navigationProps}>
        <Typography variant="h3" id={`result-title-${resultId}`}>
          {children}
        </Typography>
      </Link>
    </Box>
  );
}

export default ResultTitle;

ResultTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  resultId: PropTypes.string.isRequired,
  profileUrl: PropTypes.string, // optional override for the url
};

ResultTitle.defaultProps = {
  profileUrl: undefined,
};
