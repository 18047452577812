/* eslint-disable react/jsx-filename-extension */
import { MICROSOFT, POLK, WALMART } from 'utils/constants';

const SUCCESS_MAIN = 'success.dark';
const INFO_MAIN = 'info.main';
const ERROR_MAIN = 'error.main';

// eslint-disable-next-line import/prefer-default-export
export const getNetworkContent = ({ provider, network, client }) => {
  const { healthPlan, curatedOutNetwork } = provider;

  const networkContentOverrides = {
    [WALMART]: {
      inNetwork: {
        tooltipMessage:
          'This provider is in-network and has been designated by your medical plan as a preferred provider. You will receive your highest benefit when you get care from this provider.',
      },
      preferred: {
        networkText: 'In Network Preferred',
      },
      nonPreferred: {
        networkText: 'In Network Non-Preferred',
        textColor: ERROR_MAIN,
        tooltipMessage:
          'This provider is in-network but has been designated by your medical plan as a non-preferred provider. Your coverage will be lower when you get care from this provider.',
      },
    },
    [MICROSOFT]: {
      inNetwork: {
        showTooltip: true,
      },
      healthConnect: {
        networkText: 'Health Connect Plan',
        tooltipMessage:
          'You’ll generally pay less out of pocket when you receive care from a Health Connect provider versus an Extended Network provider.',
      },
      extendedNetwork: {
        networkText: 'Extended Network',
        textColor: INFO_MAIN,
        tooltipMessage:
          "You'll generally pay more out of pocket when you receive care from an Extended Network provider versus a Health Connect provider.",
      },
    },
    [POLK]: {
      inNetwork: {
        showTooltip: true,
      },
    },
  };

  let networkContent = {
    networkText: 'In Network',
    textColor: SUCCESS_MAIN,
    tooltipMessage:
      'This provider has been designated as In Network by your medical plan. You will receive the highest benefit when you get care from an In Network provider.',
    showTooltip: false,
    showNetworkStatus: true,
  };

  // Walmart
  if (client === WALMART) {
    const nwa = network === 'northwestarkansas' || network === 'bcbsarkansas';
    const curatedOut = curatedOutNetwork === network || provider?.curatedOut;
    networkContent = { ...networkContent, ...networkContentOverrides[WALMART].inNetwork };

    if (!nwa) {
      networkContent.showTooltip = true;
      if (curatedOut) {
        networkContent = {
          ...networkContent,
          ...networkContentOverrides[WALMART].nonPreferred,
        };
      } else {
        networkContent = { ...networkContent, ...networkContentOverrides[WALMART].preferred };
      }
    }
  }

  // Microsoft
  if (client === MICROSOFT) {
    const planPrefix = network?.toUpperCase();
    networkContent = { ...networkContent, ...networkContentOverrides[MICROSOFT].inNetwork };

    if (planPrefix === 'YMJ') {
      if (healthPlan === 'health_connect') {
        networkContent = {
          ...networkContent,
          ...networkContentOverrides[MICROSOFT].healthConnect,
        };
      } else {
        networkContent = {
          ...networkContent,
          ...networkContentOverrides[MICROSOFT].extendedNetwork,
        };
      }
    }
  }

  // Polk
  if (client === POLK) {
    networkContent = { ...networkContent, ...networkContentOverrides[POLK].inNetwork };
  }

  return networkContent;
};
