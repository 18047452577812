/* eslint-disable no-shadow */
import { createSelector } from '@reduxjs/toolkit';

import { AUTH_SLICE_NAME } from '../slicesNames';
import { currentSlug } from '../config/selectNetworks';
import { requireReferringClinic, referringClinic } from '../referringClinic/selectReferringClinic';

/**  Is /init call pending
 * @returns {boolean} */
export const authPending = (state) => state[AUTH_SLICE_NAME].authPending;

/**  Did /init succeed
 * @returns {boolean} */
export const authSuccess = (state) => state[AUTH_SLICE_NAME].authSuccess;

/**  Did /init fail
 * @returns {boolean} */
export const authFailed = (state) => state[AUTH_SLICE_NAME].authFailed;

// eslint-disable-next-line import/prefer-default-export
export const isLoggedIn = createSelector(
  [
    (state) => state[AUTH_SLICE_NAME].isLoggedIn,
    currentSlug,
    requireReferringClinic,
    referringClinic,
  ],
  (isLoggedIn, networkSlug, referringClinicIsRequired, currentReferringClinic) => {
    if (referringClinicIsRequired) {
      return Boolean(isLoggedIn && networkSlug && currentReferringClinic);
    }
    return Boolean(isLoggedIn && networkSlug);
  }
);
