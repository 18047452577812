import { DEFAULT_LOCATIONS, DOLLAR_GENERAL_SUPPORT, DROPDOWN } from 'utils/constants';
import DollarGeneralLoginLogo from 'assets/bcbst-login-logo.svg';
import { selectToken } from 'utils/selectToken';

export const apiTokens = {
  dev: 'Token 1c4d058eb180257d25be814441b1ea788ecc7107',
  staging: 'Token d5b5f37e679ac7d73c61465da2115d084c8e641d',
  prod: 'Token 88a12471a0c85a5a8d5c483a864e710f895345d4',
};

const token = selectToken(apiTokens);

const dollarGeneralSupportConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'bcbst-support',

  API_TOKEN: token,
  EMBOLD_CLIENT: DOLLAR_GENERAL_SUPPORT,
  EMBOLD_CLIENT_SLUG: 'dgbcbst-support',
  SHOW_REGION_SELECTOR: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Goodlettsville },
  LOGIN_NETWORK_NAME: 'dollarGeneralSupportUserId',

  // content
  DISPLAY_NAME: 'Dollar General',
  HEADER_LOGO: DollarGeneralLoginLogo,
  EMBOLD_LOGO_PRESENT: true,
  FORM_VARIANT: DROPDOWN,
  LOCAL_LOGO_ASSET: DollarGeneralLoginLogo,
  NO_WELCOME_LINK: false,
};

export default dollarGeneralSupportConfig;
