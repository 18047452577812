import React, { useState, useMemo, useEffect } from 'react';
import { Grow, Collapse, Typography, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import SplashAnimation from './SplashAnimation';
import ClientConfigError from './ClientConfigError';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: '0 20px 40px',
    zIndex: 1501,
  },
  '@keyframes shrink': {
    '0%': { transform: 'scale(1)' },
    '100%': { transform: 'scale(0)' },
  },
  shrink: {
    transform: 'scale(0)',
    animation: '$shrink',
    animationDuration: '.15s',
    animationTimingFunction: 'ease',
    animationIterationCount: 1,
  },
}));

export const SPLASH_PAGE_TEST_ID = 'splash-page';
export default function SplashPage({ children }) {
  const classes = useStyles();
  const [minElapsed, setMinElapsed] = useState(false);
  const duration = useMemo(() => ({ intro: 1, loop: 4 }), []);
  const timeout = useMemo(() => ({ enter: 0, exit: 300 }), []);

  const authFailed = useSelector(select.auth.authFailed);
  const configIsLoaded = useSelector(select.config.isLoaded);
  const configError = useSelector(select.config.error);
  const showSplash = useSelector(select.ui.showSplashPage);

  const isAppReady = useMemo(() => minElapsed && !showSplash, [minElapsed, showSplash]);

  useEffect(() => setTimeout(() => setMinElapsed(true), 1000), []);

  useEffect(() => {
    if (isAppReady) {
      document.body.classList.remove('no-scroll');
    } else {
      document.body.classList.add('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isAppReady]);

  return (
    <>
      {configIsLoaded && children}
      <Grow in={!isAppReady} timeout={timeout} unmountOnExit>
        <Grid
          data-testid="splash-page"
          container
          justifyContent="center"
          alignItems="center"
          classes={{ root: classes.root }}
        >
          <Box classes={{ root: isAppReady ? classes.shrink : null }}>
            {configError ? (
              <ClientConfigError />
            ) : (
              <>
                <SplashAnimation size={100} duration={duration} />
                <Collapse in={authFailed} mountOnEnter>
                  <Typography color="primary" align="center">
                    redirecting...
                  </Typography>
                </Collapse>
              </>
            )}
          </Box>
        </Grid>
      </Grow>
    </>
  );
}

SplashPage.propTypes = {
  children: PropTypes.node.isRequired,
};
