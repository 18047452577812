import { AI_SEARCH_SLICE_NAME } from 'store/slices/slicesNames';

export const searchInputValue = (state) => state[AI_SEARCH_SLICE_NAME].searchInputValue;
export const searchPrompt = (state) => state[AI_SEARCH_SLICE_NAME].searchPrompt;
export const loading = (state) => state[AI_SEARCH_SLICE_NAME].loading;
export const error = (state) => state[AI_SEARCH_SLICE_NAME].error;
export const aiSearchKey = (state) => state[AI_SEARCH_SLICE_NAME].aiSearchKey;
export const shouldDisplayFeedback = (state) =>
  state[AI_SEARCH_SLICE_NAME].quickFeedback.shouldDisplay;
export const shouldPromptFeedback = (state) =>
  state[AI_SEARCH_SLICE_NAME].quickFeedback.shouldPrompt;
export const quickFeedbackProgress = (state) => state[AI_SEARCH_SLICE_NAME].quickFeedback.progress;
export const chatResponse = (state) => state[AI_SEARCH_SLICE_NAME].response.chatResponse;
export const specialtyRecommendations = (state) =>
  state[AI_SEARCH_SLICE_NAME].response.recommendations?.specialties || [];
