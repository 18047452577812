import React from 'react';
import PropTypes from 'propTypes/index';
import { makeStyles } from '@material-ui/styles';
import { getIntroAnimationOuterCircle, getPulseAnimation } from './utils';

const useStyles = ({ size, duration, animations }) =>
  makeStyles((theme) => ({
    '@keyframes intro': animations.intro,
    '@keyframes pulse': animations.pulse,
    pulsar: {
      height: size,
      width: size,
      '& circle': {
        cx: `${size / 2}px`,
        cy: `${size / 2}px`,
        r: animations.pulse['0%'].r, // ensure "r" equals first frame of pulse animation (should also equal last frame of intro animation)
        fill: theme.palette.primary.main,
        animationName: '$intro, $pulse',
        animationDuration: `${duration.intro}s, ${duration.loop}s`,
        animationTimingFunction: 'ease-in, linear',
        animationIterationCount: '1, infinite',
        animationDelay: `0s, ${duration.intro}s`,
      },
    },
  }));

function OuterCircle({ size, rad1, rad2, duration }) {
  const animations = {
    intro: getIntroAnimationOuterCircle({ rad1, rad2 }),
    pulse: getPulseAnimation({ rad1, rad2 }),
  };
  const classes = useStyles({ size, duration, animations })();

  return (
    <svg className={classes.pulsar} xmlns="http://www.w3.org/2000/svg">
      <circle />
    </svg>
  );
}

export default React.memo(OuterCircle);

OuterCircle.propTypes = {
  size: PropTypes.number.isRequired,
  duration: PropTypes.shape({
    intro: PropTypes.number.isRequired,
    loop: PropTypes.number.isRequired,
  }).isRequired,
  rad1: PropTypes.number.isRequired,
  rad2: PropTypes.number.isRequired,
};
