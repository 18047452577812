/* eslint-disable import/prefer-default-export */
import { DEFAULT_LOCATIONS, BNSF, DROPDOWN } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import bnsfLogo from 'assets/bnsf_logo.png';

export const apiTokens = {
  dev: 'Token 8154722fbd4aa7371bf1acdc9830b0cbe02173b4',
  staging: 'Token 7f6c7cf4386c3749f26be078870317681144233a',
  prod: 'Token b2cb3a9e10e7294e432b62d31103afdc71a3b298',
};

const token = selectToken(apiTokens);

const bnsfConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'bnsf',

  API_TOKEN: token,
  EMBOLD_CLIENT: BNSF,
  EMBOLD_CLIENT_SLUG: BNSF,

  // login
  SHOW_REGION_SELECTOR: true,
  REGION_SELECTOR_CODES: {},

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.FortWorth },
  DEFAULT_SEARCH_RADIUS: 50,
  LOGIN_NETWORK_NAME: 'bnsfUserId',

  // content
  DISPLAY_NAME: 'BNSF',
  HEADER_LOGO: bnsfLogo,
  FORM_VARIANT: DROPDOWN,
  LOCAL_LOGO_ASSET: bnsfLogo,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};

export default bnsfConfig;
