import React from 'react';
import PropTypes, { RefType, ChildrenType } from 'propTypes';
import { useSelector } from 'react-redux';
import { Paper, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select } from 'store/toolkit';
import ChatLoadingState from 'components/Modals/ChatModal/ChatLoading/index';
import ChatAcknowledgementForm from 'components/Modals/ChatModal/ChatTerms/ChatAcknowledgementForm';
import MessageChain from './MessageChain';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.down(400)]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
  },
}));

export default function ChatConversation({ endOfListRef, scrollToBottom, children, ...props }) {
  const classes = useStyles();

  const termsError = useSelector(select.chat.termsError); // TODO this could be encapsulated by ChatAcknowledgementForm and removed from here
  const termsAccepted = useSelector(select.chat.termsAccepted);

  return (
    <Paper elevation={0} classes={{ root: `${classes.root} grey` }} {...props}>
      {/* Terms agreement */}
      <Collapse in={!termsAccepted}>
        <ChatAcknowledgementForm isError={termsError} />
      </Collapse>

      {/* Messages */}
      <MessageChain />
      <ChatLoadingState scrollToBottom={scrollToBottom} />
      {Boolean(endOfListRef) && <div ref={endOfListRef} />}
      {children}
    </Paper>
  );
}

ChatConversation.propTypes = {
  endOfListRef: RefType,
  children: ChildrenType,
  scrollToBottom: PropTypes.func.isRequired,
};

ChatConversation.defaultProps = {
  endOfListRef: undefined,
  children: null,
};
