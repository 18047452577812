import CrownIcon from 'icons/Crown';

/* eslint-disable import/prefer-default-export */
export const AFFILIATION_TYPES = {
  GROUP: 'group',
  HOSPITAL: 'hospital',
};

export const CENTERS_OF_EXCELLENCE = {
  key: 'centersOfExcellence',
  title: 'Centers of Excellence Provider',
  description: `This provider is a Centers of Excellence provider, meaning they specialize in surgical care. This score is based on the provider’s focus area of surgical care.`,
  Icon: CrownIcon,
};

export const getCOEdescription = (matchedSubspecialtyName) =>
  `This provider is a Centers of Excellence provider, meaning they specialize in ${matchedSubspecialtyName}. This score is based on the provider’s focus area of surgical care.`;
