/* eslint-disable import/prefer-default-export */
import { DEFAULT_LOCATIONS, SOCT, DROPDOWN } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import soctLogo from 'assets/soct_logo.svg';

export const apiTokens = {
  dev: 'Token 7aa95f2dd343a8084d6d3203f143d83bbca28149',
  staging: 'Token 321527ba2c48504d40befa570180680db1e18566',
  prod: 'Token 50522284abbed9c05b2a2f676aa3953883c35b41',
};

const token = selectToken(apiTokens);

const soctConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'carecompass',

  API_TOKEN: token,
  EMBOLD_CLIENT: SOCT,
  EMBOLD_CLIENT_SLUG: SOCT,

  // login
  SHOW_REGION_SELECTOR: true,
  REGION_SELECTOR_CODES: {},
  HIGH_QUALITY_INTRO_MODAL: true,

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Hartford },
  LOGIN_NETWORK_NAME: 'soctUserId',

  // content
  DISPLAY_NAME: 'State of Connecticut',
  HEADER_LOGO: soctLogo,
  FORM_VARIANT: DROPDOWN,
  LOCAL_LOGO_ASSET: soctLogo,
  EMBOLD_LOGO_PRESENT: true,
  NO_WELCOME_LINK: false,
};

export default soctConfig;
