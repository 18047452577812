import React, { useCallback, useState } from 'react';
import PropTypes from 'propTypes';
import { useSelector } from 'react-redux';
import { useField, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { TextField, ListSubheader } from '@material-ui/core';

import { select } from 'store/toolkit';
import AutocompleteInput from '../AutocompleteInput';

const useStyles = makeStyles((theme) => ({
  autocompleteGroupLabel: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    lineHeight: '28px',
    fontSize: '1rem',
  },
  autocompletePopper: {
    minWidth: '300px',
  },
  label: {
    '& .MuiFormLabel-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      right: 44,
    },
    '& .MuiInputLabel-shrink': {
      right: 'unset',
      paddingLeft: 2,
      backgroundColor: theme.palette.common.white,
    },
  },
}));

/**
 * This is a copy of the default `renderGroup` function, with added a11y properties "role" and "aria-labelledby"
 */
const renderGroup = (params, classes) => (
  <ul
    key={params.key}
    className="MuiAutocomplete-groupUl"
    role="group"
    aria-labelledby={`header-${params.key}`}
  >
    <ListSubheader
      id={`header-${params.key}`}
      className={`${classes.autocompleteGroupLabel} MuiAutocomplete-groupLabel`}
      component="div"
    >
      {params.group}
    </ListSubheader>
    {params.children}
  </ul>
);

export default function ReferringClinicSelect({ submitOnChange, additionalClass }) {
  const [field, meta, { setValue }] = useField('referringClinic');
  const formik = useFormikContext();
  const [isOpen, setIsOpen] = useState(false);
  const options = useSelector(select.referringClinic.referringClinicOptions);
  const groupBy = useSelector(select.referringClinic.referringClinicOptionsGroupBy);
  const classes = useStyles();
  const isError = Boolean(meta.touched && meta.error);
  const helperText = isError ? meta.error : undefined;

  /**
   * Only change "value" when an option is selected (input text will still update)
   * Possible "reason" params: ["create-option", "select-option", "remove-option", "blur", "clear"]
   */
  const handleChange = useCallback(
    (evt, value, reason) => {
      setIsOpen(true);
      if (reason !== 'select-option') return;
      setValue(value.name);
      if (submitOnChange) formik.submitForm(evt);
    },
    [formik, setValue, submitOnChange]
  );

  if (!options) return null;

  const sortedOptions = [...options].sort((a, b) => a.name.localeCompare(b.name));

  if (groupBy) {
    sortedOptions.sort((a, b) => a[groupBy].localeCompare(b[groupBy]));
  }

  return (
    <AutocompleteInput
      name="referringClinic"
      role={undefined} // moved to `input` element (@TODO: Mui Upgrade)
      aria-expanded={undefined} // moved to `input` element (@TODO: Mui Upgrade)
      classes={{
        root: additionalClass || '',
        popper: classes.autocompletePopper,
      }}
      fullWidth
      blurOnSelect
      autoHighlight
      onChange={handleChange}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      groupBy={(option) => (groupBy ? option[groupBy] : '')}
      options={sortedOptions}
      getOptionLabel={(option) => option.name || option}
      getOptionSelected={(option, currentValue) => option.name === currentValue}
      renderInput={(params) => (
        <TextField
          {...params}
          className={`${classes.label} rounded`}
          label="Referring Clinic"
          variant="outlined"
          size="small"
          error={isError}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
            'aria-expanded': isOpen,
            role: 'combobox',
          }}
        />
      )}
      renderGroup={(params) => renderGroup(params, classes)}
      value={field.value}
    />
  );
}

ReferringClinicSelect.propTypes = {
  additionalClass: PropTypes.string,
  submitOnChange: PropTypes.bool,
};

ReferringClinicSelect.defaultProps = {
  additionalClass: undefined,
  submitOnChange: false,
};
