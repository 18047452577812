import { DEFAULT_LOCATIONS, MEMBER_CREDENTIALS, MICROSOFT } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import msftHeaderLogo from 'assets/Microsoft-logo-white.svg';
import MSFTLoginLogo from 'assets/Microsoft_logo.svg';

export const apiTokens = {
  dev: 'Token 4811089698748406a665f4f4761e43bdbcdfda67',
  staging: 'Token 6c6f3aac043a87ece9b28e5af88dbbcf04990202',
  prod: 'Token c5abc04e074368a1249aa076596f93a97c9267cb',
};

const token = selectToken(apiTokens);

const microsoftConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'microsoft',

  API_TOKEN: token,
  EMBOLD_CLIENT: MICROSOFT,
  EMBOLD_CLIENT_SLUG: 'msft',

  // login
  LOGIN_NETWORK_NAME: 'msftUserId',
  SHOW_REGION_SELECTOR: false,

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Seattle },

  // content
  DISPLAY_NAME: 'Microsoft',
  HEADER_LOGO: msftHeaderLogo,
  FORM_VARIANT: MEMBER_CREDENTIALS,
  EMBOLD_LOGO_PRESENT: true,
  LOCAL_LOGO_ASSET: MSFTLoginLogo,
  NO_WELCOME_LINK: false,
};

export default microsoftConfig;
