import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { select } from 'store/toolkit';

import ShowMoreButton from 'components/ShowMoreButton';
import ProfileTabLabel from 'components/ModernExperience/Profile/ProfileTabLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    '&:not(:last-child)': { borderBottom: `1px solid ${theme.palette.grey[100]}` },
    '& > :first-child': { width: '100%' },
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    columnGap: theme.spacing(1),
  },
  spacer: { marginTop: theme.spacing(2) },
  showMoreButton: {
    minWidth: 0,
  },
  transition: {
    transition: ({ animationDuration }) =>
      `transform ${animationDuration}ms ease, opacity ${animationDuration * 0.75}ms ease`,
  },
  transparent: {
    opacity: 0,
  },
  shiftedDown: {
    transform: 'translateY(50px)', // shift down to approximately where the name in the profile would be
  },
  profileCollapseRoot: {
    maxWidth: '100%',
  },
}));

export default function CollapsingProfile({
  wrapper: Wrapper,
  isOpen,
  id,
  onClick,
  renderProfile,
  animationDuration,
}) {
  const classes = useStyles({ animationDuration });
  const provider = useSelector(select.provider(id).data);
  const place = useSelector(select.place(id).data);
  const result = provider || place;
  const name = result.entityName || result.name;
  const profileDetailsId = `${name}-details`;

  return (
    <Wrapper className={classes.root}>
      <Collapse
        classes={{ wrapperInner: classes.header }}
        timeout={{ enter: animationDuration / 2, exit: animationDuration }}
        in={!isOpen}
        collapsedSize={25} // keep the ShowMoreButton visible
      >
        <ProfileTabLabel
          id={id}
          additionalClass={clsx(classes.transition, {
            [classes.transparent]: isOpen,
            [classes.shiftedDown]: isOpen,
          })}
        />
        <ShowMoreButton
          showMoreText="Show"
          showLessText="Hide"
          isOpen={isOpen}
          onClick={() => onClick(id)}
          ariaControls={profileDetailsId}
          ariaLabel={name}
          className={classes.showMoreButton}
        />
      </Collapse>

      <Collapse
        in={isOpen}
        timeout={{ enter: animationDuration, exit: animationDuration * 0.75 }}
        unmountOnExit
        mountOnEnter
        id={profileDetailsId}
        classes={{
          root: classes.profileCollapseRoot,
          wrapperInner: clsx(classes.transition, { [classes.transparent]: !isOpen }),
        }}
      >
        <div className={classes.spacer} />
        {renderProfile(id)}
      </Collapse>
    </Wrapper>
  );
}

CollapsingProfile.propTypes = {
  wrapper: PropTypes.elementType,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  renderProfile: PropTypes.func.isRequired,
  animationDuration: PropTypes.number,
};

CollapsingProfile.defaultProps = {
  wrapper: Box,
  animationDuration: 500,
};
