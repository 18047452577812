import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, useFormikContext } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';

import {
  MenuItem,
  Grid,
  Button,
  FormGroup,
  FormLabel,
  Paper,
  TextField,
  InputAdornment,
  Link,
} from '@material-ui/core';

import { select, actions } from 'store/toolkit';
import Modal from 'components/Modals/Modal';
import TextInput from 'components/Forms/TextInput';
import RadioGroupInput from 'components/Forms/RadioGroupInput';
import getClientConfig from 'store/slices/config/localConfigurations/getClientConfig';
import SubmitButton from 'components/Forms/SubmitButton';
import FieldErrorText from 'components/Forms/FieldErrorText';
import CopyTextButton from 'components/CopyTextButton';

const FIELD_NAMES = {
  CLIENT: 'client',
  API_URL: 'apiUrl',
  API_TOKEN: 'apiToken',
  MEMBER_ID: 'memberId',
  MEMBER_EXTRA: 'memberExtra',
  ZIP: 'zip',
  NETWORK: 'network',
  NETWORK_GROUP: 'networkGroup',
};

const FUSION_DEV = 'https://fusion.dev.emboldhealth.com/api/pg';
const FUSION_LOCAL = 'https://localhost:10000/api/pg';

export const clients = [
  'walmart',
  'walmart-national',
  'microsoft',
  'polk',
  'demo',
  'customerdemo',
  'embold',
  'icuba',
  'bcbst-support',
  'bcbst',
  'emsanacare',
  'lantern',
  'bnsf',
  'jbhunt',
  'carecompass',
  'carecompass-providerlookup',
  'ensemblehp',
  'ensemblehp-providerlookup',
  'continental',
  'quantum',
  'jpmc-cigna',
  'conviva',
  'referralguide-demo',
  'northcarolina',
  'axa-partners',
];

const clientNameAlias = {
  surgeryplus: 'lantern',
};
/**
 * To ensure parity with deployed instances, never use ann API token that doesn't match the PG client
 * This function ensures the match, and allows for clients with aliases (currently only `surgeryplus`)
 */
const isClientTokenMatch = (redirectUrl, configName) =>
  redirectUrl.hostname.split('.')[0] === configName ||
  redirectUrl.hostname.split('.')[0] === clientNameAlias[configName];

async function getNewIntegrationToken(formValues, superuserToken) {
  const localConfig = getClientConfig(formValues[FIELD_NAMES.CLIENT]);
  const clientSlug = localConfig?.EMBOLD_CLIENT_SLUG;
  const configName = localConfig?.CONFIG_NAME;
  const apiUrl = formValues[FIELD_NAMES.API_URL];

  if (!clientSlug) throw new Error('No client slug');

  const url = `${apiUrl}/token/`;
  const body = {
    member_id: formValues[FIELD_NAMES.MEMBER_ID] || '00000000',
    description: 'This token was created by the PG dev tools',
  };

  if (apiUrl === FUSION_DEV) body.client_slug = clientSlug;

  // optional fields added
  const memberExtraJSON = formValues[FIELD_NAMES.MEMBER_EXTRA];
  if (memberExtraJSON) {
    body.member_extra = JSON.parse(memberExtraJSON);
  }

  const networkSlug = formValues[FIELD_NAMES.NETWORK];
  if (networkSlug) body.network = networkSlug;

  const networkGroupSlug = formValues[FIELD_NAMES.NETWORK_GROUP];
  if (networkGroupSlug) body.network_group = networkGroupSlug;

  const zipcode = formValues[FIELD_NAMES.ZIP];
  if (zipcode) body.zipcode = zipcode;

  let res;
  try {
    res = await axios.post(url, body, {
      headers: {
        Authorization: `Token ${superuserToken}`,
      },
    });
  } catch (e) {
    console.error(e);
    const { response } = e;

    if (!response && apiUrl === FUSION_LOCAL) {
      throw new Error(
        `No response from Fusion. Potential causes are that Fusion is not running on localhost:10000 or your current domain is not in the Allowed Origins table.`
      );
    }

    if (response?.status === 401)
      throw new Error(
        'Unauthorized: Check your API token value in .env and ensure your API user has the necessary permissions.'
      );

    throw e;
  }

  // return the token param from the redirect url
  const redirectUrl = new URL(res.data.location);

  if (!isClientTokenMatch(redirectUrl, configName)) {
    throw new Error(
      `Integration token does not match the API user client. Go to the admin page and assign your user to client: ${clientSlug}`
    );
  }

  const currentUrl = new URL(window.location);
  currentUrl.search = redirectUrl.search;
  currentUrl.pathname = redirectUrl.pathname;
  return currentUrl.toString();
}

const formSchema = Yup.object({
  [FIELD_NAMES.MEMBER_EXTRA]: Yup.string().test('is-json', 'Must be valid JSON data', (value) => {
    if (!value) return true;

    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  }),
});

const useStyles = makeStyles((theme) => ({
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '& .MuiFormControl-root:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

function ClearButton(props) {
  const { resetForm } = useFormikContext();
  return <Button onClick={resetForm} {...props} />;
}

function ResetButton(props) {
  return (
    <Button
      onClick={() => {
        window.location = '/override';
      }}
      {...props}
    />
  );
}

function AdminLink(props) {
  const { values } = useFormikContext();
  const apiUrl = values[FIELD_NAMES.API_URL];
  const adminUrl = new URL(apiUrl);
  adminUrl.pathname = 'admin/fusion/user/';
  return (
    <Link href={adminUrl.toString()} rel="noreferrer noopener" target="_blank" {...props}>
      Fusion Admin
    </Link>
  );
}

export default function ClientConfigDevToolsModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialConfigName = useSelector(select.config.name);

  const ClientConfigDevToolsModalOpen = useSelector(select.ui.ClientConfigDevToolsModalOpen);
  const initialApiUrl = useSelector(select.config.apiUrl);

  const fusionOptions = useMemo(
    () => [
      { value: FUSION_LOCAL, label: 'localhost:10000' },
      { value: FUSION_DEV, label: 'Fusion Dev' },
    ],
    []
  );

  const initialValues = useMemo(
    () => ({
      [FIELD_NAMES.CLIENT]: initialConfigName,
      [FIELD_NAMES.API_URL]: initialApiUrl,
      [FIELD_NAMES.MEMBER_ID]: '',
      [FIELD_NAMES.MEMBER_EXTRA]: '',
    }),
    [initialConfigName, initialApiUrl]
  );

  const closeDevTools = useCallback(() => {
    dispatch(actions.ui.closeModal());
  }, [dispatch]);

  const handleSubmit = useCallback(async (formValues, formik) => {
    const apiUrl = formValues[FIELD_NAMES.API_URL];
    const client = formValues[FIELD_NAMES.CLIENT];
    const tokenMap = {
      [FUSION_LOCAL]: process.env.REACT_APP_LOCAL_FUSION_SUPERUSER_TOKEN,
      [FUSION_DEV]: process.env.REACT_APP_DEV_FUSION_SUPERUSER_TOKEN,
    };
    const superuserApiToken = tokenMap[apiUrl];

    let redirectUrl = null;
    try {
      redirectUrl = await getNewIntegrationToken(formValues, superuserApiToken);
    } catch (e) {
      formik.setFieldError('global', e.message || 'Failed to create a an integration token');
      return;
    }

    localStorage.setItem('devTools:client', client);
    localStorage.setItem('devTools:apiUrl', apiUrl);

    window.location.href = redirectUrl || '/';
  }, []);

  return (
    <Modal
      open={ClientConfigDevToolsModalOpen}
      handleClose={closeDevTools}
      ariaId="dev-tools-modal"
      title="Change Client Configuration"
      fullWidth
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={formSchema}>
        <Form className={classes.formRoot}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8}>
              <TextInput name={FIELD_NAMES.CLIENT} label="Client" select fullWidth size="small">
                {clients.sort().map((client) => (
                  <MenuItem key={client} value={client}>
                    {client}
                  </MenuItem>
                ))}
              </TextInput>
              <TextField
                variant="outlined"
                disabled
                fullWidth
                size="small"
                label="Integration Token"
                value={localStorage.getItem('integrationToken')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyTextButton textToCopy={localStorage.getItem('integrationToken')} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <RadioGroupInput
                name={FIELD_NAMES.API_URL}
                label="Fusion Instance"
                options={fusionOptions}
              />
            </Grid>
          </Grid>

          <Paper variant="outlined">
            <FormGroup component="fieldset">
              <FormLabel component="legend">Member Session (Optional)</FormLabel>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    name={FIELD_NAMES.MEMBER_ID}
                    label="Member ID"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    name={FIELD_NAMES.NETWORK}
                    label="Network Slug"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    name={FIELD_NAMES.NETWORK_GROUP}
                    label="Network Group Slug"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput name={FIELD_NAMES.ZIP} label="ZIP" fullWidth size="small" />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    name={FIELD_NAMES.MEMBER_EXTRA}
                    label="Member Extra JSON values"
                    multiline
                    minRows={3}
                    placeholder={`{\n    "foo": "bar"\n}`}
                    fullWidth
                    size="small"
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </Paper>

          <FieldErrorText name="global" />
          <AdminLink />

          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <SubmitButton>Save</SubmitButton>
            </Grid>
            <Grid item>
              <ClearButton variant="outlined">Clear</ClearButton>
            </Grid>
            <Grid item>
              <ResetButton variant="outlined" className={classes.error}>
                Reset
              </ResetButton>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  );
}
